import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TPopUpValues, TScreenPopUpsReducerState } from '../../app/types/screenPopUpsTypes';

export const screenPopUpsReducerInitialState: TScreenPopUpsReducerState = {
  popUps: null,
  isInCall: false,
};

export const screenPopUpsReducer = createSlice({
  name: 'screenPopUpReducer',
  initialState: screenPopUpsReducerInitialState,
  reducers: {
   
    setPopUps: (state, { payload }: PayloadAction<TPopUpValues[]>) => {
      state.popUps = payload;
    },
    setIsInCall: (state, {payload} : PayloadAction<boolean>) => {
      state.isInCall = payload;
    },
  },
});

export const screenPopUpsActions = screenPopUpsReducer.actions;

export default screenPopUpsReducer.reducer;
