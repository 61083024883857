import { TSorting } from '../app/types/contactTypes';
import { TSortList } from '../app/types/generalTypes';

export const sortOnClick = (
  { sortBy, sortDirection }: TSorting,
  id: string
): TSorting => {
  if (id === sortBy && sortDirection === 'ASC') {
    return {
      sortDirection: 'DESC',
      sortBy: id,
    };
  } else {
    return {
      sortDirection: 'ASC',
      sortBy: id,
    };
  }
};

export const sortOnClickV2 = (
  { direction, property }: TSortList,
  id: string
): TSortList => {
  if (id === property && direction === 'ASC') {
    return {
      direction: 'DESC',
      property: id,
    };
  } else {
    return {
      direction: 'ASC',
      property: id,
    };
  }
};
