import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ccpActions } from '../../../../store/reducers/ccpReducer';

const TaskListener = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    connect.contact((contact) => {
      contact.onRefresh((contact) => {
        if (contact.getType() !== connect.ContactType.TASK) {
          return;
        }

        console.log('TASK - on refresh');
        console.log(
          `TASK - on refresh, Contact status: ${contact.getStatus().type}`
        );

        // Handle when the task goes into Rejected here
        if (contact.getStatus().type === 'rejected') {
          dispatch(
            ccpActions.setPartialTaskChannelIncomingContact({
              curr: contact,
              status: 'Rejected',
              name: t('ccp.task.rejectedTask'),
            })
          );
        }
      });

      contact.onConnecting((contact) => {
        if (contact.getType() !== connect.ContactType.TASK) {
          return;
        }

        console.log('TASK - on connecting');
        if (contact.getType() !== connect.ContactType.TASK) {
          return;
        }
        dispatch(
          ccpActions.setPartialTaskChannelIncomingContact({
            curr: contact,
            status: 'Incoming',
            name: contact.getName(),
            description: contact.getDescription(),
            references: contact.getReferences(),
            contactId: contact.contactId,
          })
        );
        dispatch(ccpActions.setActiveUiChannel('TASK'));
      });

      contact.onAccepted((contact) => {
        if (contact.getType() !== connect.ContactType.TASK) {
          return;
        }

        console.log('TASK - on accepted');

        dispatch(ccpActions.resetTaskChannelIncomingContact());
      });

      contact.onConnected((contact) => {
        if (contact.getType() !== connect.ContactType.TASK) {
          return;
        }

        console.log('TASK - on connected');

        dispatch(
          ccpActions.resetTaskChannelIncomingContactIfSameAsPayloadContact(
            contact
          )
        );

        dispatch(
          ccpActions.addTaskChannelActiveContact({
            curr: contact,
            status: 'Connected',
            contactId: contact.contactId,
            name: contact.getName(),
            description: contact.getDescription(),
            references: contact.getReferences(),
          })
        );
      });

      contact.onMissed((contact) => {
        if (contact.getType() !== connect.ContactType.TASK) {
          return;
        }

        console.log('TASK - on missed');

        dispatch(ccpActions.resetTaskChannelIncomingContact());
        dispatch(
          ccpActions.addTaskChannelActiveContact({
            curr: contact,
            status: 'Missed',
            contactId: contact.contactId,
            name: t('ccp.task.missedTask'),
          })
        );
      });

      contact.onACW((contact) => {
        if (contact.getType() !== connect.ContactType.TASK) {
          return;
        }

        console.log('TASK - on acw');

        dispatch(
          ccpActions.updateOrCreateTaskChannelActiveContact({
            curr: contact,
            status: 'AfterContactWork',
            contactId: contact.contactId,
            name: contact.getName(),
            description: contact.getDescription(),
            references: contact.getReferences(),
          })
        );
      });

      contact.onDestroy((contact) => {
        if (contact.getType() !== connect.ContactType.TASK) {
          return;
        }

        console.log('TASK - on destroy');

        dispatch(
          ccpActions.resetTaskChannelIncomingContactIfSameAsPayloadContact(
            contact
          )
        );
        dispatch(ccpActions.removeTaskChannelActiveContactIfPresent(contact));
      });
    });
  }, []);

  return null;
};

export default TaskListener;
