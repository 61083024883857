import { IStore } from '..';
import {
  TCallAttributeReducerState,
  TQueue,
} from '../../app/types/callAttributeTypes';
import { UUID } from '../../app/types/generalTypes';

export const getCallAttributes = (state: IStore): TCallAttributeReducerState =>
  state.callAttributesReducer;
export const getPhoneNumber = (state: IStore): string | undefined =>
  state.callAttributesReducer.phoneNumber;
export const getQueue = (state: IStore): TQueue | undefined =>
  state.callAttributesReducer.queue;
export const getContactId = (state: IStore): UUID | undefined =>
  state.callAttributesReducer.contactId;
export const getShowForm = (state: IStore): boolean | undefined =>
  state.callAttributesReducer.showForm;
