import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';

import { Box, Divider, Link, Typography } from '@mui/material';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import Network1BarIcon from '@mui/icons-material/SignalWifi1Bar';
import Network2BarIcon from '@mui/icons-material/SignalWifi2Bar';
import Network4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import NetworkOfflineIcon from '@mui/icons-material/SignalWifiBad';
import LaunchIcon from '@mui/icons-material/Launch';

import {
  useLatencyData,
  LATENCY_THRESHOLDS,
} from '../../services/latency-service';
import { ROUTE_PATHS } from '../../routes/routePaths';

import { useConfig } from '../../hooks/useConfig';

import { useNetworkStatusStyles } from './network-status.styles';
import 'react-popper-tooltip/dist/styles.css';

export enum NetworkStatusEnum {
  LOW_QUALITY = 'LOW_QUALITY',
  MEDIUM_QUALITY = 'MEDIUM_QUALITY',
  GOOD_QUALITY = 'GOOD_QUALITY',
  OFFLINE = 'OFFLINE',
  CHECKING = 'CHECKING',
  NO_DATA = 'NO_DATA',
}

export interface INetworkStatus {
  showCTAToNetworkMetricsPage?: boolean;
}

const NetworkStatus = ({
  showCTAToNetworkMetricsPage = false,
}: INetworkStatus) => {
  const [currentNetworkStatus, setCurrentNetworkStatus] = useState(
    NetworkStatusEnum.NO_DATA
  );
  const { env } = useConfig();

  const latencyCheckIntervalInMinutes = env ? env.latencyCheckInterval : 60000;

  const [latencyData, triggerLatencyCheck] = useLatencyData();

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      interactive: true,
      offset: [0, 0],
      delayHide: 300,
    });

  const calculateAndSetNetworkStatus = useCallback(async () => {
    if (window.navigator.onLine) {
      setCurrentNetworkStatus(NetworkStatusEnum.CHECKING);

      const latencyData = await triggerLatencyCheck();
      if (latencyData && latencyData.averageLatency !== undefined) {
        if (latencyData.averageLatency <= LATENCY_THRESHOLDS.OK.high) {
          setCurrentNetworkStatus(NetworkStatusEnum.GOOD_QUALITY);
        } else if (latencyData.averageLatency <= LATENCY_THRESHOLDS.WARN.high) {
          setCurrentNetworkStatus(NetworkStatusEnum.MEDIUM_QUALITY);
        } else if (
          latencyData.averageLatency <= LATENCY_THRESHOLDS.ERROR.high
        ) {
          setCurrentNetworkStatus(NetworkStatusEnum.LOW_QUALITY);
        }
      }

      console.log(
        'Average latency to connect instance: ' + latencyData?.averageLatency
      );
    } else {
      setCurrentNetworkStatus(NetworkStatusEnum.OFFLINE);
    }
  }, [triggerLatencyCheck]);

  useEffect(() => {
    calculateAndSetNetworkStatus();

    let intervalID: any;
    if (latencyCheckIntervalInMinutes) {
      intervalID = setInterval(
        calculateAndSetNetworkStatus,
        latencyCheckIntervalInMinutes
      ) as any;
    }

    return () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
    };
  }, [latencyCheckIntervalInMinutes, calculateAndSetNetworkStatus]);

  useEffect(() => {
    window.addEventListener('online', calculateAndSetNetworkStatus);
    window.addEventListener('offline', calculateAndSetNetworkStatus);

    return () => {
      window.removeEventListener('online', calculateAndSetNetworkStatus);
      window.removeEventListener('offline', calculateAndSetNetworkStatus);
    };
  }, [calculateAndSetNetworkStatus]);

  return (
    <Box>
      <Box ref={setTriggerRef}>
        <NetworkStatusIcon networkStatus={currentNetworkStatus} />
      </Box>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          <NetworkStatusDetails
            networkStatus={currentNetworkStatus}
            averageLatency={latencyData?.averageLatency}
            minimumLatency={latencyData?.minLatency}
            maximumLatency={latencyData?.maxLatency}
            showCTAToNetworkMetricsPage={showCTAToNetworkMetricsPage}
          />
        </div>
      )}
    </Box>
  );
};

interface INetworkStatusIcon {
  networkStatus: NetworkStatusEnum;
}

export const NetworkStatusIcon = ({ networkStatus }: INetworkStatusIcon) => {
  const { checkingNetworkWrapper } = useNetworkStatusStyles();

  if (networkStatus === NetworkStatusEnum.CHECKING) {
    return <NetworkCheckIcon color="info" className={checkingNetworkWrapper} />;
  }

  if (networkStatus === NetworkStatusEnum.OFFLINE) {
    return <NetworkOfflineIcon color="error" />;
  }

  if (networkStatus === NetworkStatusEnum.LOW_QUALITY) {
    return <Network1BarIcon color="error" />;
  }

  if (networkStatus === NetworkStatusEnum.MEDIUM_QUALITY) {
    return <Network2BarIcon color="warning" />;
  }

  if (networkStatus === NetworkStatusEnum.GOOD_QUALITY) {
    return <Network4BarIcon color="success" />;
  }

  if (networkStatus === NetworkStatusEnum.NO_DATA) {
    return <NetworkCheckIcon color="warning" />;
  }

  return <Network2BarIcon color="warning" />;
};

interface INetworkStatusDetails {
  networkStatus: NetworkStatusEnum;
  averageLatency?: number;
  minimumLatency?: number;
  maximumLatency?: number;
  showCTAToNetworkMetricsPage?: boolean;
}

export const NetworkStatusDetails = ({
  networkStatus,
  averageLatency,
  minimumLatency,
  maximumLatency,
  showCTAToNetworkMetricsPage = false,
}: INetworkStatusDetails) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: 1,
      }}
    >
      <Typography fontWeight={500} color="primary" fontSize={16}>
        {t('networkStatus.networkStatus')}:&nbsp;
        <Typography fontWeight={400} component="span" fontSize={16}>
          {t(`networkStatus.${networkStatus}`)}
        </Typography>
      </Typography>

      {networkStatus !== NetworkStatusEnum.OFFLINE &&
        networkStatus !== NetworkStatusEnum.CHECKING && (
          <>
            <Divider
              sx={{
                marginTop: 1,
                marginBottom: 1,
              }}
            />

            <Typography fontWeight={500} color="primary" fontSize={14}>
              {t('networkStatus.averageLatency')}:&nbsp;
              <Typography fontWeight={400} component="span" fontSize={14}>
                {averageLatency ? `${averageLatency}ms` : '-'}
              </Typography>
            </Typography>

            <Typography fontWeight={500} color="primary" fontSize={14}>
              {t('networkStatus.minimumLatency')}:&nbsp;
              <Typography fontWeight={400} component="span" fontSize={14}>
                {minimumLatency ? `${minimumLatency}ms` : '-'}
              </Typography>
            </Typography>

            <Typography fontWeight={500} color="primary" fontSize={14}>
              {t('networkStatus.maximumLatency')}:&nbsp;
              <Typography fontWeight={400} component="span" fontSize={14}>
                {maximumLatency ? `${maximumLatency}ms` : '-'}
              </Typography>
            </Typography>
          </>
        )}
      {showCTAToNetworkMetricsPage && (
        <Box>
          <Link
            href={`${window.location.origin}${ROUTE_PATHS.troubleshooting.metrics}`}
            target="blank"
            underline="none"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              mt: 2,
              textTransform: 'uppercase',
              fontSize: 14,
              fontWeight: 'bold',
            }}
          >
            {t('metrics.detailedMetricsText')}
            <LaunchIcon fontSize="inherit" />
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default NetworkStatus;
