import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useIncomingStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'absolute',
    top: 10,
    left: 10,
    right: 10,
    padding: '1.5rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '.3rem',
    backgroundColor: 'white',
    boxShadow: '0 0.3rem 0.6rem rgba(0,0,0,0.2)',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '7px',
  },
}));

export const useButtonStyles = makeStyles((theme: Theme) => ({
  incomingButton: {
    '&.MuiButton-root': {
      borderRadius: '0.3rem',
      width: 'calc(50% - .1rem)',
      marginRight: '.1rem',

      '& > svg': {
        width: '1.4rem',
        height: '1.4rem',
      },
    },
  },
  incomingButtonReject: {
    '&.MuiButton-root': {
      backgroundColor: `${theme.palette.third?.main}14`,
      color: theme.palette.error.main,

      '&:hover': {
        backgroundColor: `${theme.palette.error.main}40`,
      },
    },
  },
  incomingButtonAccept: {
    '&.MuiButton-root': {
      backgroundColor: `${theme.palette.third?.main}14`,
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: `${theme.palette.third?.main}40`,
      },
    },
  },
}));
