
import { AxiosPromise } from "axios";
import { TPopUpValues } from "../types/screenPopUpsTypes";
import apiPaths from "./apiPaths";
import { axiosInstance } from "./axiosClient";

export const screenPopUpsApi: {
  getPopUp: () => AxiosPromise<TPopUpValues[] | null>;
} = {
  getPopUp: () => axiosInstance.get(apiPaths.screenPopUp.urlConfiguration)
};
