import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Toast } from '@webhelp-aws-connect-frontend/toast';

import { useConfig } from './hooks/useConfig';

import Loader from './components/loader/Loader';
import LoginRoutes from './routes/LoginRoutes';
import MainRoutes from './routes/MainRoutes';
import { ROUTE_PATHS } from './routes/routePaths';
import MetricsRoute from './routes/metrics-route';
import SideNav from './components/SideNav/side-nav';
import { ContactControlPanel } from './components/ContactControlPanel/contact-control-panel';
import { ErrorBoundary } from './components/Error/ErrorBoundary';

import { authSelectors, notificationSelectors } from '../store/selectors';
import { notificationActions } from '../store/reducers/notificationReducer';

import './app.css';

export const App: FC = () => {
  const dispatch = useDispatch();
  const { env } = useConfig();

  const history = useHistory();

  const isLogged = useSelector(authSelectors.getIsLogged);
  const userInfo = useSelector(authSelectors.getUserInfo);
  const notification = useSelector(notificationSelectors.getNotification);

  /**
   * Adding a window.onerrror handler to handle all unhandled window.onerror errors
   */
  useEffect(() => {
    window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
      console.error({ errorMsg, url, lineNumber });
    };
  }, []);

  if (!env) return <Loader />;

  if (!isLogged || !userInfo) {
    return <LoginRoutes />;
  }

  if (
    history.location.pathname.includes(ROUTE_PATHS.troubleshooting.metrics) &&
    env?.enabledFeatures?.NETWORK_METRICS
  ) {
    return <MetricsRoute />;
  }

  return (
    <ErrorBoundary dispatch={dispatch}>
      <Toast
        {...notification}
        close={() => dispatch(notificationActions.closeNotification())}
      />
      <SideNav
        ccp={(open) =>
          env?.enabledFeatures?.CCP ? <ContactControlPanel open={open} /> : null
        }
      >
        <MainRoutes />
      </SideNav>
    </ErrorBoundary>
  );
};

export default App;
