export default {
  auth: {
    redirectLogin: '/saml/login',
    login: '/login',
    logout: '/logout',
  },
  callRecording: {
    callRecording: '/call-recording',
    pause: '/pause',
    resume: '/resume',
    start: '/start',
  },
  form: {
    forms: '/forms',
    assignedToQueue: '/assigned-to-queue',
    details: '/details',
    assignableForms: '/assignable-forms',
  },
  contactRecord: {
    contactRecords: '/contact-records',
    search: '/search',
    form: '/form',
    notifications: '/notifications',
    voicemail: '/voicemail',
    history: '/history',
  },
  screenPopUp: {
    urlConfiguration: '/url-configuration',
  },
  agentStats: {
    agents: '/agents',
    stats: '/stats',
  },
  connectService: {
    connect: '/connect',
    contactInQueues: '/contacts-in-queues',
    contactAttributes: '/contact-attributes',
  },
  network: {
    base: '/network',
  },
  users: {
    users: '/users',
    language: '/language',
    countryCode: '/country-code',
    lastActivity: '/last-activity',
  },
  chatAttachments: {
    base: '/attachments',
    files: '/files',
  },
  chat: {
    historyByEmailWithTranscript: '/chat',
  },
  callService: {
    call: '/call',
    notifyStart: '/notify-start',
    transcript: '/transcript',
  },
  outboundCampaign: {
    outboundCampaign: '/outbound-campaign',
    widgetData: '/widget-data',
  },
  polyglot: {
    base: '/polyglot',
    translation: '/translation/translate',
    chat: '/translation/chat'
  },
} as const;
