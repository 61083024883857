import { useCallback, useEffect, useState } from 'react';

import { useConfig } from '../../hooks/useConfig';

import { getConnectInstanceLatencyResult } from './latency-service';
import type { LatencyResult } from './latency-service';

const useLatencyData: (
  executeAutomatically?: boolean
) => [LatencyResult | null, () => Promise<LatencyResult | null>] = (
  executeAutomatically = true
) => {
  const { env } = useConfig();

  const [latencyData, setLatencyData] = useState<LatencyResult | null>(null);

  const triggerLatencyCheck = useCallback(async () => {
    if (env?.connectInstance) {
      const result = await getConnectInstanceLatencyResult(
        env.connectInstance,
        10
      );
      setLatencyData(result);
      return result;
    }
    return null;
  }, [env?.connectInstance]);

  useEffect(() => {
    if (executeAutomatically) {
      triggerLatencyCheck();
    }
  }, [triggerLatencyCheck]);

  return [latencyData, triggerLatencyCheck];
};

export default useLatencyData;
