import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Loader from '../../components/loader/Loader';

import { ROUTE_PATHS } from '../routePaths';

const Metrics = lazy(
  () => import(/* webpackChunkName: 'network-metrics' */ '../../pages/metrics')
);

const MetricsRoute = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route
          path={ROUTE_PATHS.troubleshooting.metrics}
          exact
          component={Metrics}
        />
      </Switch>
    </Suspense>
  );
};

export default MetricsRoute;
