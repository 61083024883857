import { useEffect } from 'react';
import { usersApi } from '../../api/usersApi';

const HEARTBEAT_INTERVAL_MS = 60 * 1000;

const LastActivityHeartbeat = () => {
  useEffect(() => {
    const sendHeartbeat = () => {
      usersApi.updateLastActivity().catch(() => {
        // Nothing to do here
      });
    };

    sendHeartbeat();

    const interval = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL_MS);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return null;
};

export default LastActivityHeartbeat;
