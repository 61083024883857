import { FC, useEffect, lazy, Suspense, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import Loader from '../../../../loader/Loader';

import { callAttributesActions } from '../../../../../../store/reducers/callAttributeReducer';
import {
  ccpSelectors,
  screenPopUpsSelectors,
} from '../../../../../../store/selectors';
import { configureNewUrl } from '../../../../../shared/screenPopUpHelper';
import { screenPopUpsActions } from '../../../../../../store/reducers/screenPopUpsReducer';
import { modalReducerActions } from '../../../../../../store/reducers/modalReducer';
import { secondsToTime } from '../../../../../../../src/utils/dateUtils';
import { ccpActions } from '../../../../../../store/reducers/ccpReducer';
import storageUtils from '../../../../../../utils/storageUtils';
import { WelcomeIcon } from '../../../../../../assets/images';
import { TVoiceContactStatus } from '../../../../../types/ccp';
import { TKeyObject } from '../../../../../types/generalTypes';
import { connectServiceApi } from '../../../../../api/connectServiceApi';
import { TPopUpValues } from '../../../../../../app/types/screenPopUpsTypes';
import { contactRecordApi } from '../../../../../api/contactRecordApi';

import { useOngoingStyle } from './ongoing-style';

const IncomingCall = lazy(
  () => import(/* webpackChunkName: 'incomingCall' */ './IncomingCall')
);
const OngoingCall = lazy(
  () => import(/* webpackChunkName: 'ongoingCall' */ './OngoingCall')
);
const Recording = lazy(
  () => import(/* webpackChunkName: 'recording' */ './Recording')
);
const AfterCallWork = lazy(
  () =>
    import(/* webpackChunkName: 'afterCallWork' */ '../afterCall/AfterCallWork')
);
const OutboundCall = lazy(
  () => import(/* webpackChunkName: 'outboundCall' */ './OutboundCall')
);
const MissedCall = lazy(
  () => import(/* webpackChunkName: 'missedCall' */ './MissedCall')
);
const Transfer = lazy(
  () => import(/* webpackChunkName: 'transfer' */ '../transfer/Transfer')
);

export const mapConnectContactAttributeToInternalContactAttribute = (
  connectAttributes: connect.AttributeDictionary
): TKeyObject<string> => {
  return Object.values(connectAttributes).reduce(
    (accumulatorObject, connectAttribute) => ({
      ...accumulatorObject,
      [connectAttribute.name]: connectAttribute.value,
    }),
    {}
  );
};

const Call: FC = () => {
  const token = storageUtils.getAuthToken();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const popUps = useSelector(screenPopUpsSelectors.getPopUps);
  const {
    voice: {
      endedByCustomer,
      wasMissed,
      phoneNumber,
      contact: { status, curr },
    },
    status: agentStatus,
  } = useSelector(ccpSelectors.getState);
  const { wrapper, customerEnded, welcomeMessage } = useOngoingStyle({
    status: agentStatus || '',
  });

  const isOutboundCampaignCall = useSelector(ccpSelectors.getOutboundCallType);

  const valueRef = useRef<string | undefined>();

  useEffect(() => {
    valueRef.current = isOutboundCampaignCall;
  }, [isOutboundCampaignCall]);

  const agent = new connect.Agent();

  useEffect(() => {
    let localContact: connect.Contact | null = null;
    let localStatus: TVoiceContactStatus = 'Initial';

    const openRequestedSinglePopup = (
      url: string,
      openIn: string | undefined
    ) => {
      window.open(url, url, openIn === 'NEW_TAB' ? '' : 'popup');
    };

    let newPopUpsGlobal: TPopUpValues[] = [];

    connect.contact((contact) => {
      contact.onConnecting((contact) => {
        if (contact.getType() !== connect.ContactType.VOICE) {
          return;
        }

        // Change the current UI channel to voice as we have an incoming call
        dispatch(ccpActions.setActiveUiChannel('VOICE'));
        dispatch(ccpActions.setCallHoldTime(0));
        dispatch(ccpActions.setContactId(contact.getContactId()));

        if (valueRef.current === 'outboundCampaign') {
          connectServiceApi.setContactAttributes(contact.getContactId(), {
            outboundCallType: 'outboundCampaign',
          });
        }

        const queuesARNs = agent.getAllQueueARNs();

        agent.getEndpoints(queuesARNs, {
          success: (data) =>
            dispatch(ccpActions.setQuickConnects(data.endpoints)),
        });

        localContact = contact;
        if (contact.getConnections()[1].getType() === 'outbound') {
          localStatus = 'Outbound';
          dispatch(
            ccpActions.setVoiceChannelContact({
              curr: contact,
              status: 'Outbound',
            })
          );
          dispatch(ccpActions.setVoiceChannelIsOutboundCall(true));
        } else {
          localStatus = 'Incoming';
          dispatch(
            ccpActions.setVoiceChannelContact({
              curr: contact,
              status: 'Incoming',
            })
          );
          dispatch(ccpActions.setVoiceChannelIsOutboundCall(false));
        }

        if (popUps && contact.getAttributes()) {
          const newPopUps = popUps.map((elem) => {
            return {
              ...elem,
              newUrl: configureNewUrl(
                elem.queryParameters,
                elem.baseUrl,
                contact,
                agent
              ),
            };
          });
          newPopUpsGlobal = newPopUps;
          dispatch(screenPopUpsActions.setPopUps(newPopUps));
          dispatch(screenPopUpsActions.setIsInCall(true));
        }

        const phoneNumberForTransferredCall =
          contact.getContactId() === contact.getInitialContactId() ||
          !contact.getInitialContactId()
            ? contact.getConnections()[1].getAddress().phoneNumber
            : t('ccp.transferredCall');

        const phoneNumber = contact
          .getConnections()[1]
          .getAddress().phoneNumber;

        const queue = contact.getQueue();

        dispatch(
          ccpActions.setVoiceChannelPhoneNumber(phoneNumberForTransferredCall)
        );

        let waitingTimeInQueue = null;
        if (contact.getQueueTimestamp()) {
          const waitingSeconds = Math.round(
            (new Date().getTime() - contact.getQueueTimestamp().getTime()) /
              1000
          );
          const waitingTime = secondsToTime(
            waitingSeconds < 0 ? 0 : waitingSeconds
          );
          waitingTimeInQueue = waitingTime;
        }

        dispatch(
          callAttributesActions.setContact({
            callAttributes:
              mapConnectContactAttributeToInternalContactAttribute(
                contact.getAttributes()
              ),
            queue,
            phoneNumber,
            contactId: contact?.contactId,
            queueWaitingTime: waitingTimeInQueue,
          })
        );

        dispatch(
          modalReducerActions.setShowCallAttributes({
            open: false,
          })
        );

        dispatch(
          modalReducerActions.setShowCallForm({
            open: false,
          })
        );
      });

      contact.onRefresh((contact) => {
        if (contact.getType() !== connect.ContactType.VOICE) {
          return;
        }

        dispatch(ccpActions.setVoiceChannelContact({ curr: contact }));
      });

      contact.onAccepted((contact) => {
        if (contact.getType() !== connect.ContactType.VOICE) {
          return;
        }

        localContact = contact;
        localStatus = 'Accepted';
        dispatch(
          ccpActions.setVoiceChannelContact({
            curr: contact,
            status: 'Accepted',
          })
        );
        dispatch(
          callAttributesActions.setAttributes(
            mapConnectContactAttributeToInternalContactAttribute(
              contact.getAttributes()
            )
          )
        );
      });

      contact.onConnected((contact) => {
        if (contact.getType() !== connect.ContactType.VOICE) {
          return;
        }

        localContact = contact;
        localStatus = 'Connected';

        const urlsAutomatic = newPopUpsGlobal?.filter(
          (elem) => elem.openWithButton === false
        );

        if (urlsAutomatic) {
          urlsAutomatic.map((elem) =>
            openRequestedSinglePopup(elem.newUrl!, elem.openIn)
          );
        }

        dispatch(
          ccpActions.setVoiceChannelContact({
            curr: contact,
            status: 'Connected',
          })
        );
        dispatch(callAttributesActions.setShowForm(true));
        connectServiceApi
          .getContactAttributes(contact.getContactId())
          .then(({ data }) => {
            dispatch(callAttributesActions.setAttributes(data));
          });

        contactRecordApi.startContact(contact.getContactId());
      });

      contact.onMissed((contact) => {
        if (contact.getType() !== connect.ContactType.VOICE) {
          return;
        }

        localContact = contact;
        const agent = new connect.Agent();

        if (agent.getState().name !== 'FailedConnectCustomer') {
          if (localStatus === 'Incoming') {
            localStatus = 'Missed';
            dispatch(ccpActions.setVoiceChannelWasMissed(true));
            dispatch(
              ccpActions.setVoiceChannelContact({
                curr: contact,
                status: 'Missed',
              })
            );
          } else {
            localStatus = 'Initial';
            dispatch(
              ccpActions.setVoiceChannelContact({
                curr: contact,
                status: 'Initial',
              })
            );
          }
        } else {
          localStatus = 'Initial';
          dispatch(
            ccpActions.setVoiceChannelContact({
              curr: contact,
              status: 'Initial',
            })
          );
          dispatch(ccpActions.setVoiceChannelEndedByCustomer(true));

          setTimeout(() => {
            dispatch(ccpActions.setVoiceChannelEndedByCustomer(false));
          }, 3000);
        }
      });

      contact.onACW((contact) => {
        if (contact.getType() !== connect.ContactType.VOICE) {
          return;
        }

        localContact = contact;
        localStatus = 'AfterCallWork';

        dispatch(
          ccpActions.setVoiceChannelContact({
            curr: contact,
            status: 'AfterCallWork',
          })
        );
      });

      contact.onDestroy((contact) => {
        if (contact.getType() !== connect.ContactType.VOICE) {
          return;
        }

        localContact = null;
        localStatus = 'Initial';

        dispatch(callAttributesActions.resetContact());
        dispatch(screenPopUpsActions.setIsInCall(false));
        dispatch(callAttributesActions.setShowForm(false));
        dispatch(
          ccpActions.setVoiceChannelContact({
            curr: undefined,
            status: 'Initial',
          })
        );
        dispatch(ccpActions.setVoiceChannelWasMissed(false));
        dispatch(ccpActions.setVoiceChannelIsMuted(false));
        dispatch(ccpActions.setQuickConnects([]));
        dispatch(ccpActions.setVoiceChannelIsOnHold(false));
        dispatch(
          ccpActions.setVoiceChannelRecording({
            isRecording: false,
            recordingHasStarted: false,
          })
        );
      });
    });

    return () => {
      localStatus !== 'Missed' &&
        token &&
        localContact &&
        fetch(
          process.env.NX_BASE_URL +
            `connect/stop-contact/${localContact.contactId}`,
          {
            method: 'POST',
            keepalive: true,
            headers: {
              Authorization: token,
            },
          }
        );
    };
  }, []);

  const renderContent = (): JSX.Element | null => {
    switch (status) {
      case 'Initial':
        return (
          <Box className={welcomeMessage}>
            <WelcomeIcon />
            {`${t('ccp.welcome')}, ${agent.getName()}`}
          </Box>
        );
      case 'Incoming':
        return <IncomingCall />;
      case 'Outbound':
        return <OutboundCall />;
      case 'Accepted':
        return (
          <Box className={wrapper}>
            <Loader label={phoneNumber} />
          </Box>
        );
      case 'Connected':
        return (
          <>
            <OngoingCall />
            <Recording />
          </>
        );
      case 'AfterCallWork':
        return <AfterCallWork />;
      case 'Transfer':
        return (
          <>
            <Transfer />
            <Recording />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      {renderContent()}
      {!!endedByCustomer && (
        <Typography component="label" className={customerEnded}>
          <WarningIcon color="warning" />
          {t('ccp.customerEndedCall')}
        </Typography>
      )}
      {wasMissed && <MissedCall />}
    </Suspense>
  );
};

export default Call;
