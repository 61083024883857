import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Typography } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import SearchIcon from '@mui/icons-material/Search';

import { ccpSelectors } from '../../../../store/selectors';
import { ccpActions } from '../../../../store/reducers/ccpReducer';

import { useQuickConnectsStyles } from './quick-connects-style';

const QuickConnects = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const open = useSelector(ccpSelectors.getQuickConnectVisibility);
  const quickConnects = useSelector(ccpSelectors.getQuickConnects);
  const { curr } = useSelector(ccpSelectors.getVoiceChannelContact);

  const { t } = useTranslation();
  const { menuButton, quickConnectList, line, inputWrapper, input } =
    useQuickConnectsStyles();

  const quickConnectsSearched = useMemo(
    () =>
      quickConnects?.filter(
        (el) =>
          el?.name?.toLowerCase().includes(search.toLowerCase()) ||
          el?.phoneNumber?.toLowerCase().includes(search.toLowerCase())
      ),
    [search, quickConnects]
  );

  return (
    <Box>
      {!open && (
        <Button
          className={menuButton}
          onClick={() => {
            dispatch(ccpActions.setVoiceChannelDialpadVisibility(false));
            dispatch(ccpActions.setQuickConnectsVisibility(true));
          }}
        >
          <PeopleAltIcon />
          {t('ccp.quickConnects')}
        </Button>
      )}
      {open && (
        <Box sx={{ marginTop: '1rem' }}>
          <Box className={inputWrapper}>
            <SearchIcon />
            <Box
              component="input"
              className={input}
              value={search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
            />
          </Box>
          <Box className={quickConnectList}>
            {quickConnectsSearched?.map((el) => (
              <Box className={line}>
                <Typography>{el.name}</Typography>
                <PhoneForwardedIcon
                  onClick={() =>
                    curr?.addConnection(el, {
                      success: () =>
                        dispatch(
                          ccpActions.setVoiceChannelContact({
                            curr,
                            status: 'Transfer',
                            activeQuickConnect: el.name,
                          })
                        ),
                    })
                  }
                />
              </Box>
            ))}
          </Box>
          <Button
            className={menuButton}
            onClick={() => {
              dispatch(ccpActions.setQuickConnectsVisibility(false));
            }}
          >
            <PeopleAltIcon />
            {t('ccp.hideQuickConnects')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default QuickConnects;
