import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { STYLE_CONSTANTS } from '../../../../../../assets/styles/defaultTheme/constants';

export const useModalStyles = makeStyles((theme: Theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.common.white,
    textAlign: 'center',
  },
  modalWrapper: {
    width: '70rem',
    borderRadius: '.3rem',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: 16,
    height: '90vh',
    overflowX: 'scroll',
  },
  modalHeader: {
    '&.MuiTypography-root': {
      position: 'relative',
      fontSize: '1.3rem',
      color: theme.palette.primary.main,

      '& > svg': {
        position: 'absolute',
        right: '1rem',
        top: 0,
        cursor: 'pointer',
        borderRadius: '0.3rem',

        '&:hover': {
          backgroundColor: `${theme.palette.primary.main}25`,
        },
      },
    },
  },
  filterSectionWrapper: {
    ...STYLE_CONSTANTS.FLEX.SPACE_BETWEEN,
    marginBottom: '2rem',
  },
}));
