import { IStore } from '..';
import {
  TShowCallAttributesModal,
  TModal,
  TShowChatTranscriptModal,
} from '../../app/types/modalType';

export const getShowCallAttributes = (
  state: IStore
): TModal<TShowCallAttributesModal> =>
  state.modalReducer.callHistory.showCallAttributes;

export const getShowCallForm = (state: IStore): TModal =>
  state.modalReducer.callHistory.showCallForm;

export const getShowChatTranscriptModal = (
  state: IStore
): TModal<TShowChatTranscriptModal> =>
  state.modalReducer.chatTranscript.showChatTranscript;
