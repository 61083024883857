import React, { useState, useMemo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';


import PhoneIcon from '@mui/icons-material/Phone';
import BackspaceIcon from '@mui/icons-material/Backspace';
import DialpadIcon from '@mui/icons-material/Dialpad';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { useLongPress } from 'use-long-press';

import CountrySelector from './CountrySelector';
import OutboundForm from './OutboundForm';
import TouchTone from './touch-tone';

import { COUNTRY_CODES } from './constants';
import { notificationActions } from '../../../../../store/reducers/notificationReducer';
import { automatedCallActions } from '../../../../../store/reducers/automated-call-reducer';
import { TOutboundForm } from '../../../../types/ccp';
import { ccpActions } from '../../../../../store/reducers/ccpReducer';
import { useConfig } from '../../../../hooks/useConfig';
import {
  ccpSelectors,
  automatedCallSelectors,
} from '../../../../../store/selectors';
import { connectServiceApi } from '../../../../api/connectServiceApi';
import {
  AsYouType,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';

import { useDialpadStyles } from './dialpad-style';

const DIAL_BUTTONS: { shortPress: string; longPress: string | null }[][] = [
  [
    { shortPress: '1', longPress: null },
    { shortPress: '2', longPress: null },
    { shortPress: '3', longPress: null },
  ],
  [
    { shortPress: '4', longPress: null },
    { shortPress: '5', longPress: null },
    { shortPress: '6', longPress: null },
  ],
  [
    { shortPress: '7', longPress: null },
    { shortPress: '8', longPress: null },
    { shortPress: '9', longPress: null },
  ],
  [
    { shortPress: '*', longPress: null },
    {
      shortPress: '0',
      longPress: '+',
    },
    { shortPress: '#', longPress: null },
  ],
];

/**
 * ***Dialpad Version 1***
 * @returns Dialpad Version 1
 */
const DialpadV1 = () => {
  const dispatch = useDispatch();
  const { env } = useConfig();

  const agent = new connect.Agent();

  const [error, setError] = useState('');
  const [connection, setConnection] = useState<connect.BaseConnection>();

  const open = useSelector(ccpSelectors.getDialpadVisibility);
  const { number, form, isRedial, country } = useSelector(
    ccpSelectors.getVoiceChannelOutboundCall
  );
  const contact = useSelector(ccpSelectors.getVoiceChannelContact);
  const statusClient = contact.status;

  const automatedCallData = useSelector(
    automatedCallSelectors.getAutomatedCallData
  );

  const { curr } = useSelector(ccpSelectors.getVoiceChannelContact);

  const isDialpadDisabled = useSelector(ccpSelectors.getIsDialpadDisabled);

  const client = curr?.getActiveInitialConnection();

  const isOutboundFormEnabled =
    env?.enabledFeatures?.OUTBOUND_CALL_FORM ?? false;

  const methods = useForm<TOutboundForm>({
    defaultValues: { type: '', value: '' },
  });

  const dialableCountries = _.pick(COUNTRY_CODES, agent.getDialableCountries());

  // Calls the MyConnect API to update the contact attributes (for an outbound call)
  useEffect(() => {
    const contactId = contact.curr?.getContactId();

    // If we have a contact ID and there is an outbound call with form data
    if (contactId && !contact.curr?.isInbound() && form) {
      /**
       * If this is an automated call request,
       * set the contact attributes to the automated call's request's payload.
       */
      if (automatedCallData.customerEndpoint) {
        const statusState = agent.getAgentStates().find((s: any) => {
          if (s.name === 'Available') return s;
          return null;
        });
        agent.setState(statusState!, undefined);

        connectServiceApi.setContactAttributes(contactId, {
          agentKey: automatedCallData.agentKey,
          callReason: automatedCallData.callReason,
          contactId: automatedCallData.contactId,
          inputValue: automatedCallData.inputValue,
          customerEndpoint: automatedCallData.customerEndpoint,
          ...automatedCallData.additionalAttributes,
        });

        // Reset the automated call request data
        dispatch(automatedCallActions.resetAutomatedCallData());
      } else {
        const statusState = agent.getAgentStates().find((s: any) => {
          if (s.name === 'Available') return s;
          return null;
        });
        agent.setState(statusState!, undefined);

        // Else, if the form was completed manually,
        // set the contact attributes.
        connectServiceApi.setContactAttributes(contactId, {
          callReason: form.type,
          inputValue: form.value,
        });
      }
    }
    // Reset the outbound call form and number fields
    dispatch(
      ccpActions.setVoiceChannelOutboundCall({ form: undefined, number: '' })
    );
  }, [contact.curr?.contactId]);

  useEffect(() => {
    if (form) {
      methods.setValue('type', form.type || '');
      methods.setValue('value', form.value || '');
    }
  }, [isRedial]);

  useEffect(() => {
    connect.contact((contact) => {
      if (contact.getType() !== connect.ContactType.VOICE) {
        return;
      }

      contact.onConnecting((c) => {
        setConnection(c.getConnections()[1]);
      });

      contact.onDestroy(() => {
        setConnection(undefined);
      });
    });
  }, []);

  const { type, value } = methods.watch();

  const isAgentInACall = contact.status !== 'Initial';

  /**
   * Explanation of logic:
   * Call button is disabled in the following situations:
   * 1. Outbound form enabled and either:
   * - no type selected
   * - no value entered
   * - no number entered
   * => i.e the outbound form should be filled out but it is not
   *
   * 2. Outbound form is disabled and no number is entered
   * => i.e. no number in the input field
   *
   * 3. Ongoing voice contact's status is different than "Initial"
   * => i.e. there is an ongoing contact already
   */
  const isCallButtonDisabled =
    (isOutboundFormEnabled && (!type || !value || !number)) ||
    (!isOutboundFormEnabled && !number) ||
    (client === null && statusClient !== 'AfterCallWork');

  const { t } = useTranslation();
  const {
    menuButton,
    mainWrapper,
    display,
    numberWrapper,
    deleteIcon,
    numberInput,
    column,
    callButton,
    rowWrapper,
    button,
    numberInputError,
    errorTooltip,
  } = useDialpadStyles();

  const handleCall = () => {
    const agent = new connect.Agent();
    if (automatedCallData.customerEndpoint) {
      const endpoint = connect.Endpoint.byPhoneNumber(`${number}`);

      agent.connect(endpoint, {
        success: () => {
          methods.reset();
          dispatch(ccpActions.setVoiceChannelDialpadVisibility(false));
        },
        failure: () =>
          dispatch(
            notificationActions.openNotification({
              isOpen: true,
              type: 'error',
              message: t('error.invalidNumber'),
            })
          ),
      });
    } else {
      const endpoint = connect.Endpoint.byPhoneNumber(`${number}`);
      agent.connect(endpoint, {
        success: () => {
          methods.reset();
          dispatch(ccpActions.setVoiceChannelDialpadVisibility(false));
        },
        failure: () =>
          dispatch(
            notificationActions.openNotification({
              isOpen: true,
              type: 'error',
              message: t('error.invalidNumber'),
            })
          ),
      });
    }
  };

  const handleSubmit = (values: TOutboundForm) => {
    if (contact?.status === 'Connected') {
      contact.curr?.addConnection(
        connect.Endpoint.byPhoneNumber(number.replace(/[^\d+]/g, '')),
        {
          success: () =>
            dispatch(
              ccpActions.setVoiceChannelContact({
                ...contact,
                status: 'Transfer',
                activeQuickConnect: `${number}`,
              })
            ),
          failure: () => {
            dispatch(
              notificationActions.openNotification({
                isOpen: true,
                type: 'error',
                message: t('error.invalidNumber'),
              })
            );
          },
        }
      );

      return;
    }

    setError('');

    if (!isValidPhoneNumber(number)) {
      setError(t('error.e164'));
      return;
    }

    dispatch(
      ccpActions.setVoiceChannelOutboundCall({
        number: number.replace(/[^\d+]/g, ''),
        ...(isOutboundFormEnabled && {
          form: values,
        }),
      })
    );

    dispatch(ccpActions.setDisabledNextContactOutboundCampaign(true));

    handleCall();
  };

  // If there is a change to the automatedCallData's customerEndpoint
  // field, it means that there is a request for an automated call.
  // Thus, triggering the submission of the form.
  useEffect(() => {
    if (automatedCallData.customerEndpoint) {
      methods.handleSubmit(handleSubmit)();
    }
  }, [automatedCallData]);

  const checkNumberOnChange = (value: any) => {
    const numberFormatted = new AsYouType().input(value);
    const prefix = numberFormatted.split(' ')[0];

    const flag = Object.keys(dialableCountries).find(
      (key) => dialableCountries[key] === prefix
    );

    if (flag) {
      dispatch(
        ccpActions.setVoiceChannelOutboundCall({
          country: flag ? flag : country,
        })
      );
      dispatch(ccpActions.setVoiceChannelOutboundCallDialledNumber(value));
    } else {
      if (isValidPhoneNumber(COUNTRY_CODES[country] + value)) {
        const newNumberFormatted = parsePhoneNumber(
          COUNTRY_CODES[country] + value
        ).formatInternational();

        dispatch(
          ccpActions.setVoiceChannelOutboundCallDialledNumber(
            newNumberFormatted
          )
        );
      } else {
        dispatch(ccpActions.setVoiceChannelOutboundCallDialledNumber(value));
      }
    }
  };

  /**
   * The logic below applies in a call, when the agent presses
   * (using the keyboard) the phone characters (1-9, #, * and +).
   * In that case, the character is sent throught the connection +
   * sound is played.
   * If another character was pressed, call "preventDefault" to prevent
   * it from being added to the text field.
   */
  const onNumberInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const characterEntered = event.key;
    if (isAgentInACall) {
      if (/[0-9]+|\*+|#+|\++/.test(characterEntered)) {
        TouchTone.dial(characterEntered);
        connection?.sendDigits(characterEntered);
      }
    }
  };

  return (
    <>
      {!open && (
        <Button
          className={menuButton}
          onClick={() => {
            dispatch(ccpActions.setVoiceChannelDialpadVisibility(true));
            dispatch(ccpActions.setQuickConnectsVisibility(false));
          }}
        >
          <DialpadIcon />
          {t('ccp.numberPad')}
        </Button>
      )}
      <FormProvider {...methods}>
        <Box
          onSubmit={methods.handleSubmit(handleSubmit)}
          className={mainWrapper}
          component="form"
          sx={{ display: open ? 'block' : 'none' }}
        >
          {!connection && isOutboundFormEnabled && <OutboundForm />}
          <Box className={display}>
            <Box sx={{ display: 'flex' }}>
              <CountrySelector
                isDisabled={isDialpadDisabled}
                onSelect={(id) => {
                  dispatch(
                    ccpActions.setVoiceChannelOutboundCallDialledNumber(id)
                  );
                }}
              />
              <Box className={numberWrapper}>
                <Tooltip
                  title={error}
                  disableFocusListener
                  disableTouchListener
                  placement="top"
                  classes={{ tooltip: errorTooltip }}
                >
                  <Box
                    className={`${numberInput} ${!!error && numberInputError}`}
                    component="input"
                    disabled={isDialpadDisabled}
                    value={number}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      checkNumberOnChange(event.target.value);
                      event.target.value === '' && setError('');
                    }}
                    onKeyDown={onNumberInputKeyDown}
                  />
                </Tooltip>
                <BackspaceIcon
                  className={deleteIcon}
                  onClick={() => {
                    if (!isDialpadDisabled) {
                      dispatch(
                        ccpActions.setVoiceChannelOutboundCallDialledNumber(
                          number.slice(0, -1)
                        )
                      );
                      number.length === 1 && setError('');

                      // Play sound
                      TouchTone.dial(1);
                    }
                  }}
                />
              </Box>
            </Box>
            <Button
              className={callButton}
              type="submit"
              disabled={isCallButtonDisabled}
            >
              <PhoneIcon />
              {t('ccp.callNumber')}
            </Button>
          </Box>
          <Box className={column}>
            {DIAL_BUTTONS.map((row, index) => (
              <Box className={rowWrapper} key={index}>
                {row.map(({ shortPress, longPress }, i) => {
                  return (
                    <VariablePressLengthDialpadButton
                      disabled={isDialpadDisabled}
                      key={`dial-${index}-${i}`}
                      id={shortPress}
                      shortPressLabel={shortPress}
                      longPressLabel={longPress}
                      onShortPress={() => {
                        // Play sound
                        TouchTone.dial(shortPress);
                        checkNumberOnChange(number + shortPress);

                        connection?.sendDigits(shortPress);
                      }}
                      onLongPress={() => {
                        if (longPress) {
                          // Play sound
                          TouchTone.dial(longPress);
                          checkNumberOnChange(number + longPress);

                          connection?.sendDigits(longPress);
                        }
                      }}
                      className={button}
                      sx={{
                        visibility: !shortPress ? 'hidden' : 'visible',
                      }}
                    />
                  );
                })}
              </Box>
            ))}
          </Box>
          <Button
            className={menuButton}
            onClick={() => {
              dispatch(ccpActions.setVoiceChannelDialpadVisibility(false));
            }}
          >
            <DialpadIcon />
            {t('ccp.hidePad')}
          </Button>
        </Box>
      </FormProvider>
    </>
  );
};

const VariablePressLengthDialpadButton = ({
  onShortPress,
  onLongPress,
  shortPressLabel,
  longPressLabel,
  ...rest
}: Omit<React.ComponentProps<typeof Button>, 'children'> & {
  onShortPress: () => void;
  onLongPress: () => void;
  shortPressLabel: string;
  longPressLabel: string | null;
}) => {
  const bind = useLongPress(onLongPress, {
    onCancel: onShortPress,
  });

  return (
    <Button {...rest} {...bind()}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '1.175rem',
          }}
        >
          {shortPressLabel}
        </Typography>

        {longPressLabel && (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '1rem',
            }}
          >
            {longPressLabel}
          </Typography>
        )}
      </Box>
    </Button>
  );
};

export default DialpadV1;
