import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { OUTBOUND_CALL_TYPES } from './constants';

import { useOutboundFormStyles } from './dialpad-style';

const OutboundForm = () => {
  const { t } = useTranslation();
  const {
    wrapper,
    radioButton,
    radioButtonText,
    textInput,
    fieldWrapper,
    header,
  } = useOutboundFormStyles();

  const { control, register, setValue } = useFormContext();

  return (
    <Box className={wrapper}>
      <Typography component="label" className={header}>
        {t('filter.callReason')}
      </Typography>
      <Controller
        name="type"
        rules={{ required: `${t('error.required')}` }}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <RadioGroup
              onChange={(e) => {
                onChange(e);
                setValue('value', '');
              }}
            >
              {Object.keys(OUTBOUND_CALL_TYPES).map((key, index) => (
                <Box className={fieldWrapper} key={index}>
                  <FormControlLabel
                    value={key}
                    control={
                      <Radio className={radioButton} checked={key === value} />
                    }
                    label={`${t(OUTBOUND_CALL_TYPES[key])}`}
                    className={radioButtonText}
                  />
                  {value === key && (
                    <Box
                      className={textInput}
                      placeholder={t(`outboundCall.${key}`)}
                      component="input"
                      autoComplete="off"
                      {...register('value', {
                        required: `${t('error.required')}`,
                      })}
                    />
                  )}
                </Box>
              ))}
            </RadioGroup>
          );
        }}
      />
    </Box>
  );
};

export default OutboundForm;
