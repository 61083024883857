import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import FieldWrapper from '../../../../../../shared/fieldWrapper/FieldWrapper';

import { chatAttachmentFiltersSelectors } from '../../../../../../../store/selectors';
import { chatAttachmentFilterActions } from '../../../../../../../store/reducers/chat-attachment-filter-reducer';
import { TChatAttachmentFilter } from '../../../../../../types/chat-attachment-types';

import { useStyles } from './chat-attachment-filters-styles';

const ChatAttachmentFilters = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chatAttachmentFilters = useSelector(
    chatAttachmentFiltersSelectors.getFilters
  );

  const { register, setValue, watch, handleSubmit, reset, control } =
    useForm<TChatAttachmentFilter>({
      defaultValues: {
        ...chatAttachmentFilters,
        createdAfter: chatAttachmentFilters.createdAfter || null,
        createdBefore: chatAttachmentFilters.createdBefore || null,
      },
    });

  const handleSearch = (inputValues: TChatAttachmentFilter): void => {
    dispatch(
      chatAttachmentFilterActions.setFilters({
        name: inputValues.name ? inputValues.name : undefined,
        title: inputValues.title ? inputValues.title : undefined,
        userName: inputValues.userName ? inputValues.userName : undefined,
        createdAfter: inputValues.createdAfter
          ? inputValues.createdAfter
          : undefined,
        createdBefore: inputValues.createdBefore
          ? inputValues.createdBefore
          : undefined,
      })
    );
    dispatch(chatAttachmentFilterActions.setPage(0));
  };

  const handleReset = (): void => {
    const initialValue = {
      name: '',
      title: '',
      userName: '',
      createdAfter: null,
      createdBefore: null,
    };
    reset(initialValue);
    handleSearch(initialValue);
  };

  return (
    <Box
      className={classes.filterWrapper}
      onSubmit={handleSubmit(handleSearch)}
      component="form"
    >
      <FieldWrapper label="filter.chatAttachment.name" percent={30}>
        <TextField
          variant="outlined"
          {...register('name')}
          autoComplete="off"
        />
      </FieldWrapper>

      <FieldWrapper label="filter.chatAttachment.title" percent={30}>
        <TextField
          variant="outlined"
          {...register('title')}
          autoComplete="off"
        />
      </FieldWrapper>

      <Controller
        control={control}
        name="createdAfter"
        render={({ field }) => {
          const { onChange, value } = field;

          return (
            <FieldWrapper
              label="filter.chatAttachment.createdAfter"
              percent={30}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd.MM.yyyy"
                  value={value}
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} />
                  )}
                />
              </LocalizationProvider>
            </FieldWrapper>
          );
        }}
      />

      <Controller
        control={control}
        name="createdBefore"
        render={({ field }) => {
          const { onChange, value } = field;

          return (
            <FieldWrapper
              label="filter.chatAttachment.createdBefore"
              percent={30}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd.MM.yyyy"
                  value={value}
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} />
                  )}
                />
              </LocalizationProvider>
            </FieldWrapper>
          );
        }}
      />

      <Box sx={{ flex: 1 }} />

      <FieldWrapper percent={30}>
        <Box className={classes.buttonWrapper}>
          <Button
            type="submit"
            classes={{
              root: classes.button,
              text: classes.submitButton,
            }}
          >
            {t('button.search')}
          </Button>
          <Button
            classes={{
              root: classes.button,
              text: classes.resetButton,
            }}
            type="reset"
            onClick={handleReset}
          >
            {t('button.reset')}
          </Button>
        </Box>
      </FieldWrapper>
    </Box>
  );
};

export default ChatAttachmentFilters;
