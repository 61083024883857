import isEmpty from 'lodash.isempty';
import minBy from 'lodash.minby';
import maxBy from 'lodash.maxby';
import meanBy from 'lodash.meanby';

type LatencyItem = {
  end: number;
  start: number;
  error: any;
  latency: number;
  success: boolean;
  timedOut: boolean;
};
export type LatencyResult = {
  averageLatency?: number;
  maxLatency?: number;
  minLatency?: number;
  name: string;
  endpoint: string;
  latencyItems: LatencyItem[];
};

export const getConnectInstanceLatencyResult = async (
  connectInstanceUrl: string,
  iterations: number
): Promise<LatencyResult> => {
  const url = new URL(connectInstanceUrl);
  const formattedUrl = `${url.protocol}//${url.hostname}`;
  const instanceName = 'CloudFront';

  const latencyItems = await getLatencyItems(
    getConnectInstanceTestUrl(formattedUrl),
    iterations
  );

  return {
    name: `${instanceName}`,
    endpoint: getConnectInstanceTestUrl(formattedUrl),
    minLatency: Math.round(
      !isEmpty(latencyItems) ? minBy(latencyItems, 'latency')!.latency : 0
    ),
    maxLatency: Math.round(
      !isEmpty(latencyItems) ? maxBy(latencyItems, 'latency')!.latency : 0
    ),
    averageLatency: Math.round(
      !isEmpty(latencyItems) ? meanBy(latencyItems, 'latency') : 0
    ),
    latencyItems: latencyItems,
  } as LatencyResult;
};

const getConnectInstanceTestUrl = (baseEndpoint: string): string => {
  if (baseEndpoint.toLowerCase().endsWith('awsapps.com')) {
    return `${baseEndpoint}/connect/ccp-v2/config/config.json`;
  } else {
    return `${baseEndpoint}/ccp-v2-config/config.json`;
  }
};

const FETCH_TIMEOUT = 5000;

export const LATENCY_THRESHOLDS = {
  OK: {
    low: 0,
    high: 149,
  },
  WARN: {
    low: 150,
    high: 199,
  },
  ERROR: {
    low: 200,
    high: Number.MAX_VALUE,
  },
};

const getLatencyItems = async (endpointUrl: string, iterations: number) => {
  const latencyItems: LatencyItem[] = [] as LatencyItem[];

  await getLatencyItem(endpointUrl);

  for (let i = 0; i < iterations; i++) {
    latencyItems.push(await getLatencyItem(endpointUrl));
  }

  return latencyItems;
};

const getLatencyItem = async (endpointUrl: string): Promise<LatencyItem> => {
  let promiseResolved = false;
  let didTimeout = false;

  return new Promise((resolve) => {
    try {
      const timeout = setTimeout(() => {
        didTimeout = true;

        if (promiseResolved) {
          return;
        } else {
          promiseResolved = true;

          resolve({
            start: -1,
            end: -1,
            latency: 0,
            success: false,
            timedOut: didTimeout,
            error: `Timeout`,
          } as LatencyItem);
        }
      }, FETCH_TIMEOUT);

      const start = performance.now();

      const headers = new Headers();
      headers.append('pragma', 'no-cahche');
      headers.append('cache-control', 'no-cache');

      fetch(`${endpointUrl}`, {
        mode: 'no-cors',
        headers: headers,
      })
        .then((response) => response)
        .then((data) => {
          clearTimeout(timeout);
          if (!didTimeout) {
            promiseResolved = true;
            const end = performance.now();

            resolve({
              start: start,
              end: end,
              latency: end - start,
              success: true,
              timedOut: didTimeout,
              error: null,
            } as LatencyItem);
          }
        })
        .catch((err) => {
          promiseResolved = true;

          resolve({
            start: -1,
            end: -1,
            latency: 0,
            success: false,
            timedOut: didTimeout,
            error: err.toString(),
          } as LatencyItem);
        });
    } catch (err: any) {
      promiseResolved = true;

      resolve({
        start: -1,
        end: -1,
        latency: 0,
        success: false,
        timedOut: didTimeout,
        error: err.toString(),
      } as LatencyItem);
    }
  });
};
