import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { TStatus } from '../../../types/ccp';

export const getStatusIcon = (status?: TStatus): JSX.Element => {
  switch (status) {
    case 'Available':
      return <FiberManualRecordIcon color="secondary" />;
    case 'Offline':
      return <FiberManualRecordIcon color="disabled" />;
    default:
      return <FiberManualRecordIcon color="warning" />;
  }
};
