import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { themeConstants } from '../../../../../assets/styles/defaultTheme/constants';

export const useIncomingTaskTabStyles = makeStyles((theme: Theme) => ({
  tab: {
    backgroundColor: themeConstants.COLOR_PRIMARY,
  },
}));

export const useIncomingTaskTabPanelStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: '2rem',
    display: 'flex',
    gap: '0.5rem',
  },
  incomingButton: {
    '&.MuiButton-root': {
      borderRadius: '0.3rem',
      width: 'calc(50% - .1rem)',
      marginRight: '.1rem',

      '& > svg': {
        marginRight: '.5rem',
        width: '1.4rem',
        height: '1.4rem',
      },
    },
  },
  incomingButtonReject: {
    '&.MuiButton-root': {
      backgroundColor: `${theme.palette.third?.main}14`,
      color: theme.palette.error.main,

      '&:hover': {
        backgroundColor: `${theme.palette.error.main}40`,
      },
    },
  },
  incomingButtonAccept: {
    '&.MuiButton-root': {
      backgroundColor: `${theme.palette.third?.main}14`,
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: `${theme.palette.third?.main}40`,
      },
    },
  },
}));
