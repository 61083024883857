import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useConfig } from '../useConfig';

import { notificationActions } from '../../../store/reducers/notificationReducer';
import { contactRecordApi } from '../../api/contactRecordApi';
import { TNotification } from '../../types/contactRecordTypes';

export const useNotifications = (): TNotification => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState<TNotification>({
    missedCalls: 0,
    newVoicemails: 0,
  });
  const { env } = useConfig();

  const getNotifications = () => {
    contactRecordApi
      .getNotificationCount()
      .then(({ data }) => setNotifications(data))
      .catch((err) =>
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            message: err.response.data,
            type: 'error',
          })
        )
      );
  };

  useEffect(() => {
    getNotifications();

    const interval = setInterval(() => {
      getNotifications();
    }, env?.notificationRefreshRate);

    return () => clearInterval(interval);
  }, []);

  return notifications;
};
