import { AxiosPromise } from 'axios';
import {
  TChatAttachmentElement,
  TChatAttachmentRequest,
  TGetPresignedURLRequest,
} from '../types/chat-attachment-types';
import { TPagedSearch } from '../types/generalTypes';
import apiPaths from './apiPaths';
import { axiosInstance } from './axiosClient';

export const chatAttachmentApi: {
  searchChatAttachment: (
    request: TChatAttachmentRequest
  ) => AxiosPromise<TPagedSearch<TChatAttachmentElement>>;
  getPresignedUrl: (request: TGetPresignedURLRequest) => AxiosPromise<string>;
} = {
  searchChatAttachment: (request) =>
    axiosInstance.post(
      apiPaths.chatAttachments.base + apiPaths.chatAttachments.files,
      request
    ),
  getPresignedUrl: (request) =>
    axiosInstance.get(apiPaths.chatAttachments.base + '/' + request.fileName),
};
