import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Toolbar, IconButton, Box } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { sideNavSelectors } from '../../../store/selectors';
import { sideNavActions } from '../../../store/reducers/sideNavReducer';

import { Header } from './components/header';

import { ReactComponent as Logo } from '../../../assets/images/webhelp-logo-dark.svg';

import { AppBar, Drawer, useStyles } from './side-nav-style';

interface IProps {
  ccp?: (open: boolean) => JSX.Element | null;
}

const SideNav = ({ ccp, children }: React.PropsWithChildren<IProps>) => {
  const classes = useStyles();

  const { isOpen } = useSelector(sideNavSelectors.getSideNavState);
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    dispatch(sideNavActions.toggleOpen({ isOpen: !isOpen }));
  };

  return (
    <Box className={classes.mainWrapper}>
      <AppBar sx={{ height: '45px' }} position="fixed">
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            id="menu-icon"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={classes.menuIcon}
          >
            <Logo className={classes.logo} />
            {isOpen ? (
              <ChevronLeftIcon color="primary" />
            ) : (
              <ChevronRightIcon color="primary" />
            )}
          </IconButton>
          <Header />
        </Toolbar>
      </AppBar>
      <Drawer open={isOpen} variant="permanent">
        {ccp && ccp(isOpen)}
      </Drawer>
      <Box component="main" className={classes.content}>
        {children}
      </Box>
    </Box>
  );
};

export default SideNav;
