import { AxiosPromise } from 'axios';
import {
  TCallTranscript,
  TGetCallToInitiateData,
} from '../types/call-service-types';
import { TCallData } from '../types/ccp';
import apiPaths from './apiPaths';
import { axiosInstance } from './axiosClient';

const {
  callService: { call, transcript },
} = apiPaths;

export const callServiceApi: {
  saveTranscript: (request: TCallTranscript) => AxiosPromise;
  getCallToInitiateData: (
    request: TGetCallToInitiateData,
    abortController?: AbortController
  ) => AxiosPromise<TCallData | null>;
} = {
  saveTranscript: (request) => axiosInstance.post(call + transcript, request),
  getCallToInitiateData: (request, abortController) =>
    axiosInstance.get(call + `/${request.username}`, {
      ...(abortController && {
        signal: abortController.signal,
      }),
    }),
};
