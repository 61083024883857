import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ccpActions } from '../../../../store/reducers/ccpReducer';
import { callAttributesActions } from '../../../../store/reducers/callAttributeReducer';
import { chatApi } from '../../../api/chat-api';
import { IGetTranslationBody, polyglotApi } from '../../../api/polyglot-api';
import { notificationActions } from '../../../../store/reducers/notificationReducer';
import { ccpSelectors, languageSelectors } from '../../../../store/selectors';
import { AxiosError } from 'axios';
import { useConfig } from '../../../hooks/useConfig';
import { TChatContact, TChatEntry } from '../../../types/ccp';

type TMessageTranslation = {
  originalMessage: string;
  messageTranslation: string;
};

type TContactMessagesTranslation = {
  contactId: string;
  messagesTranslation: TMessageTranslation[];
};

const ChatListener = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { env } = useConfig();

  const [isFirstClientMessage, setIsFirstClientMessage] = useState(true);

  const allMessagesTranslation: TContactMessagesTranslation[] = [];

  const isRejectedChatFromStore = useSelector(ccpSelectors.getIsRejectedChat);
  const agentLanguageFromStore = useSelector(languageSelectors.getLanguage);
  const customerLanguageFromStore = useSelector(
    ccpSelectors.getCustomerLanguage
  );
  const isTranslationErrorFromStore = useSelector(
    ccpSelectors.getIsTranslationError
  );
  const activeChatContacts = useSelector(
    ccpSelectors.getChatChannelActiveContacts
  );
  const activeChatTabIndex = useSelector(ccpSelectors.getActiveChatTabIndex);
  const lastOriginalMessageFromStore = useSelector(
    ccpSelectors.getLastOriginalMessage
  );

  const activeChatContactsRef = useRef<TChatContact[]>([]);
  const isRejectedChat = useRef<boolean>(false);
  const translation = useRef('');
  const isTranslationError = useRef(isTranslationErrorFromStore);
  const agentLanguage = useRef(agentLanguageFromStore);
  const lastOriginalMessage = useRef(lastOriginalMessageFromStore);
  const customerLanguage = useRef(customerLanguageFromStore);
  const data = useRef<IGetTranslationBody>({
    contactId: '',
    targetLanguage: '',
    text: '',
    type: 'ORIGINAL',
  });

  useEffect(() => {
    activeChatContactsRef.current = activeChatContacts;
  }, [activeChatContacts]);

  useEffect(() => {
    isRejectedChat.current = isRejectedChatFromStore;
  }, [isRejectedChatFromStore]);

  useEffect(() => {
    agentLanguage.current =
      agentLanguageFromStore === 'us' ? 'en' : agentLanguageFromStore;
  }, [agentLanguageFromStore]);

  useEffect(() => {
    customerLanguage.current = customerLanguageFromStore;
  }, [customerLanguageFromStore]);

  useEffect(() => {
    lastOriginalMessage.current = lastOriginalMessageFromStore;
  }, [lastOriginalMessageFromStore]);

  useEffect(() => {
    isTranslationError.current = isTranslationErrorFromStore;
  }, [isTranslationErrorFromStore]);

  useEffect(() => {
    connect.contact((contact) => {
      contact.onRefresh((contact) => {
        if (contact.getType() !== connect.ContactType.CHAT) {
          return;
        }

        console.log('CHAT - on refresh');
        console.log(
          `CHAT - on refresh, Contact status: ${contact.getStatus().type}`
        );

        // Handle when the CHAT goes into Rejected here
        if (isRejectedChat.current) {
          dispatch(
            ccpActions.setPartialChatChannelIncomingContact({
              curr: contact,
              status: 'Rejected',
              name: t('ccp.chat.rejectedChat'),
            })
          );
          dispatch(ccpActions.removeChatChannelActiveContactIfPresent(contact));
        }
      });

      contact.onConnecting((contact) => {
        if (contact.getType() !== connect.ContactType.CHAT) {
          return;
        }

        console.log('CHAT - on connecting');

        const customerConnection = contact
          .getConnections()
          .find((cnn) => cnn.getType() === connect.ConnectionType.INBOUND);

        dispatch(
          ccpActions.setPartialChatChannelIncomingContact({
            curr: contact,
            status: 'Incoming',
            name:
              (customerConnection as connect.ChatConnection).getMediaInfo()
                .originalInfo.customerName ?? '',
            contactId: contact.contactId,
            queue: contact.getQueue(),
          })
        );
        dispatch(ccpActions.setActiveUiChannel('CHAT'));
      });

      contact.onAccepted(async (contact) => {
        if (contact.getType() !== connect.ContactType.CHAT) {
          return;
        }

        console.log('CHAT - on accepted');

        dispatch(ccpActions.resetChatChannelIncomingContact());
      });

      contact.onConnected(async (contact) => {
        if (contact.getType() !== connect.ContactType.CHAT) {
          return;
        }

        dispatch(callAttributesActions.setShowForm(true));

        console.log('CHAT - on connected');

        const agentConnection = contact
          .getConnections()
          .find((cnn) => cnn.getType() === connect.ConnectionType.AGENT);

        const customerConnection = contact
          .getConnections()
          .find((cnn) => cnn.getType() === connect.ConnectionType.INBOUND);

        const agentChatSession = (await (
          agentConnection as connect.ChatConnection
        ).getMediaController()) as connect.ChatSession;

        allMessagesTranslation.push({
          contactId: contact.contactId,
          messagesTranslation: [],
        });

        agentChatSession.onMessage(async (event) => {
          if (
            event.data.Type === 'MESSAGE' ||
            event.data.Type === 'ATTACHMENT'
          ) {
            data.current = {
              contactId: contact.contactId,
              targetLanguage: agentLanguage.current,
              text: event.data.Content ?? '',
              type:
                event.data.ParticipantRole === 'CUSTOMER'
                  ? 'ORIGINAL'
                  : 'REPLY',
            };

            if (env?.enabledFeatures?.CHAT_TWO_WAY_TRANSLATION) {
              if (event.data.ParticipantRole === 'CUSTOMER') {
                try {
                  const response = await polyglotApi.getTranslation(
                    data.current
                  );

                  if (
                    isFirstClientMessage &&
                    data.current.type === 'ORIGINAL'
                  ) {
                    dispatch(
                      ccpActions.setCustomerLanguage(
                        response.data.sourceLanguage
                      )
                    );
                    setIsFirstClientMessage(false);
                  }
                  translation.current = response.data.translatedText;
                  allMessagesTranslation
                    .find((id) => id.contactId === contact.contactId)
                    ?.messagesTranslation.push({
                      originalMessage: event.data.Content!,
                      messageTranslation: response.data.translatedText,
                    });
                } catch (error: any) {
                  if (
                    ((error?.response as any)?.data.message as string).includes(
                      '400 Bad Request from PUT'
                    )
                  ) {
                    if (isFirstClientMessage)
                      dispatch(
                        ccpActions.setCustomerLanguage(agentLanguage.current)
                      );

                    translation.current = '';
                    allMessagesTranslation
                      .find((id) => id.contactId === contact.contactId)
                      ?.messagesTranslation.push({
                        originalMessage: event.data.Content!,
                        messageTranslation: '',
                      });
                  }
                } finally {
                  dispatch(
                    ccpActions.addChatEntryToChatChannelActiveContact({
                      contactId: contact.contactId,
                      chatEntry: {
                        displayName: event.data.DisplayName ?? '-',
                        timestamp: event.data.AbsoluteTime,
                        participantRole: event.data.ParticipantRole!,
                        message: event.data.Content ?? '',
                        ...(event.data.Type === 'MESSAGE' && {
                          messageTranslation: translation.current,
                        }),
                        type: event.data.Type as 'MESSAGE' | 'ATTACHMENT',
                        ...(event.data.Type === 'ATTACHMENT' && {
                          attachments: event.data.Attachments?.map(
                            (attachment) => ({
                              attachmentId: attachment.AttachmentId,
                              attachmentName: attachment.AttachmentName,
                              contentType: attachment.ContentType,
                            })
                          ),
                        }),
                      },
                    })
                  );
                }
              } else if (event.data.ParticipantRole === 'AGENT') {
                if (!isTranslationError.current) {
                  dispatch(
                    ccpActions.addChatEntryToChatChannelActiveContact({
                      contactId: contact.contactId,
                      chatEntry: {
                        displayName: event.data.DisplayName ?? '-',
                        timestamp: event.data.AbsoluteTime,
                        participantRole: event.data.ParticipantRole!,
                        message: lastOriginalMessage.current,
                        ...(event.data.Type === 'MESSAGE' && {
                          messageTranslation: event.data.Content,
                        }),
                        type: event.data.Type as 'MESSAGE' | 'ATTACHMENT',
                        ...(event.data.Type === 'ATTACHMENT' && {
                          attachments: event.data.Attachments?.map(
                            (attachment) => ({
                              attachmentId: attachment.AttachmentId,
                              attachmentName: attachment.AttachmentName,
                              contentType: attachment.ContentType,
                            })
                          ),
                        }),
                      },
                    })
                  );
                } else {
                  dispatch(
                    ccpActions.addChatEntryToChatChannelActiveContact({
                      contactId: contact.contactId,
                      chatEntry: {
                        displayName: event.data.DisplayName ?? '-',
                        timestamp: event.data.AbsoluteTime,
                        participantRole: event.data.ParticipantRole!,
                        message: lastOriginalMessage.current,
                        ...(event.data.Type === 'MESSAGE' && {
                          messageTranslation: '',
                        }),
                        type: event.data.Type as 'MESSAGE' | 'ATTACHMENT',
                        ...(event.data.Type === 'ATTACHMENT' && {
                          attachments: event.data.Attachments?.map(
                            (attachment) => ({
                              attachmentId: attachment.AttachmentId,
                              attachmentName: attachment.AttachmentName,
                              contentType: attachment.ContentType,
                            })
                          ),
                        }),
                      },
                    })
                  );
                }
              }
            } else {
              dispatch(
                ccpActions.addChatEntryToChatChannelActiveContact({
                  contactId: contact.contactId,
                  chatEntry: {
                    displayName: event.data.DisplayName ?? '-',
                    timestamp: event.data.AbsoluteTime,
                    participantRole: event.data.ParticipantRole!,
                    message: event.data.Content ?? '',
                    ...(event.data.Type === 'MESSAGE' && {
                      messageTranslation: '',
                    }),
                    type: event.data.Type as 'MESSAGE' | 'ATTACHMENT',
                    ...(event.data.Type === 'ATTACHMENT' && {
                      attachments: event.data.Attachments?.map(
                        (attachment) => ({
                          attachmentId: attachment.AttachmentId,
                          attachmentName: attachment.AttachmentName,
                          contentType: attachment.ContentType,
                        })
                      ),
                    }),
                  },
                })
              );
            }
          }
          if (event.data.Type === 'ATTACHMENT') {
            dispatch(
              ccpActions.removeAttachmentNameToWaitForWithTemporaryMessageAndTemporaryMessage(
                {
                  attachmentName:
                    event.data.Attachments?.[0].AttachmentName ?? '',
                  contactId: contact.contactId,
                }
              )
            );
          }
        });

        const result = (
          await agentChatSession.getTranscript({
            maxResults: 100,
            sortOrder: 'ASCENDING',
          })
        ).data as connect.GetTranscriptResult;

        const chatEntries = result.Transcript.filter(
          (item) =>
            (item.Type === 'MESSAGE' &&
              (item.ContentType === 'text/plain' ||
                item.ContentType === 'text/markdown')) ||
            item.Type === 'ATTACHMENT'
        ).map(
          (item) =>
          ({
            participantRole: item.ParticipantRole!,
            message: item.Content!,
            messageTranslation: allMessagesTranslation
              .find(
                (messagesTranslationForId) =>
                  messagesTranslationForId.contactId === item.ContactId
              )
              ?.messagesTranslation.find(
                (messageTransaltionPair) =>
                  messageTransaltionPair.originalMessage === item.Content
              )?.messageTranslation,
            timestamp: item.AbsoluteTime!,
            displayName: item.DisplayName!,
            type: item.Type as 'MESSAGE' | 'ATTACHMENT',
            ...(item.Type === 'ATTACHMENT' && {
              attachments: item.Attachments?.map((attachment) => ({
                attachmentId: attachment.AttachmentId,
                attachmentName: attachment.AttachmentName,
                contentType: attachment.ContentType,
              })),
            }),
          } as TChatEntry)
        );

        dispatch(
          ccpActions.resetChatChannelIncomingContactIfSameAsPayloadContact(
            contact
          )
        );

        dispatch(
          ccpActions.addChatChannelActiveContact({
            curr: contact,
            status: 'Connected',
            contactId: contact.contactId,
            name:
              (customerConnection as connect.ChatConnection).getMediaInfo()
                .originalInfo.customerName ?? '',
            chatEntries: [],
            queue: contact.getQueue(),
            showForm: true,
          })
        );

        dispatch(
          ccpActions.setChatEntriesToChatChannelActiveContact({
            contactId: contact.contactId,
            chatEntries,
          })
        );

        /**
         * Load the chat history by email with transcript.
         */
        chatApi
          .getChatHistoryByEmailWithTranscript({
            contactId: contact.contactId,
          })
          .then(({ data: chatHistoryItemsByEmailWithTranscript }) => {
            dispatch(
              ccpActions.updatePartialChatChannelActiveContactHistoryItemsByEmailWithTranscript(
                {
                  contactId: contact.contactId,
                  historyItemsByEmailWithTranscript:
                    chatHistoryItemsByEmailWithTranscript.map((item) => ({
                      date: item.date,
                      email: item.email,
                      transcript: item.transcript.Transcript.filter(
                        (item) =>
                          (item.Type === 'MESSAGE' &&
                            (item.ContentType === 'text/plain' ||
                              item.ContentType === 'text/markdown')) ||
                          item.Type === 'ATTACHMENT'
                      ).map((transcript) => ({
                        participantRole: transcript.ParticipantRole!,
                        message: transcript.Content!,
                        messageTranslation: allMessagesTranslation
                          .find(
                            (messagesTranslationForId) =>
                              messagesTranslationForId.contactId ===
                              transcript.ContactId
                          )
                          ?.messagesTranslation.find(
                            (messageTransaltionPair) =>
                              messageTransaltionPair.originalMessage ===
                              transcript.Content
                          )?.messageTranslation,
                        timestamp: transcript.AbsoluteTime!,
                        displayName: transcript.DisplayName!,
                        type: transcript.Type as 'MESSAGE' | 'ATTACHMENT',
                        ...(transcript.Type === 'ATTACHMENT' && {
                          attachments: transcript.Attachments?.map(
                            (attachment) => ({
                              attachmentId: attachment.AttachmentId,
                              attachmentName: attachment.AttachmentName,
                              contentType: attachment.ContentType,
                            })
                          ),
                        }),
                      })),
                    })),
                }
              )
            );
          })
          .catch(() => {
            console.error(
              `Failed to load chat history by email with transcript for contactId: ${contact.contactId}`
            );
          });
      });

      contact.onMissed((contact) => {
        if (contact.getType() !== connect.ContactType.CHAT) {
          return;
        }

        console.log('CHAT - on missed');

        dispatch(ccpActions.resetChatChannelIncomingContact());
        dispatch(
          ccpActions.addChatChannelActiveContact({
            curr: contact,
            status: 'Missed',
            contactId: contact.contactId,
            name: t('ccp.chat.missedChat'),
            chatEntries: [],
          })
        );
      });

      contact.onACW((contact) => {
        if (contact.getType() !== connect.ContactType.CHAT) {
          return;
        }

        console.log('CHAT - on acw');
        if (activeChatContactsRef.current[activeChatTabIndex].chatEntries.length > 0) {
          polyglotApi
            .sendChatToBackend(
              activeChatContactsRef.current[activeChatTabIndex].contactId!,
              activeChatContactsRef.current[activeChatTabIndex].chatEntries
            )
            .then(() => {
              dispatch(
                notificationActions.openNotification({
                  isOpen: true,
                  type: 'success',
                  message: 'Your chat was successfully saved!',
                })
              );
            })
            .catch(() => {
              dispatch(
                notificationActions.openNotification({
                  isOpen: true,
                  type: 'error',
                  message: 'There was a problem saving your chat!',
                })
              );
            });
        }

        const customerConnection = contact
          .getConnections()
          .find((cnn) => cnn.getType() === connect.ConnectionType.INBOUND);

        dispatch(
          ccpActions.updateOrCreateChatChannelActiveContact({
            curr: contact,
            status: 'AfterContactWork',
            contactId: contact.contactId,
            name:
              (customerConnection as connect.ChatConnection).getMediaInfo()
                .originalInfo.customerName ?? '',
            chatEntries: [],
          })
        );
      });

      contact.onDestroy((contact) => {
        if (contact.getType() !== connect.ContactType.CHAT) {
          return;
        }

        dispatch(callAttributesActions.setShowForm(false));

        console.log('CHAT - on destroy');

        dispatch(ccpActions.setLastOriginalMessage(''));

        isRejectedChat.current = false;
        customerLanguage.current = '';
        dispatch(ccpActions.setIsRejectedChat(false));
        dispatch(
          ccpActions.resetChatChannelIncomingContactIfSameAsPayloadContact(
            contact
          )
        );
        dispatch(ccpActions.removeChatChannelActiveContactIfPresent(contact));

        const currentSessionIndex = allMessagesTranslation.findIndex(
          (id) => id.contactId === contact.contactId
        );
        allMessagesTranslation.splice(currentSessionIndex, 1);
      });
    });
  }, []);

  return null;
};

export default ChatListener;
