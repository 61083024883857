import { IStore } from '..';
import {
  TCallFilter,
  TCallReducerState,
} from '../../app/types/contactTypes';

export const getFilters = (state: IStore): Partial<TCallFilter> =>
  state.callHistoryReducer.filters;
export const getState = (state: IStore): TCallReducerState =>
  state.callHistoryReducer;
