import { addSeconds } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';

import dateFormat from 'date-fns/format';

export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm:ss';
const DATE_FORMAT = 'yyyy-MM-dd';
/**
 * Proxy for date-fns format method, in order to perform appropriate translation for dates
 * @param date
 * @param format
 */
export const formatDateWithTimezone = (
  date: Date | number,
  structure = DATE_TIME_FORMAT
): string => {
  try {
    const zonedDate = utcToZonedTime(
      date,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    return format(zonedDate, structure);
  } catch (err) {
    return '';
  }
};

export const secondsToTime = (sec: number): string => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;

  return (
    (hours < 10 ? `0${hours}` : hours) +
    ':' +
    (minutes < 10 ? `0${minutes}` : minutes) +
    ':' +
    (seconds < 10 ? `0${seconds}` : seconds)
  );
};

export const formatDate = (date: Date | number, format = DATE_FORMAT) => {
  return dateFormat(date, format);
};

export const secondsToDate = (
  sec: number,
  format: string | undefined = 'HH:mm:ss'
): string => dateFormat(addSeconds(new Date(0, 0, 0, 0, 0, 0, 0), sec), format);

export const setTimeToZero = (inputDate: Date) => {
  const date = new Date(inputDate);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getDateWithTimeZone = (
  timeZone: string,
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  second: number
) => {
  const date = new Date(Date.UTC(year, month, day, hour, minute, second));

  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
  const offset = utcDate.getTime() - tzDate.getTime();

  date.setTime(date.getTime() + offset);

  return date;
};

export const getDistanceInDays = (inputDate1: Date, inputDate2: Date) => {
  const differenceInTime = inputDate2.getTime() - inputDate1.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays;
};

export const stringPadLeft = (str: string, pad: string, length: number) => {
  return (new Array(length + 1).join(pad) + str).slice(-length);
};

export const getMinutesAndSecondsStringFromMilliseconds = (
  timeInMilliseconds: number
) => {
  const timeInSeconds = Math.round(timeInMilliseconds / 1000);
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;

  return (
    stringPadLeft(String(minutes), '0', 2) +
    ':' +
    stringPadLeft(String(seconds), '0', 2)
  );
};
