import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useNotFoundStyles = makeStyles((theme: Theme) => ({
  notFoundWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.text.primary,
    maxHeight: '5rem',
    margin: '1rem 0',

    '& > span.MuiTypography-root': {
      fontSize: '1rem',
      paddingTop: '1rem',
    },
  },
}));
