import { RecursiveAllValues } from '../types/generalTypes';

export type TRoutePath = RecursiveAllValues<typeof ROUTE_PATHS>;

export const ROUTE_PATHS = {
  auth: {
    login: '/login',
    loginDev: '/login-dev',
  },
  main: {
    stats: '/stats',
    contactHistory: '/contact-history',
  },
  troubleshooting: {
    metrics: '/network-metrics',
  },
};
