import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AcceptIncomingTaskIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

import { Box, Button } from '@mui/material';

import { ccpSelectors } from '../../../../../store/selectors';

import { useIncomingChatTabPanelStyles } from './incoming-chat-tab-styles';
import { ccpActions } from '../../../../../store/reducers/ccpReducer';

const IncomingChat = () => {
  const { curr: incomingChatContact } = useSelector(
    ccpSelectors.getChatChannelIncomingContact
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const {
    wrapper,
    incomingButton,
    incomingButtonAccept,
    incomingButtonReject,
  } = useIncomingChatTabPanelStyles();

  // /**
  //  * When this component mounts (when we have an inbound task),
  //  * send a desktop notification (this works if notifications have been granted)
  //  */
  // useEffect(() => {
  //   if (name) {
  //     const inboundCallNotification = new Notification(
  //       t('inboundCallNotifications.title'),
  //       {
  //         body: `${t(
  //           'inboundCallNotifications.incomingCallFrom'
  //         )} ${phoneNumber}`,
  //       }
  //     );

  //     // Focusing on the Agent Panel tab when notification is clicked
  //     inboundCallNotification.onclick = function () {
  //       window.parent.focus();
  //       window.focus(); //just in case, older browsers
  //       this.close();
  //     };
  //   }
  // }, [name, t]);

  if (!incomingChatContact) return null;

  return (
    <Box className={wrapper}>
      <Button
        className={`${incomingButton} ${incomingButtonReject}`}
        onClick={() => {
          dispatch(ccpActions.setIsRejectedChat(true));
          incomingChatContact.reject();
        }}
      >
        <CancelIcon />
        {t('ccp.reject')}
      </Button>

      <Button
        className={`${incomingButton} ${incomingButtonAccept}`}
        onClick={() => incomingChatContact.accept()}
      >
        <AcceptIncomingTaskIcon />
        {t('ccp.accept')}
      </Button>
    </Box>
  );
};

export default IncomingChat;
