import { AxiosPromise } from 'axios';

import { axiosInstance } from './axiosClient';
import apiPaths from './apiPaths';

export const authApi: {
  login: (
    username: string,
    password: string
  ) => AxiosPromise<{ accessToken: string }>;
} = {
  login: (username, password) =>
    axiosInstance.post(apiPaths.auth.login, { username, password }),
};
