import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { useStyles } from './FieldWrapper-style';
import { TOptions, InterpolationOptions } from 'i18next';

type TProps = {
  label?: string;
  options?: TOptions<InterpolationOptions> | string;
  percent?: number;
  disabled?: boolean;
  labelExtraProps?: Record<string, any>;
};

const FieldWrapper: FC<TProps> = ({
  label = '',
  children,
  percent = 100,
  disabled = false,
  options = '',
  labelExtraProps = {},
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ percent, disabled });

  return (
    <Box className={classes.wrapper}>
      <Typography variant="label" component="label">
        {t(label, options)}
      </Typography>
      {children}
    </Box>
  );
};

export default FieldWrapper;
