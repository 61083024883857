import React, { useEffect, useState } from 'react';
import {
  useForm,
  useFieldArray,
  Controller,
  UseFormRegister,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import FieldWrapper from '../../../../shared/fieldWrapper/FieldWrapper';
import { themeConstants } from '../../../../../assets/styles/defaultTheme/constants';

import {
  getDateWithTimeZone,
  getDistanceInDays,
  setTimeToZero,
} from '../../../../../utils/dateUtils';
import { notificationActions } from '../../../../../store/reducers/notificationReducer';
import timezones from '../../../../../utils/timezones.json';

type TTaskCreatorFormContent = {
  name: string;
  description: string;
  references: {
    name: string;
    link: string;
  }[];
  assignToEndpointARN: string;
  date: Date | null;
  time: Date | null;
  timezone: typeof timezones[0] | null;
};

const COMMON_FORM_BOX_STYLES = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
};

const COMMON_CONTROL_LABEL_STYLES = {
  fontSize: 14,
  fontWeight: 'bold',
};

export interface ITaskCreator {
  onCancel: () => void;
  onCreatedSuccessfully: () => void;
}

const mergeDateDateWithTimeDate = (date: Date, time: Date) => {
  const combinedDateAndTime = new Date(date);
  combinedDateAndTime.setHours(
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds()
  );

  return combinedDateAndTime;
};

const URL_REGEX = 'https://([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

const isUrlValid = (url: string): boolean => {
  return url.match(URL_REGEX) !== null;
};

interface IGetTaskCreatorTextField {
  register: UseFormRegister<TTaskCreatorFormContent>;
  index: number;
  t: (arg: string) => string;
}
const getTaskCreatorTextField = ({
  register,
  index,
  t,
  ...rest
}: IGetTaskCreatorTextField & React.ComponentProps<typeof TextField>) => {
  const { onChange, ...restRegisterProps } = register(
    `references.${index}.link`,
    {
      required: t('error.required'),
      validate: (v: any) => {
        if (!isUrlValid(v)) {
          return `${t('error.validation.url')}`;
        }
        return undefined;
      },
    }
  );

  const _onChange = (event: any) => {
    const data: string = event.target.value;

    /**
     * If we have a double protocol, remove the first one
     */
    if (data.includes('https://https://') || data.includes('https://http://')) {
      event.target.value = data.slice(8);
    }

    onChange(event);
  };

  return <TextField onChange={_onChange} {...restRegisterProps} {...rest} />;
};

const TaskCreator = ({ onCancel, onCreatedSuccessfully }: ITaskCreator) => {
  const { t } = useTranslation();

  const [quickConnects, setQuickConnects] = useState<connect.Endpoint[]>([]);
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    trigger,
    control,
    setValue,
  } = useForm<TTaskCreatorFormContent>({
    defaultValues: {
      name: '',
      description: '',
      references: [],
      assignToEndpointARN: '',
      date: null,
      time: null,
      timezone: null,
    },
    reValidateMode: 'onSubmit',
  });
  const references = useFieldArray({
    control,
    name: 'references',
  });

  const dispatch = useDispatch();

  const handleTaskCreation = (data: TTaskCreatorFormContent) => {
    let combinedDateAndTime: Date | null = null;
    if (data.time && data.date && data.timezone) {
      combinedDateAndTime = mergeDateDateWithTimeDate(data.date, data.time);
      combinedDateAndTime = getDateWithTimeZone(
        data.timezone.tzCode,
        data.date.getFullYear(),
        data.date.getMonth(),
        data.date.getDate(),
        data.time.getHours(),
        data.time.getMinutes(),
        data.time.getSeconds()
      );
      /**
       * Additional checks regarding fields
       */
      const now = new Date();
      // If in the past, not okay
      if (combinedDateAndTime.getTime() < new Date().getTime()) {
        setFormErrorMessage(t('ccp.task.dateTimeCantBeInThePast'));
        return;
      }
      // If more than 6 days in the future, not okay
      if (Math.abs(getDistanceInDays(combinedDateAndTime, now)) > 6) {
        setFormErrorMessage(t('ccp.task.dateTimeCantBeAfter6Days'));
        return;
      }
    }

    setFormErrorMessage(null);

    const endpoint = quickConnects.find(
      (quickConnect) => quickConnect.endpointARN === data.assignToEndpointARN
    );

    /**
     * If no endpoint found, something is wrong - most probably a bug in the code, we've got nothing to do...
     */
    if (!endpoint) return;

    const mappedNewTaskData: connect.TaskContactDefinition = {
      name: data.name,
      description: data.description ?? '',
      endpoint,
      references: data.references.reduce(
        (prev, current) => ({
          ...prev,
          [current.name]: {
            type: 'URL',
            value: current.link,
          },
        }),
        {}
      ),
      scheduledTime: combinedDateAndTime
        ? Math.floor(combinedDateAndTime.getTime() / 1000)
        : 0,
      idempotencyToken: String(Math.random()),
    };

    const agent = new connect.Agent();
    agent.createTask(mappedNewTaskData, {
      success: () => {
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'success',
            message: t('ccp.task.taskCreatedSuccessfully'),
          })
        );
        onCreatedSuccessfully();
      },
      failure: () => {
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'error',
            message: t('ccp.task.taskCreationFailed'),
          })
        );
      },
    });
  };

  useEffect(() => {
    // Load all the quick connects

    const agent = new connect.Agent();

    const queuesARNs = agent.getAllQueueARNs();

    agent.getEndpoints(queuesARNs, {
      success: (data) => {
        setQuickConnects(
          data.endpoints.filter(
            (endpoint) => endpoint.type !== connect.EndpointType.PHONE_NUMBER
          )
        );
      },
    });
  }, []);

  const { date, time } = watch();

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleTaskCreation)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <Typography sx={{ fontWeight: 500 }}>
          {t('ccp.task.createTask')}
        </Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          ...COMMON_FORM_BOX_STYLES,
        }}
      >
        <Typography
          sx={{
            ...COMMON_CONTROL_LABEL_STYLES,
          }}
        >
          {t('ccp.task.name')}
        </Typography>
        <TextField
          variant="outlined"
          {...register('name', {
            required: t('error.required'),
            minLength: {
              value: 1,
              message: `${t('error.validation.textMinLength', { count: 1 })}`,
            },
            maxLength: {
              value: 150,
              message: `${t('error.validation.textMaxLength', { count: 150 })}`,
            },
          })}
          size="small"
          error={!!errors?.name}
          helperText={errors.name?.message}
          data-test-id="task_creator_task_name_input_field"
        />
      </Box>

      <Box
        sx={{
          paddingTop: 2,
          ...COMMON_FORM_BOX_STYLES,
        }}
      >
        <Typography
          sx={{
            ...COMMON_CONTROL_LABEL_STYLES,
          }}
        >
          {t('ccp.task.description')}
          <Typography
            sx={{
              fontWeight: 'normal',
              fontSize: 'inherit',
            }}
            component="span"
          >
            &nbsp;{t('optionalInParanthesis')}
          </Typography>
        </Typography>
        <TextField
          variant="outlined"
          multiline
          minRows={3}
          maxRows={3}
          {...register('description', {
            maxLength: {
              value: 4096,
              message: `${t('error.validation.textMaxLength', {
                count: 4096,
              })}`,
            },
          })}
          size="small"
          error={!!errors?.description}
          helperText={errors.description?.message}
          data-test-id="task_creator_task_description_input_field"
        />
      </Box>

      <Box
        sx={{
          ...COMMON_FORM_BOX_STYLES,
          paddingTop: 2,
        }}
      >
        <Typography
          sx={{
            ...COMMON_CONTROL_LABEL_STYLES,
          }}
        >
          {t('ccp.task.references')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {references.fields.map((field, index) => (
            <Box
              key={field.id}
              sx={{
                backgroundColor: themeConstants.COLOR_PRIMARY_LIGHTER,
                padding: 1,
                position: 'relative',
              }}
            >
              <IconButton
                onClick={() => {
                  references.remove(index);
                }}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
              <FieldWrapper label="ccp.task.references.name">
                <TextField
                  {...register(`references.${index}.name`, {
                    required: t('error.required'),
                    minLength: {
                      value: 1,
                      message: `${t('error.validation.textMinLength', {
                        count: 1,
                      })}`,
                    },
                  })}
                  error={!!errors?.references?.[index]?.name}
                  helperText={errors.references?.[index]?.name?.message}
                ></TextField>
              </FieldWrapper>
              <FieldWrapper label="ccp.task.references.link">
                {getTaskCreatorTextField({
                  register,
                  index,
                  t,
                  error: !!errors?.references?.[index]?.link,
                  helperText: errors.references?.[index]?.link?.message,
                })}
              </FieldWrapper>
            </Box>
          ))}
          <Button
            sx={{
              textTransform: 'none',
              fontSize: 14,
              fontWeight: 600,
            }}
            onClick={() => {
              references.append({
                name: '',
                link: 'https://',
              });
            }}
            data-test-id="task_creator_add_reference_button"
          >
            {t('ccp.task.createReference')}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          ...COMMON_FORM_BOX_STYLES,
          paddingTop: 2,
        }}
      >
        <Typography
          sx={{
            ...COMMON_CONTROL_LABEL_STYLES,
          }}
        >
          {t('ccp.task.assignTo')}
        </Typography>
        <Select
          {...register('assignToEndpointARN', {
            required: t('error.required'),
          })}
        >
          {quickConnects.map((quickConnect) => (
            <MenuItem
              value={quickConnect.endpointARN}
              key={quickConnect.endpointARN}
            >
              {quickConnect.name}
            </MenuItem>
          ))}
        </Select>
        {!!errors?.assignToEndpointARN && (
          <FormHelperText error>
            {errors?.assignToEndpointARN.message}
          </FormHelperText>
        )}
      </Box>

      <Box
        sx={{
          ...COMMON_FORM_BOX_STYLES,
          paddingTop: 2,
        }}
      >
        <Typography
          sx={{
            ...COMMON_CONTROL_LABEL_STYLES,
          }}
        >
          {t('ccp.task.scheduledDateAndTime')}
          <Typography
            sx={{
              fontWeight: 'normal',
              fontSize: 'inherit',
            }}
            component="span"
          >
            &nbsp;{t('optionalInParanthesis')}
          </Typography>
        </Typography>

        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          {t('ccp.task.date')}
        </Typography>
        <Controller
          control={control}
          rules={{
            validate: (v: Date | null) => {
              if (v) {
                if (v.toString() === 'Invalid Date') {
                  return `${t('error.invalidDate')}`;
                }
                /**
                 * Check that the chosen date is not in the past
                 */
                const nowDate = setTimeToZero(new Date());
                const chosenDate = setTimeToZero(v);
                if (nowDate.getTime() > chosenDate.getTime())
                  return t('ccp.task.dateTimeCantBeInThePast');
              } else {
                if (time) {
                  return t('error.required');
                }
              }
              return undefined;
            },
          }}
          name="date"
          render={({ field }) => {
            const { onChange, value } = field;

            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd.MM.yyyy"
                  value={value}
                  minDate={new Date()}
                  onChange={(newValue) => {
                    onChange(newValue);
                    setFormErrorMessage(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      size="small"
                      error={!!errors?.date}
                      helperText={errors?.date?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            );
          }}
        />

        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          {t('ccp.task.time')}
        </Typography>
        <Controller
          control={control}
          rules={{
            validate: (v: Date | null) => {
              if (v) {
                if (v.toString() === 'Invalid Date') {
                  return `${t('error.invalidTime')}`;
                }
              } else {
                if (date) {
                  return t('error.required');
                }
              }
              return undefined;
            },
          }}
          name="time"
          render={({ field }) => {
            const { onChange, value } = field;

            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors?.time}
                      helperText={errors?.time?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            );
          }}
        />

        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          {t('ccp.task.timezone')}
        </Typography>
        <Controller
          control={control}
          rules={{
            validate: (v) => {
              if (!v) {
                if (date || time) {
                  return t('error.required');
                }
              }
              return undefined;
            },
          }}
          name="timezone"
          render={({ field }) => {
            const { onChange, value } = field;
            return (
              <Autocomplete
                options={timezones}
                getOptionLabel={(timezone) => {
                  return timezone.name;
                }}
                onChange={(_, newValue) => onChange(newValue)}
                value={value}
                renderInput={(params) => (
                  <TextField
                    error={!!errors?.timezone}
                    helperText={(errors?.timezone as any)?.message}
                    {...params}
                  />
                )}
              />
            );
          }}
        />
      </Box>

      {(time || date) && (
        <Button
          sx={{
            textTransform: 'none',
            fontSize: 14,
            fontWeight: 600,
          }}
          onClick={() => {
            setValue('date', null);
            setValue('time', null);
            trigger();
          }}
          data-test-id="task_creator_add_reference_button"
        >
          {t('ccp.task.clearDateTime')}
        </Button>
      )}

      {formErrorMessage && (
        <FormHelperText error>{formErrorMessage}</FormHelperText>
      )}
      <Box
        sx={{
          paddingTop: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          type="submit"
          data-test-id="task_creator_create_button"
        >
          {t('button.create')}
        </Button>
        <Button onClick={onCancel} data-test-id="task_creator_cancel_button">
          {t('button.cancel')}
        </Button>
      </Box>
    </Box>
  );
};
export default TaskCreator;
