import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';

import authReducer, { authReducerInitialState } from './reducers/authReducer';
import callAttributesReducer, {
  callAttributesReducerInitialState,
} from './reducers/callAttributeReducer';
import notificationReducer, {
  notificationReducerInitialState,
} from './reducers/notificationReducer';
import modalReducer, {
  modalReducerInitialState,
} from './reducers/modalReducer';
import screenPopUpsReducer, {
  screenPopUpsReducerInitialState,
} from './reducers/screenPopUpsReducer';
import ccpReducer, { ccpReducerInitialState } from './reducers/ccpReducer';
import configReducer, {
  configReducerInitialState,
} from './reducers/configReducer';
import callHistoryReducer, {
  callInitialState,
} from './reducers/callHistoryReducer';
import sideNavReducer, { sideNavInitialState } from './reducers/sideNavReducer';
import callNotificationsReducer, {
  callNotificationsReducerInitialState,
} from './reducers/call-notifications-reducer';
import automatedCallReducer, {
  automatedCallInitialState,
} from './reducers/automated-call-reducer';
import languageReducer, {
  languageReducerInitialState,
} from './reducers/language-reducer';
import chatAttachmentFilterReducer, {
  chatAttachmentFilterInitialState,
} from './reducers/chat-attachment-filter-reducer';

export const globalInitialState: IStore = {
  notificationReducer: notificationReducerInitialState,
  authReducer: authReducerInitialState,
  callAttributesReducer: callAttributesReducerInitialState,
  modalReducer: modalReducerInitialState,
  screenPopUpsReducer: screenPopUpsReducerInitialState,
  ccpReducer: ccpReducerInitialState,
  configReducer: configReducerInitialState,
  callHistoryReducer: callInitialState,
  sideNavReducer: sideNavInitialState,
  automatedCallReducer: automatedCallInitialState,
  callNotificationsReducer: callNotificationsReducerInitialState,
  languageReducer: languageReducerInitialState,
  chatAttachmentFilterReducer: chatAttachmentFilterInitialState,
};

const reducer = combineReducers({
  notificationReducer,
  authReducer,
  callAttributesReducer,
  modalReducer,
  screenPopUpsReducer,
  ccpReducer,
  configReducer,
  callHistoryReducer,
  sideNavReducer,
  automatedCallReducer,
  callNotificationsReducer,
  languageReducer,
  chatAttachmentFilterReducer,
});

const store = configureStore({
  reducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;

export type IStore = ReturnType<typeof reducer>;

export type AppThunk = ThunkAction<void, IStore, null, Action<string>>;
