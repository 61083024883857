import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Button, Typography } from '@mui/material';
import AcceptIncomingTaskIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

import CloseIcon from '@mui/icons-material/Close';

import { ccpSelectors } from '../../../../../store/selectors';

import {
  useIncomingStyle,
  useButtonStyles,
} from './incoming-chat-modal-styles';

export interface IIncomingChatModal {
  onAction: () => void;
}

const IncomingChatModal = ({ onAction }: IIncomingChatModal) => {
  const { curr: incomingChatContact, name } = useSelector(
    ccpSelectors.getChatChannelIncomingContact
  );

  const { t } = useTranslation();
  const { wrapper, textWrapper, buttonsWrapper } = useIncomingStyle();
  const { incomingButton, incomingButtonAccept, incomingButtonReject } =
    useButtonStyles();

  if (!incomingChatContact) return null;

  return (
    <Box className={wrapper}>
      <Box className={textWrapper}>
        <Typography
          component="label"
          sx={{
            fontWeight: '500',
            fontSize: 14,
          }}
        >
          {t('ccp.incomingChat')}
        </Typography>
        <Typography
          component="p"
          sx={{
            fontSize: 14,
          }}
        >
          {name}
        </Typography>
      </Box>
      <Box className={buttonsWrapper}>
        <Button
          className={`${incomingButton} ${incomingButtonReject}`}
          onClick={() => {
            incomingChatContact.reject();
            onAction();
          }}
        >
          <CancelIcon />
        </Button>
        <Button
          className={`${incomingButton} ${incomingButtonAccept}`}
          onClick={() => {
            incomingChatContact.accept();
            onAction();
          }}
        >
          <AcceptIncomingTaskIcon />
        </Button>
      </Box>
      <CloseIcon
        onClick={onAction}
        sx={{
          position: 'absolute',
          top: -8,
          right: -2,
          padding: 1,
          width: 15,
          cursor: 'pointer',
        }}
      />
    </Box>
  );
};

export default IncomingChatModal;
