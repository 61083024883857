import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { STYLE_CONSTANTS } from '../../../assets/styles/defaultTheme/constants';

interface IProps {
  percent: number;
  disabled: boolean;
}

export const useStyles = makeStyles<Theme, IProps>(() => ({
  wrapper: (props) => ({
    ...STYLE_CONSTANTS.FLEX.COLUMN,
    justifyContent: 'space-around',
    margin: '.5rem',
    textAlign: 'left',
    flex: `1 0 ${props.percent}%`,
    opacity: props.disabled ? 0.5 : 1,
    pointerEvents: props.disabled ? 'none' : 'initial',

    '& > .MuiTypography-root': {
      marginBottom: '.5rem',
    },
  }),
}));
