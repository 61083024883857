import { IStore } from '..';
import {
  TCcpReducerState,
  TChannel,
  TVoiceContact,
  TOutboundCall,
  TRecording,
  TStatus,
  TTaskContact,
  TChatContact,
} from '../../app/types/ccp';

export const getActiveUiChannel = (state: IStore): TChannel =>
  state.ccpReducer.activeUiChannel;
export const getVoiceChannelContact = (state: IStore): TVoiceContact =>
  state.ccpReducer.voice.contact;
export const getStatus = (state: IStore): TStatus | undefined =>
  state.ccpReducer.status;
export const getVoiceChannelPhoneNumber = (state: IStore): string =>
  state.ccpReducer.voice.phoneNumber;
export const getState = (state: IStore): TCcpReducerState => state.ccpReducer;
export const getVoiceChannelIsOnHold = (state: IStore): boolean =>
  state.ccpReducer.voice.isOnHold;
export const getVoiceChannelIsMuted = (state: IStore): boolean =>
  state.ccpReducer.voice.isMuted;
export const getVoiceChannelIsOutboundCall = (state: IStore): boolean =>
  state.ccpReducer.voice.isOutboundCall;
export const getVoiceChannelRecording = (state: IStore): TRecording =>
  state.ccpReducer.voice.recording;
export const getDialpadVisibility = (state: IStore): boolean =>
  state.ccpReducer.visibility.isDialpadOpen;
export const getHeaderVisibility = (state: IStore): boolean =>
  state.ccpReducer.visibility.isHeaderOpen;
export const getVoiceChannelOutboundCall = (state: IStore): TOutboundCall =>
  state.ccpReducer.voice.outboundCall;
export const getVoiceChannelCountry = (state: IStore): string =>
  state.ccpReducer.voice.outboundCall.country;
export const getCallHoldTime = (state: IStore): number =>
  state.ccpReducer.voice.callHoldTime;
  export const getContactId = (state: IStore): string =>
  state.ccpReducer.voice.contactId;
  export const getOutboundCallType = (state: IStore): string =>
  state.ccpReducer.voice.outboundCallType;
  export const getIsDialpadDisabled = (state: IStore): boolean =>
  state.ccpReducer.voice.isDialpadDisabled;
  export const getDisabledNextContactOutboundCampaign = (state: IStore): boolean =>
  state.ccpReducer.voice.isNextContactDisabled; 
  export const getIsOutboundCampaignAvailable = (state: IStore): boolean =>
  state.ccpReducer.voice.isOutboundCampaignAvailable;  
export const getQuickConnectVisibility = (state: IStore): boolean =>
  state.ccpReducer.visibility.isQuickConnectsOpen;
export const getQuickConnects = (
  state: IStore
): connect.Endpoint[] | undefined => state.ccpReducer.quickConnects;
export const getChannelsActivationMap = (
  state: IStore
): Record<TChannel, boolean> => state.ccpReducer.channelsActivationMap;
export const getTaskChannelIncomingContact = (state: IStore): TTaskContact =>
  state.ccpReducer.task.incomingContact;
export const getTaskChannelActiveContacts = (state: IStore): TTaskContact[] =>
  state.ccpReducer.task.activeContacts;
export const getChatChannelIncomingContact = (state: IStore): TChatContact =>
  state.ccpReducer.chat.incomingContact;
export const getChatChannelActiveContacts = (state: IStore): TChatContact[] =>
  state.ccpReducer.chat.activeContacts;
export const getActiveChatTabIndex = (state: IStore): number =>
  state.ccpReducer.chat.activeChatTabIndex;
export const getIsRejectedChat = (state: IStore): boolean =>
  state.ccpReducer.chat.isRejectedChat;
export const getCustomerLanguage = (state: IStore): string => 
  state.ccpReducer.chat.customerLanguage;
export const getLastOriginalMessage = (state: IStore): string => 
  state.ccpReducer.chat.lastOriginalMessage;
export const getIsTranslationError = (state: IStore): boolean => 
  state.ccpReducer.chat.isTranslationError;
