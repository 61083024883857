import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { notificationActions } from '../../../store/reducers/notificationReducer';
import { chatAttachmentFiltersSelectors } from '../../../store/selectors';
import { chatAttachmentApi } from '../../api/chat-attachment-api';
import { TPagedSearchV1 } from '../../types/generalTypes';
import {
  TChatAttachmentElement,
  TChatAttachmentRequest,
} from '../../types/chat-attachment-types';

type TReturn = {
  chatAttachments: TChatAttachmentElement[];
  isLoading: boolean;
  totalNumberOfPages: number;
  refetch: (shouldSetIsLoading?: boolean) => void;
};

const initialPageState = {
  elements: [],
  pageNumber: 0,
  totalNumberOfPages: 1,
};

const useSearchChatAttachments = (): TReturn => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [chatAttachmentsPage, setChatAttachmentsPage] =
    useState<TPagedSearchV1<TChatAttachmentElement>>(initialPageState);
  const dispatch = useDispatch();

  const { filters, page, forceRefresh, sortingOptions } = useSelector(
    chatAttachmentFiltersSelectors.getState
  );

  const query = useMemo<TChatAttachmentRequest>(
    () => ({
      ...filters,

      page: { page: page, size: 10 },
      sortingOptions: [sortingOptions],
      type: "ATTACHMENT",
    }),
    [filters, page, sortingOptions, forceRefresh]
  );

  const loadData = useCallback(
    (shouldSetIsLoading = true) => {
      if (shouldSetIsLoading) {
        setIsLoading(true);
      }
      chatAttachmentApi
        .searchChatAttachment(query)
        .then(({ data }) => setChatAttachmentsPage(data))
        .catch(({ error }) =>
          dispatch(
            notificationActions.openNotification({
              isOpen: true,
              type: 'error',
              message: error?.response?.message,
            })
          )
        )
        .finally(() => {
          if (shouldSetIsLoading) {
            setIsLoading(false);
          }
        });
    },
    [query]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const chatAttachments: TChatAttachmentElement[] = useMemo(
    () => chatAttachmentsPage.elements.map((attachment) => ({ ...attachment })),
    [chatAttachmentsPage, t]
  );

  return {
    chatAttachments,
    totalNumberOfPages: chatAttachmentsPage.totalNumberOfPages,
    isLoading,
    refetch: loadData,
  };
};

export default useSearchChatAttachments;
