import { AxiosPromise } from 'axios';

import { axiosInstance } from './axiosClient';
import apiPaths from './apiPaths';
import { TWaitingContacts } from '../types/agentStats';
import { TKeyObject, UUID } from '../types/generalTypes';

const {
  connectService: { connect, contactInQueues, contactAttributes },
} = apiPaths;

export const connectServiceApi: {
  getContactsInQueues: () => AxiosPromise<TWaitingContacts[]>;
  getContactAttributes: (contactId: UUID) => AxiosPromise<TKeyObject>;
  setContactAttributes: (contactId: UUID, value: TKeyObject) => AxiosPromise;
} = {
  getContactsInQueues: () => axiosInstance.get(connect + contactInQueues),
  getContactAttributes: (contactId) =>
    axiosInstance.get(connect + contactAttributes + `/${contactId}`),
  setContactAttributes: (contactId, value) =>
    axiosInstance.post(connect + contactAttributes + `/${contactId}`, value),
};
