import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={3} ref={ref} variant="filled" {...props} />
));

interface IProps {
  isOpen: boolean;
  close: () => void;
  message: string;
  type: 'error' | 'warning' | 'info' | 'success';
}

export const Toast: FC<IProps> = ({ isOpen, close, message, type }) => {
  const { t } = useTranslation();

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    close();
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      key={`${isOpen}`}
    >
      <Alert sx={{ fontSize: '1rem' }} severity={type} onClose={handleClose}>
        {message || t('error.unknown')}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
