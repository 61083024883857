import { TKeyObject } from '../types/generalTypes';
import { TPopUpParams } from '../types/screenPopUpsTypes';

/**
 * Returns the newUrl which is composed by baseUrl+parameters
 * @param urlConfig
 * @param baseUrl it is configured by the admin in screen-popup
 * @param contact
 * @param agent
 */
export const configureNewUrl = (
  urlConfig: TPopUpParams[],
  baseUrl: string,
  contact: connect.Contact,
  agent: connect.Agent
): string => {
  const attributes: TKeyObject<{ value: string }> =
    contact.getAttributes();

  let newUrl = baseUrl?.includes('?') ? baseUrl : `${baseUrl}?`;

  urlConfig?.forEach((param) => {
    switch (param.sourceType) {
      case 'STATIC':
        newUrl = `${newUrl}${param.name}=${param.value}&`;
        break;
      case 'CONNECT_SYSTEM':
        newUrl = `${newUrl}${param.name}=${getConnectSystemAttributes(
          contact,
          agent,
          param.value
        )}&`;
        break;
      case 'CONTACT_ATTRIBUTE':
        newUrl = `${newUrl}${param.name}=${
          attributes[param.value]?.value || ''
        }&`;
        break;
      default:
        break;
    }
  });

  newUrl = newUrl.slice(0, -1);

  return newUrl;
};

/**
 * Returns the value for the parameters from connect system
 * @param contact
 * @param agent
 * @param param - parameter from connect system
 */
export const getConnectSystemAttributes = (
  contact: connect.Contact,
  agent: connect.Agent,
  param: string
): string | undefined => {
  switch (param) {
    case 'QUEUE_ARN':
      return contact.getQueue().queueARN;
    case 'QUEUE_NAME':
      return contact.getQueue().name;
    case 'CUSTOMER_ENDPOINT_ADDRESS':
      return contact.getConnections()[1].getAddress().phoneNumber;
    case 'CONTACT_ID':
      return contact.getContactId();
    case 'INITIAL_CONTACT_ID':
      return contact.getInitialContactId();
    case 'CONTACT_CHANNEL':
      return contact.getType();
    case 'AGENT_USERNAME':
      return agent.getConfiguration().username;
    case 'AGENT_NAME':
      return agent.getConfiguration().name;
    case 'INITIATION_METHOD':
      return contact.getInitialConnection().getType();
    default:
      return;
  }
};
