import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { useLoaderStyles } from './Loader-style';

interface IProps {
  label?: string;
}

const Loader: FC<IProps> = ({ label = 'loading' }) => {
  const { loaderWrapper } = useLoaderStyles();
  const { t } = useTranslation();

  return (
    <Box className={loaderWrapper}>
      <CircularProgress />
      <Typography component="span" color="primary">
        {t(label)}
      </Typography>
    </Box>
  );
};

export default Loader;
