import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { STYLE_CONSTANTS } from '../../../../../assets/styles/defaultTheme/constants';

export const useDialpadStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    margin: '0.5rem 1rem',
    borderBottom: `1px solid ${theme.palette.primary.main}25`,
  },
  display: {
    ...STYLE_CONSTANTS.FLEX.COLUMN,
    backgroundColor: `${theme.palette.third?.main}14`,
    marginBottom: '1rem',
  },
  column: {
    ...STYLE_CONSTANTS.FLEX.COLUMN,
  },
  rowWrapper: {
    display: 'flex',
    width: '90%',
    margin: '0 5%',
    borderBottom: `1px solid ${theme.palette.primary.main}25`,
    overflow: 'hidden',

    '&:first-of-type': {
      borderRadius: '.5rem .5rem 0 0',
    },

    '&:last-of-type': {
      borderRadius: '0 0 .5rem .5rem',
      border: 'none',
    },
  },
  deleteIcon: {
    '&.MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
      width: '1.5rem',
      height: '1.5rem',
      cursor: 'pointer',
    },
  },
  numberWrapper: {
    ...STYLE_CONSTANTS.FLEX.SPACE_BETWEEN,
    padding: '0 .7rem 0 .3rem',
    borderRadius: '4px 4px 0 0',
    width: '70%',
    alignSelf: 'center',
  },
  numberInput: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '1rem',
    width: '90%',
    margin: '0 auto',
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    outline: 0,
    borderBottom: `1px solid ${theme.palette.primary.main}60`,

    // other browsers - hide arrows
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    // firefox
    '-moz-appearance': 'textfield',
  },
  numberInputError: {
    backgroundColor: `${theme.palette.error.main}26`,
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  errorTooltip: {
    '&.MuiTooltip-tooltip': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
  },
  button: {
    border: 0,
    backgroundColor: 'transparent',
    fontSize: '20px',
    flex: '1 0 33%',
    textAlign: 'center',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 500,
    color: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}18`,
    },
  },
  callButton: {
    '&.MuiButton-root': {
      width: '100%',
      fontWeight: 500,
      borderTop: `1px solid ${theme.palette.common.white}`,
      borderRadius: '0 0 4px 4px',
      padding: '10px 0',

      '& > svg': {
        marginRight: '.5rem',
        width: '1.4rem,',
        height: '1.4rem',
      },
    },
  },
  menuButton: {
    '&.MuiButton-root': {
      width: 'calc(100% - 2rem)',
      margin: '1rem 1rem 0',
      textTransform: 'none',

      '& > svg': {
        marginRight: '.5rem',
        width: '1.2rem',
        height: '1.2rem',
      },

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}15`,
      },
    },
  },
  countryFlag: {
    width: '25px',
    height: '25px',
  },
  countryCode: {
    '&.MuiTypography-root': {
      fontWeight: 500,
      alignSelf: 'center',
      fontSize: '1rem',
    },
  },
}));

export const useOutboundFormStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: `${theme.palette.third?.main}14`,
    borderBottom: `1px solid ${theme.palette.common.white}`,
    borderRadius: '.3rem .3rem 0 0',
    padding: '.5rem 1rem',
  },
  radioButton: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.1rem',
    },
  },
  radioButtonText: {
    '& .MuiTypography-root': {
      fontSize: '.8rem',
      fontWeight: 600,
    },
  },
  header: {
    '&.MuiTypography-root': {
      fontWeight: 700,
      fontSize: '1rem',

      '&::after': {
        content: '":"',
      },
    },
  },
  textInput: {
    width: '45%',
    margin: '.3rem 0',
    borderRadius: '.3rem',
    border: `1px solid ${theme.palette.primary.main}80`,
    padding: '0 .5rem',
    fontFamily: theme.typography.fontFamily,

    '&:focus': {
      outline: 0,
      borderColor: theme.palette.primary.main,
    },
  },
  fieldWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));
