import { makeStyles } from '@mui/styles';
import { STYLE_CONSTANTS } from '../../../assets/styles/defaultTheme/constants';

export const useLoaderStyles = makeStyles(() => ({
  loaderWrapper: {
    ...STYLE_CONSTANTS.FLEX.COLUMN,
    alignItems: 'center',
    textAlign: 'center',

    '& > span': {
      fontSize: '1.2rem',
      fontWeight: 500,
      maxHeight: '5rem',
    },
  },
  marginWrapper: {
    margin: '.5rem 0',

    '& > span': {
      margin: '.5rem',
    },
  },
}));
