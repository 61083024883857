import React from 'react';

import {
  createTheme,
  SimplePaletteColorOptions,
  ThemeOptions,
} from '@mui/material/styles';

import { themeConstants } from './constants';

declare module '@mui/material/styles' {
  interface Palette {
    third?: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    third?: SimplePaletteColorOptions;
  }

  interface ButtonPropsColorOverrides {
    third?: SimplePaletteColorOptions;
  }

  interface TypographyVariants {
    label?: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    third: true;
  }
}

const baseTheme: ThemeOptions = {
  palette: {
    primary: {
      main: themeConstants.COLOR_SECONDARY,
      dark: themeConstants.COLOR_SECONDARY_DARKER,
    },
    secondary: {
      main: themeConstants.COLOR_PRIMARY,
      dark: themeConstants.COLOR_PRIMARY_DARKER,
    },
    third: {
      main: themeConstants.COLOR_THIRD,
    },
    error: {
      main: themeConstants.COLOR_ERROR_LIGHTER,
    },
    text: {
      primary: themeConstants.COLOR_TEXT,
    },
    warning: {
      main: themeConstants.COLOR_WARNING,
      dark: themeConstants.COLOR_WARNING_DARKER,
    },
    success: {
      main: themeConstants.COLOR_PRIMARY,
    },
    info: {
      main: themeConstants.COLOR_INFO,
    },
    common: {
      black: themeConstants.COLOR_BLACK,
      white: themeConstants.COLOR_WHITE,
    },
  },
  typography: {
    fontFamily: themeConstants.PRIMARY_FONT,
    fontSize: 10,
    htmlFontSize: 10,
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightLight: 100,
    fontWeightRegular: 400,
    allVariants: {
      fontFamily: 'Montserrat',
    },
    h1: {
      fontSize: '1.4rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.1rem',
      fontWeight: 600,
    },
    label: {
      fontSize: '.99rem',
    },
  },
};

export default createTheme(baseTheme);
