import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Button, Typography } from '@mui/material';
import AcceptIncomingTaskIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';

import { ccpSelectors } from '../../../../../store/selectors';

import {
  useIncomingStyle,
  useButtonStyles,
} from './incoming-task-modal-styles';

export interface IIncomingTaskModal {
  onAction: () => void;
}

const IncomingTaskModal = ({ onAction }: IIncomingTaskModal) => {
  const { curr: incomingTaskContact, name } = useSelector(
    ccpSelectors.getTaskChannelIncomingContact
  );

  const { t } = useTranslation();
  const { wrapper, textWrapper, buttonsWrapper } = useIncomingStyle();
  const { incomingButton, incomingButtonAccept, incomingButtonReject } =
    useButtonStyles();

  if (!incomingTaskContact) return null;

  return (
    <Box className={wrapper}>
      <Box className={textWrapper}>
        <Typography
          component="label"
          sx={{
            fontWeight: '500',
            fontSize: 14,
          }}
        >
          {t('ccp.incomingTask')}
        </Typography>
        <Typography
          component="p"
          sx={{
            fontSize: 14,
          }}
        >
          {name}
        </Typography>
      </Box>
      <Box className={buttonsWrapper}>
        <Button
          className={`${incomingButton} ${incomingButtonReject}`}
          onClick={() => {
            incomingTaskContact.reject();
            onAction();
          }}
        >
          <CancelIcon />
        </Button>
        <Button
          className={`${incomingButton} ${incomingButtonAccept}`}
          onClick={() => {
            incomingTaskContact.accept();
            onAction();
          }}
        >
          <AcceptIncomingTaskIcon />
        </Button>
      </Box>
      <CloseIcon
        onClick={onAction}
        sx={{
          position: 'absolute',
          top: -8,
          right: -2,
          padding: 1,
          width: 15,
          cursor: 'pointer',
        }}
      />
    </Box>
  );
};

export default IncomingTaskModal;
