import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TCallFilter,
  TCallReducerState,
  TSorting,
} from '../../app/types/contactTypes';

export const callInitialState: TCallReducerState = {
  filters: {
    dateFrom: null,
    dateTo: null,
  },
  forceRefresh: true,
  page: 0,
  sort: { sortDirection: 'ASC', sortBy: 'DATE_TIME' },
};

const callHistoryReducer = createSlice({
  name: 'callHistoryReducer',
  initialState: callInitialState,
  reducers: {
    setFilters: (state, { payload }: PayloadAction<Partial<TCallFilter>>) => {
      state.filters = payload;
      state.forceRefresh = !state.forceRefresh;
    },
    setVoicemailFilter: (state, { payload }: PayloadAction<string>) => {
      state.filters.voicemail = payload;
      state.forceRefresh = !state.forceRefresh;
    },
    setCallStatusFilter: (state, { payload }: PayloadAction<string>) => {
      state.filters.callStatus = payload;
      state.forceRefresh = !state.forceRefresh;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSorting: (state, { payload }: PayloadAction<TSorting>) => {
      state.sort = payload;
      state.forceRefresh = !state.forceRefresh;
    },
  },
});

export const callActions = callHistoryReducer.actions;

export default callHistoryReducer.reducer;
