import axios from 'axios';

import storageUtils from '../../utils/storageUtils';
import { ROUTE_PATHS } from '../routes/routePaths';
import store from '../../store';
import { authActions } from '../../store/reducers/authReducer';

export const axiosInstance = axios.create({
  baseURL: process.env.NX_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 300000,
});

axiosInstance.interceptors.request.use((config) => {
  const token = storageUtils.getAuthToken();

  config.headers = Object.assign(
    {
      Authorization: token,
    },
    config.headers
  );

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { dispatch, getState } = store;
    if (error?.response?.status === 401 || error?.response?.status === 402 || error?.response?.status === 403) {
      connect.agent(async (agent) => {
        await setToOffline().then(() => {
          storageUtils.clearLocalUserData();
          if (agent.getState().name === "Offline") {
            fetch(`${getState().configReducer.env?.connectInstance}/logout`, {
              method: 'GET',
              credentials: 'include',
              mode: 'no-cors',
              keepalive: true,
            });
            connect.core.terminate();

            dispatch(authActions.logout());
          }
        });
        window.history.replaceState({}, '', ROUTE_PATHS.auth.login);
      });
    }

    return Promise.reject(error);
  }
);

const setToOffline = async (): Promise<void> => {
  connect.agent(async (agent) => {
    const states = agent.getAgentStates();
    const offlineStatus = states.filter(
      (state) => state.name === 'Offline'
    )[0];
    try {
      agent.setState(offlineStatus);
    } catch (error: any) { console.log("There was an error setting the agent state", error) }
  });
}

