import { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import _ from 'lodash';

import { COUNTRY_CODES } from './constants';
import { ccpActions } from '../../../../../store/reducers/ccpReducer';
import { ccpSelectors } from '../../../../../store/selectors';

import { useDialpadStyles } from './dialpad-style';

interface IProps {
  onSelect: (id: string) => void;
  isDisabled: boolean;
}

const CountrySelector = ({ onSelect, isDisabled }: IProps) => {
  const { countryFlag, numberInputError, numberInput } = useDialpadStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const country = useSelector(ccpSelectors.getVoiceChannelCountry);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');

  const [countrySearched, setCountrySearched] = useState('');
  const anchorRef = useRef<HTMLButtonElement>(null);

  const agent = new connect.Agent();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setCountrySearched('');
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const dialableCountries = _.pick(COUNTRY_CODES, agent.getDialableCountries());

  const listOfSortedCountryCodes = Object.keys(dialableCountries).sort();

  const countrySearchedList = useMemo(
    () =>
      listOfSortedCountryCodes?.filter((el) => {
        return t(`country.${el}`)
          ?.toLowerCase()
          .includes(countrySearched.toLowerCase());
      }),
    [countrySearched, dialableCountries]
  );

  return (
    <Box sx={{ zIndex: 99, display: 'flex' }}>
      <Button
        disabled={isDisabled}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Box
          component="img"
          className={countryFlag}
          alt={country}
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country.toUpperCase()}.svg`}
        />
        <ArrowDropDownIcon color="primary" />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  sx={{ paddingLeft: 2, zIndex: 999 }}
                >
                  <Box
                    className={`${numberInput} ${!!error && numberInputError}`}
                    component="input"
                    value={countrySearched}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCountrySearched(event.target.value);
                    }}
                  />
                  {countrySearchedList.map((c) => (
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      placement="bottom-end"
                      title={`${t(`country.${c}`)}`}
                    >
                      <MenuItem
                        onClick={() => {
                          onSelect(COUNTRY_CODES[c]);

                          dispatch(
                            ccpActions.setVoiceChannelOutboundCall({
                              country: c,
                            })
                          );
                          setOpen(false);
                        }}
                        sx={{
                          display: 'flex',
                          flex: '0 1 30%',
                          padding: '3px 10px',
                          fontSize: '.9rem',
                        }}
                      >
                        <Box
                          component="img"
                          className={countryFlag}
                          alt={c}
                          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${c.toUpperCase()}.svg`}
                        />
                        <Box
                          sx={{
                            marginLeft: 1,
                            minWidth: '30px',
                          }}
                        >
                          {COUNTRY_CODES[c]}
                        </Box>
                        <Box
                          sx={{
                            marginLeft: 1,
                          }}
                        >
                          {t(`country.${c}`)}
                        </Box>
                      </MenuItem>
                    </Tooltip>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default CountrySelector;
