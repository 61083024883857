import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useNetworkStatusStyles = makeStyles((theme: Theme) => ({
  checkingNetworkWrapper: {
    animation: `$pulsate 1s linear`,
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
  },
  '@keyframes pulsate': {
    '0%': {
      opacity: '10%',
    },
    '100': {
      opacity: '100%',
    },
  },
}));
