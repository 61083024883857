import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import ChatAttachmentFilters from './chat-attachments-listing/chat-attachment-filters';
import ChatAttachmentListing from './chat-attachments-listing/chat-attachment-listing';
import { chatAttachmentApi } from '../../../../../api/chat-attachment-api';
import { notificationActions } from '../../../../../../store/reducers/notificationReducer';
import Loader from '../../../../loader/Loader';

import { useModalStyles } from './modal-style';

interface IChatAttachmentBrowse {
  onClose: () => void;
  onSelectFile: (file: File) => void;
}

const ChatAttachmentBrowse = ({
  onClose,
  onSelectFile,
}: IChatAttachmentBrowse) => {
  const { modal, modalWrapper, modalHeader, filterSectionWrapper } =
    useModalStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async ({ fileName }: any) => {
    try {
      setIsLoading(true);
      const { data: fileURL } = await chatAttachmentApi.getPresignedUrl({
        fileName,
      });

      try {
        const downloadedFileResponse = await fetch(fileURL);
        if (downloadedFileResponse.ok) {
          const downloadedFileBlob = await downloadedFileResponse.blob();

          const file = new File([downloadedFileBlob], fileName, {
            type:
              downloadedFileResponse.headers.get('Content-Type') ?? 'image/jpg',
          });

          onSelectFile(file);

          onClose();
        } else {
          throw new Error('Nothing to do here..');
        }
      } catch {
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'error',
            message: t('chatAttachments.failedToDownloadFile'),
          })
        );
      }
    } catch {
      dispatch(
        notificationActions.openNotification({
          isOpen: true,
          type: 'error',
          message: t('chatAttachments.failedToFetchFileURL'),
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open onClose={onClose}>
      <Box
        className={`${modal} ${modalWrapper}`}
        sx={{
          position: 'relative',
        }}
      >
        <Typography className={modalHeader} variant="h1">
          {t('ccp.chat.attachments.selectPredefinedFile')}
          <CloseIcon onClick={onClose} color="primary" />
        </Typography>
        <Box sx={{ marginTop: 2 }}>
          <Box className={filterSectionWrapper}>
            <ChatAttachmentFilters />
          </Box>
          <ChatAttachmentListing handleClick={handleClick} />
        </Box>
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#ffffffB0',
            }}
          >
            <Loader />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ChatAttachmentBrowse;
