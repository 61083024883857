import { notificationActions } from '../../../store/reducers/notificationReducer';
import React, { Dispatch } from 'react';
import i18next from 'i18next';

export class ErrorBoundary extends React.Component<{
  dispatch: Dispatch<any>;
}> {
  componentDidCatch(error: Error): void {
    this.props.dispatch(
      notificationActions.openNotification({
        isOpen: true,
        type: 'error',
        message: error.message || i18next.t('error.general'),
      })
    );
  }

  render() {
    return this.props.children;
  }
}
