import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TConfigReducer } from '../../app/types/configType';

export const configReducerInitialState: TConfigReducer = {};

const configReducer = createSlice({
  name: 'configReducer',
  initialState: configReducerInitialState,
  reducers: {
    setConfig: (_, { payload }: PayloadAction<TConfigReducer>) => payload,
  },
});

export const configActions = configReducer.actions;

export default configReducer.reducer;
