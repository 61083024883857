import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Typography,
  styled,
  Tooltip,
  IconButton,
  Input,
  Menu,
  MenuItem,
} from '@mui/material';
import IncomingChatNotificationIcon from '@mui/icons-material/NewReleases';
import TimeIcon from '@mui/icons-material/AccessTime';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CloseIcon from '@mui/icons-material/Close';
import AttachmentIcon from '@mui/icons-material/Attachment';
import QuickConnectsIcon from '@mui/icons-material/PeopleAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SourceIcon from '@mui/icons-material/Source';

import {
  ccpSelectors,
  languageSelectors,
} from '../../../../../store/selectors';
import {
  TChatAttachment,
  TChatContact,
  TChatContactStatus,
  TChatEntry,
} from '../../../../types/ccp';
import {
  getMinutesAndSecondsStringFromMilliseconds,
  stringPadLeft,
} from '../../../../../utils/dateUtils';
import { ccpActions } from '../../../../../store/reducers/ccpReducer';
import IncomingChatModal from '../incoming-chat-modal';
import { IncomingChatTabPanel } from '../incoming-chat-tab';
import ChatQuickConnects from '../chat-quick-connects';
import ChatAttachmentBrowse from './chat-attachment-browse';
import TaskCreator from '../../task/task-creator';
import { downloadFile } from '../../../../../utils/downloadUtils';
import { useConfig } from '../../../../hooks/useConfig';
import { notificationActions } from '../../../../../store/reducers/notificationReducer';

import { themeConstants } from '../../../../../assets/styles/defaultTheme/constants';
import { IGetTranslationBody, polyglotApi } from '../../../../api/polyglot-api';
import { AxiosError } from 'axios';

interface ITabPanelProps {
  isShown: boolean;
  chat: TChatContact;
  onCreateTaskIntention: () => void;
  onQuickConnectIntention: () => void;
  onEndChatIntention: () => void;
  onCloseContactIntention: () => void;
  onSendMessage: (message: string) => Promise<any>;
  onSendAttachment: (attachmentFile: File) => void;
  onDownloadAttachment: (attachment: TChatAttachment) => void;
}

const ATTACHMENT_MENU_ITEM_STYLES = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 1,
};

function TabPanel({
  isShown,
  chat,
  onCreateTaskIntention,
  onQuickConnectIntention,
  onEndChatIntention,
  onCloseContactIntention,
  onSendMessage,
  onSendAttachment,
  onDownloadAttachment,
}: ITabPanelProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { env } = useConfig();

  const smallCreateTaskButton = (
    <IconButton
      title={t('ccp.task.createTask')}
      onClick={onCreateTaskIntention}
    >
      <AssignmentIcon sx={{ color: themeConstants.COLOR_SECONDARY }} />
    </IconButton>
  );

  const fullSizeCreateTaskButton = (
    <Button
      title={t('ccp.task.createTask')}
      onClick={onCreateTaskIntention}
      sx={{
        display: 'flex',
        gap: 1,
        textTransform: 'none',
      }}
    >
      <AssignmentIcon sx={{ color: themeConstants.COLOR_SECONDARY }} />
      {t('ccp.task.createTask')}
    </Button>
  );

  const quickConnectsButton = (
    <IconButton
      title={t('ccp.quickConnects')}
      onClick={onQuickConnectIntention}
    >
      <QuickConnectsIcon sx={{ color: themeConstants.COLOR_SECONDARY }} />
    </IconButton>
  );

  const endChatButton = (
    <Button
      sx={{
        display: 'flex',
        gap: 1,
        textTransform: 'none',
      }}
      onClick={onEndChatIntention}
    >
      <CancelIcon sx={{ color: themeConstants.COLOR_RED }} />
      {t('ccp.chat.endChat')}
    </Button>
  );

  const closeContactButton = (
    <Button
      sx={{
        display: 'flex',
        gap: 1,
        textTransform: 'none',
      }}
      onClick={onCloseContactIntention}
      variant="contained"
    >
      {t('ccp.task.closeContact')}
    </Button>
  );

  /**
   * Scroll the chat entries container to the bottom
   * whenever a new chat entry comes.
   */
  const chatEntriesContainerRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    chatEntriesContainerRef.current?.scrollTo({
      top: chatEntriesContainerRef.current.scrollHeight,
    });
  }, [chat.chatEntries.length, isShown]);

  const [messageToSend, setMessageToSend] = useState('');

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedAttachmentFile, setSelectedAttachmentFile] =
    useState<File | null>(null);

  const [isProblemSendingMessage, setIsProblemSendingMessage] =
    useState<boolean>(false);

  const sendMessage = async () => {
    const fileTypesAccepted = [
      'csv',
      'doc',
      'docx',
      'jpeg',
      'jpg',
      'pdf',
      'png',
      'ppt',
      'pptx',
      'txt',
      'wav',
      'xls',
      'xlsx',
    ];

    if (messageToSend) {
      try {
        await onSendMessage(messageToSend);
        setMessageToSend('');
        setIsProblemSendingMessage(false);
      } catch {
        setIsProblemSendingMessage(true);
        setMessageToSend('');
        return;
      }
    }
    if (selectedAttachmentFile) {
      if (selectedAttachmentFile.size > 20971520) {
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'error',
            message: t('ccp.chat.notification.fileCannotExceed'),
          })
        );
      } else if (selectedAttachmentFile.size === 0) {
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'error',
            message: t('ccp.chat.notification.emptyFile'),
          })
        );
      } else if (
        !fileTypesAccepted.includes(
          selectedAttachmentFile.name
            .split('.')
            [selectedAttachmentFile.name.split('.').length - 1].toLowerCase()
        )
      ) {
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'error',
            message: t('ccp.chat.notification.invalidType'),
          })
        );
      } else {
        onSendAttachment(selectedAttachmentFile);
      }
      setSelectedAttachmentFile(null);
    }
  };

  const [attachmentMenuAnchorEl, setAttachmentMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const isAttachmentMenuOpen = Boolean(attachmentMenuAnchorEl);
  const handleAttachmentsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAttachmentMenuAnchorEl(event.currentTarget);
  };
  const handleAttachmentsMenuClose = () => {
    setAttachmentMenuAnchorEl(null);
  };

  const [
    isPredefinedAttachmentBrowseOpen,
    setIsPredefinedAttachmentBrowseOpen,
  ] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  return (
    <Box>
      {isShown && (
        <Box
          sx={{
            paddingTop: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {chat.status !== 'Incoming' &&
            chat.status !== 'Missed' &&
            chat.status !== 'Rejected' && (
              <Box
                sx={{
                  // TODO remove these magic numbers!
                  height: 'calc(100vh - 370px)',
                  overflow: 'auto',
                  display: 'flex',
                  paddingRight: 2,
                  flexDirection: 'column',
                  gap: 2,
                }}
                ref={chatEntriesContainerRef}
              >
                {chat.chatEntries.map((chatEntry) => {
                  let dateOfTimestamp;
                  let formattedChatEntryTime;

                  if (chatEntry.timestampStringOverwrite) {
                    formattedChatEntryTime = chatEntry.timestampStringOverwrite;
                  } else {
                    dateOfTimestamp = new Date(chatEntry.timestamp);
                    formattedChatEntryTime = `${stringPadLeft(
                      String(dateOfTimestamp.getHours()),
                      '0',
                      2
                    )}:${stringPadLeft(
                      String(dateOfTimestamp.getMinutes()),
                      '0',
                      2
                    )}`;
                  }

                  return (
                    <Box key={chatEntry.timestamp}>
                      <Box
                        sx={{
                          display: 'grid',
                          rowGap: 1 / 2,
                          columnGap: 1,
                          gridTemplateRows: 'auto auto',
                          ...((chatEntry.participantRole === 'CUSTOMER' ||
                            chatEntry.participantRole === 'SYSTEM') && {
                            gridTemplateColumns: 'minmax(0, 1fr) auto',
                            gridTemplateAreas: `
                            'display-name display-name'
                            'chat-entry chat-entry-time'
                            `,
                          }),
                          ...(chatEntry.participantRole === 'AGENT' && {
                            gridTemplateColumns: 'auto minmax(0, 1fr)',
                            gridTemplateAreas: `
                            '. display-name'
                            'chat-entry-time chat-entry'
                            `,
                          }),
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 500,
                            gridArea: 'display-name',
                          }}
                        >
                          {chatEntry.displayName}
                        </Typography>

                        <Box
                          sx={{
                            padding: 3 / 2,
                            backgroundColor:
                              chatEntry.participantRole === 'AGENT'
                                ? themeConstants.COLOR_PRIMARY_LIGHTER
                                : themeConstants.COLOR_GREY_LIGHTER,
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: 1,
                            gridArea: 'chat-entry',
                          }}
                        >
                          {(chatEntry.type === 'MESSAGE' ||
                            chatEntry.type === 'TEMPORARY_MESSAGE') && (
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 500,
                                  whiteSpace: 'break-spaces',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {chatEntry.message}
                              </Typography>
                              {env?.enabledFeatures?.CHAT_TWO_WAY_TRANSLATION &&
                                chatEntry.messageTranslation && chatEntry.messageTranslation !== chatEntry.message && (
                                  
                                  //check if chatEntry.messageTranslation is false or set it to false
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 500,
                                      whiteSpace: 'break-spaces',
                                      wordBreak: 'break-word',
                                      color:
                                        themeConstants.COLOR_PRIMARY_DARKER,
                                    }}
                                  >
                                    {chatEntry.messageTranslation}
                                  </Typography>
                                )}
                            </Box>
                          )}
                          {chatEntry.type === 'ATTACHMENT' && (
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontWeight: 500,
                                whiteSpace: 'break-spaces',
                                color: '#0049ff',
                                cursor: 'pointer',
                              }}
                              component="a"
                              onClick={() => {
                                if (chatEntry.attachments?.[0]) {
                                  onDownloadAttachment(
                                    chatEntry.attachments[0]
                                  );
                                }
                              }}
                            >
                              {chatEntry.attachments?.[0]?.attachmentName}
                            </Typography>
                          )}
                        </Box>

                        <Typography
                          sx={{
                            fontSize: 12,
                            gridArea: 'chat-entry-time',
                            alignSelf: 'center',
                          }}
                        >
                          {formattedChatEntryTime}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          {chat.status === 'Connected' && (
            <Box
              sx={{
                marginTop: 1,
                borderTop: '1px solid lightgrey',
                borderBottom: '1px solid lightgrey',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {selectedAttachmentFile && (
                <Box
                  sx={{
                    borderRadius: 1,
                    backgroundColor: themeConstants.COLOR_PRIMARY_LIGHTER,
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'start',
                    maxWidth: '100%',
                    paddingLeft: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 10,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {selectedAttachmentFile.name}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setSelectedAttachmentFile(null);
                    }}
                  >
                    <CancelIcon
                      sx={{
                        width: 20,
                        height: 20,
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                {env?.enabledFeatures?.CCP_CHAT_ATTACHMENTS && (
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Input
                      inputRef={fileInputRef}
                      type="file"
                      sx={{
                        width: 0,
                        height: 0,
                        overflow: 'hidden',
                      }}
                      onChange={(event) => {
                        const files = (event.target as any).files;
                        if (Array.isArray([...files]) && files.length === 1) {
                          setSelectedAttachmentFile(files[0] as File);
                        }
                      }}
                      // Allow users to select the same file more than once
                      // https://thewebdev.info/2021/05/09/how-to-allow-file-input-to-select-the-same-file-in-react-component/
                      onClick={(event: any) => {
                        event.target.value = null;
                      }}
                      inputProps={{
                        // Copied over from AWS CCP
                        accept:
                          'text/plain,application/pdf,image/jpeg,image/png,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,audio/wav,audio/x-wav,audio/vnd.wave,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv',
                      }}
                    />
                    {isPredefinedAttachmentBrowseOpen && (
                      <ChatAttachmentBrowse
                        onClose={() => {
                          setIsPredefinedAttachmentBrowseOpen(false);
                        }}
                        onSelectFile={setSelectedAttachmentFile}
                      />
                    )}
                    <Menu
                      anchorEl={attachmentMenuAnchorEl}
                      open={isAttachmentMenuOpen}
                      onClose={handleAttachmentsMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleAttachmentsMenuClose();
                          fileInputRef.current?.click();
                        }}
                      >
                        <Box sx={ATTACHMENT_MENU_ITEM_STYLES}>
                          <FileUploadIcon />
                          {t('ccp.chat.attachments.browseLocalFile')}
                        </Box>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleAttachmentsMenuClose();
                          setIsPredefinedAttachmentBrowseOpen(true);
                        }}
                      >
                        <Box sx={ATTACHMENT_MENU_ITEM_STYLES}>
                          <SourceIcon />
                          {t('ccp.chat.attachments.selectPredefinedFile')}
                        </Box>
                      </MenuItem>
                    </Menu>
                    <IconButton
                      sx={{
                        transform: 'rotate(270deg)',
                      }}
                      onClick={handleAttachmentsClick}
                    >
                      <AttachmentIcon
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                      />
                    </IconButton>
                  </Box>
                )}
                <Input
                  sx={{
                    width: '100%',
                    fontSize: 14,
                    height: 40,
                  }}
                  value={messageToSend}
                  onChange={(event) => {
                    setMessageToSend(event.target.value);
                  }}
                  onKeyDown={async (event) => {
                    if (
                      event.key === 'Enter' &&
                      !event.shiftKey &&
                      !isInputDisabled
                    ) {
                      event.stopPropagation();
                      event.preventDefault();
                      setIsInputDisabled(true);
                      await sendMessage();
                      setMessageToSend('');
                      setIsInputDisabled(false);
                    }
                  }}
                  multiline
                  disableUnderline
                  maxRows={2}
                  placeholder={t('ccp.chat.messageBoxPlaceholder')}
                />
              </Box>
              {isProblemSendingMessage && (
                <Box
                  sx={{
                    padding: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: 'red',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {t('ccp.chat.problemSendingMessage')}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {chat.wasContactTransferred && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                marginTop: 2,
                textAlign: 'center',
                wordBreak: 'break-all',
                whiteSpace: 'normal',
              }}
            >
              {t('ccp.contactWasTransferred')}
            </Typography>
          )}
          {(chat.status === 'Rejected' || chat.status === 'Missed') && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                marginTop: 2,
                textAlign: 'center',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
              }}
            >
              {t('ccp.chat.cannotAcceptMoreUntilClosed')}
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 3,
              marginTop: 2,
            }}
          >
            {chat.status === 'Connected' && (
              <>
                {smallCreateTaskButton}
                {quickConnectsButton}
                {endChatButton}
              </>
            )}
            {(chat.status === 'AfterContactWork' ||
              chat.status === 'Rejected') && (
              <>
                {fullSizeCreateTaskButton}
                {closeContactButton}
              </>
            )}
            {chat.status === 'Missed' && closeContactButton}
            {chat.status === 'Incoming' && <IncomingChatTabPanel />}
          </Box>
        </Box>
      )}
    </Box>
  );
}

interface IStyledTabsProps {
  children: React.ReactNode;
}

const StyledTabs = styled((props: IStyledTabsProps) => <Box {...props} />)({
  display: 'flex',
  gap: '4px',
  overflowX: 'auto',
});

type TChatTabType = TChatContactStatus;

type StyledTabProps = {
  type: TChatTabType;
  selected: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const StyledTab = styled(({ type, ...rest }: StyledTabProps) => (
  <Box {...rest} />
))(({ theme, type, selected }) => ({
  minWidth: '130px',

  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  cursor: 'pointer',
  backgroundColor: 'white',
  color: 'black',

  ...(type === 'Incoming' && {
    ...(selected && {
      backgroundColor: 'green',
      color: 'white',
    }),
  }),

  ...(type === 'Connected' && {
    ...(selected && {
      backgroundColor: themeConstants.COLOR_SECONDARY,
      color: 'white',
    }),
  }),

  ...(type === 'AfterContactWork' && {
    ...(selected && {
      backgroundColor: 'grey',
      color: 'white',
    }),
  }),

  ...(type === 'Missed' && {
    backgroundColor: themeConstants.COLOR_RED,
    color: 'white',
  }),

  ...(type === 'Rejected' && {
    backgroundColor: themeConstants.COLOR_RED,
    color: 'white',
  }),

  '& .text-to-show-in-full-width-tab': {
    display: 'none',
  },

  '&:first-child:last-child': {
    width: '100%',

    '& .text-to-show-in-full-width-tab': {
      display: 'block',
    },
  },
}));

type ChatTab =
  | {
      type: 'Incoming';
      chatName: string;
      selected: boolean;
    }
  | {
      type: 'Connected' | 'AfterContactWork';
      chatName: string;
      selected: boolean;
      timeString: string;
    }
  | {
      type: 'Missed';
      chatName: string;
      selected: boolean;
      timeString: string;
      onClose: () => void;
    }
  | {
      type: 'Accepted';
      chatName: string;
      selected: boolean;
      timeString: string;
    }
  | {
      type: 'Initial';
      chatName: string;
      selected: boolean;
      timeString: string;
    }
  | {
      type: 'Rejected';
      chatName: string;
      selected: boolean;
      timeString: string;
    };

const ChatTab = ({
  type,
  chatName,
  ...restProps
}: ChatTab & StyledTabProps) => {
  const { t } = useTranslation();
  const shouldShowElapsedTime =
    type === 'Connected' ||
    type === 'Rejected' ||
    type === 'AfterContactWork' ||
    type === 'Missed';

  return (
    <StyledTab type={type} {...restProps}>
      <Tooltip
        placement="bottom"
        arrow
        title={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: 130,
            }}
          >
            <Typography
              sx={{
                gridArea: 'chat-name',
                fontSize: 'inherit',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontWeight: '500',
              }}
            >
              {chatName}
            </Typography>

            {shouldShowElapsedTime && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1 / 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 'inherit',
                    fontWeight: '500',
                  }}
                >
                  {t('ccp.task.elapsedTime')}:&nbsp;
                </Typography>
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {(restProps as any).timeString}
                </Typography>
              </Box>
            )}

            {type === 'Incoming' && (
              <Typography
                sx={{
                  fontSize: 'inherit',
                }}
              >
                {t('ccp.incomingChat')}
              </Typography>
            )}
            {type === 'AfterContactWork' && (
              <Typography
                sx={{
                  fontSize: 'inherit',
                }}
              >
                {t('ccp.chat.afterChatWork')}
              </Typography>
            )}
            {type === 'Connected' && (
              <Typography
                sx={{
                  fontSize: 'inherit',
                }}
              >
                {t('ccp.chat.connectedChat')}
              </Typography>
            )}
            {type === 'Rejected' && (
              <Typography
                sx={{
                  fontSize: 'inherit',
                }}
              >
                {t('ccp.chat.rejectedChat')}
              </Typography>
            )}
            {type === 'Missed' && (
              <Typography
                sx={{
                  fontSize: 'inherit',
                }}
              >
                {t('ccp.chat.missedChat')}
              </Typography>
            )}
          </Box>
        }
      >
        <Box
          sx={{
            padding: 1,
            paddingBottom: 1 / 2,
            display: 'grid',
            gridTemplateColumns: 'minmax(0,1fr) auto',
            gridTemplateRows: 'auto auto',
            gridTemplateAreas: `
            'chat-name top-right-side'
            'extra-info extra-info'
            `,
            alignItems: 'center',
            columnGap: 1,
            color: 'inherit',
            fontSize: 12,
          }}
        >
          <Typography
            sx={{
              gridArea: 'chat-name',
              fontSize: 'inherit',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontWeight: '500',
            }}
          >
            {chatName}
          </Typography>
          <Box
            sx={{
              gridArea: 'top-right-side',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              height: 18,
            }}
          >
            {type === 'Incoming' && (
              <IncomingChatNotificationIcon
                sx={{
                  height: 18,
                }}
              />
            )}
            {type === 'AfterContactWork' && (
              <HourglassEmptyIcon
                sx={{
                  height: 18,
                }}
              />
            )}
            {(type === 'Missed' || type === 'Rejected') && (
              <CloseIcon
                sx={{
                  height: 18,
                }}
                onClick={(restProps as any).onClose}
              />
            )}
          </Box>
          <Box
            sx={{
              gridArea: 'extra-info',
              height: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {shouldShowElapsedTime && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1 / 2,
                  }}
                >
                  <TimeIcon
                    sx={{
                      width: 20,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 'inherit',
                    }}
                  >
                    {(restProps as any).timeString}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box className="text-to-show-in-full-width-tab">
              {type === 'Incoming' && (
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {t('ccp.incomingChat')}
                </Typography>
              )}
              {type === 'AfterContactWork' && (
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {t('ccp.chat.afterChatWork')}
                </Typography>
              )}
              {type === 'Connected' && (
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {t('ccp.chat.connectedChat')}
                </Typography>
              )}
              {type === 'Rejected' && (
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {t('ccp.chat.rejectedChat')}
                </Typography>
              )}
              {type === 'Missed' && (
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {t('ccp.chat.missedChat')}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Tooltip>
    </StyledTab>
  );
};

const ChatUi = () => {
  const [isTaskCreatorShown, setIsTaskCreatorShown] = useState(false);
  const [areQuickConnectsShown, setAreQuickConnectsShown] = useState(false);

  const { t } = useTranslation();
  const { env } = useConfig();

  const agentLanguageFromStore = useSelector(languageSelectors.getLanguage);
  const customerLanguageFromStore = useSelector(
    ccpSelectors.getCustomerLanguage
  );

  const customerLanguage = useRef(customerLanguageFromStore);
  const agentLanguage = useRef(agentLanguageFromStore);
  const data = useRef<IGetTranslationBody>({
    contactId: '',
    targetLanguage: '',
    text: '',
    type: 'ORIGINAL' || 'REPLY',
  });
  const lastTranslatedMessage = useRef('');

  useEffect(() => {
    agentLanguage.current =
      agentLanguageFromStore === 'us' ? 'en' : agentLanguageFromStore;
  }, [agentLanguageFromStore]);

  useEffect(() => {
    customerLanguage.current = customerLanguageFromStore;
  }, [customerLanguageFromStore]);

  const incomingContact = useSelector(
    ccpSelectors.getChatChannelIncomingContact
  );

  const {
    curr: incomingChatContact,
    name: incomingChatContactName,
    status: incomingChatContactStatus,
    stateDurationMs: incomingChatContactStateDurationMs,
    contactId: incomingChatContactContactId,
  } = incomingContact;

  const incomingChatContactRef = useRef<connect.Contact | null | undefined>();
  useEffect(() => {
    incomingChatContactRef.current = incomingChatContact;
  }, [incomingChatContact]);

  const activeChatContacts = useSelector(
    ccpSelectors.getChatChannelActiveContacts
  );
  const activeChatContactsRef = useRef<TChatContact[]>([]);
  useEffect(() => {
    activeChatContactsRef.current = activeChatContacts;
  }, [activeChatContacts]);
  const activeChatTabIndex = useSelector(ccpSelectors.getActiveChatTabIndex);
  /**
   * If there already active (open) chats, first a popover (modal like)
   * notification is shown that a chat is incoming.
   *
   * If this notification is closed, the incoming chat is put into
   * a tab in Incoming state.
   * That's why this state is needed: whether the notification for an
   * incoming chat has been closed once.
   *
   * We reinitialize it on every change of the incoming chat.
   */
  const [
    wasIncomingChatNotificationDismissed,
    setWasIncomingChatNotificationDismissed,
  ] = useState(false);
  useEffect(() => {
    if (incomingChatContactStatus === 'Incoming') {
      setWasIncomingChatNotificationDismissed(false);
    }
  }, [incomingChatContactContactId]);

  const shouldShowIncomingChatNotification =
    activeChatContacts.length > 0 &&
    incomingChatContact &&
    !wasIncomingChatNotificationDismissed;

  const shouldShowIncomingChatTab =
    incomingChatContact &&
    (wasIncomingChatNotificationDismissed || activeChatContacts.length === 0);

  const dispatch = useDispatch();

  // Effect to calculate the state duration
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        ccpActions.setChatChannelActiveContacts(
          activeChatContactsRef.current.map((activeChatContact) => ({
            ...activeChatContact,
            stateDurationMs: activeChatContact.curr?.getStateDuration(),
          }))
        )
      );
      if (incomingChatContactRef.current) {
        dispatch(
          ccpActions.setPartialChatChannelIncomingContact({
            stateDurationMs: incomingChatContactRef.current?.getStateDuration(),
          })
        );
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const sendMessageToContact = async (
    contact: connect.Contact | undefined,
    message: string
  ) => {
    if (!contact) return;

    const agentConnection = contact
      .getConnections()
      .find((cnn) => cnn.getType() === connect.ConnectionType.AGENT);

    const agentChatSession = (await (
      agentConnection as connect.ChatConnection
    ).getMediaController()) as connect.ChatSession;

    if (env?.enabledFeatures?.CHAT_TWO_WAY_TRANSLATION) {
      data.current = {
        contactId: contact.contactId,
        targetLanguage: customerLanguage.current,
        text: message,
        type: 'REPLY',
      };

      dispatch(ccpActions.setLastOriginalMessage(message));

      try {
        const response = await polyglotApi.getTranslation(data.current);

        dispatch(ccpActions.setIsTranslationError(false));
        lastTranslatedMessage.current = response.data.translatedText;
      } catch (error: any) {
        console.log("Error while translating message: ", error);
        //there is an issue with the polyglot call, log the error and send the original message, no translation
        lastTranslatedMessage.current = '';
      } finally {
        await agentChatSession.sendMessage({
          contentType: 'text/plain',
          message: lastTranslatedMessage.current !== '' ? lastTranslatedMessage.current : message,
        });
      }
    } else {
      await agentChatSession.sendMessage({
        contentType: 'text/plain',
        message: message,
      });
    }
  };

  const downloadAttachment = async (
    contact: connect.Contact | undefined,
    attachment: TChatAttachment
  ) => {
    if (!contact) return;

    const agentConnection = contact
      .getConnections()
      .find((cnn) => cnn.getType() === connect.ConnectionType.AGENT);

    const agentChatSession = (await (
      agentConnection as connect.ChatConnection
    ).getMediaController()) as connect.ChatSession;

    const response = await agentChatSession.downloadAttachment({
      attachmentId: attachment.attachmentId,
    });

    downloadFile(response, attachment.attachmentName, attachment.contentType);
  };

  const sendAttachment = async (
    contact: connect.Contact | undefined,
    file: File
  ) => {
    if (!contact) return;

    const agentConnection = contact
      .getConnections()
      .find((cnn) => cnn.getType() === connect.ConnectionType.AGENT);

    const agentChatSession = (await (
      agentConnection as connect.ChatConnection
    ).getMediaController()) as connect.ChatSession;

    const internalId = String(Math.random());
    try {
      dispatch(
        ccpActions.addChatEntryToChatChannelActiveContact({
          contactId: contact.contactId,
          chatEntry: {
            type: 'TEMPORARY_MESSAGE',
            internalId,
            timestamp: '',
            timestampStringOverwrite: t('ccp.chat.sending'),
            participantRole: 'AGENT',
            message: file.name,
            displayName: '',
          },
        })
      );
      dispatch(
        ccpActions.addAttachmentNameToWaitForWithTemporaryMessage({
          attachmentName: file.name,
          temporaryMessageInternalId: internalId,
        })
      );
      await agentChatSession.sendAttachment({
        attachment: file,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {shouldShowIncomingChatNotification && (
        <IncomingChatModal
          onAction={() => {
            setWasIncomingChatNotificationDismissed(true);
          }}
        />
      )}
      {activeChatContacts.length === 0 &&
      !incomingChatContact &&
      !isTaskCreatorShown ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography>{t('ccp.chat.have0openChats')}</Typography>
        </Box>
      ) : (
        <Box sx={{ width: '100%' }}>
          {(activeChatContacts.length > 0 || shouldShowIncomingChatTab) && (
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <StyledTabs>
                {activeChatContacts.map((activeChatContact, index) => (
                  <ChatTab
                    type={activeChatContact.status}
                    chatName={activeChatContact.name!}
                    timeString={getMinutesAndSecondsStringFromMilliseconds(
                      activeChatContact.stateDurationMs ?? 0
                    )}
                    key={activeChatContact.contactId}
                    selected={activeChatTabIndex === index}
                    onClick={() => {
                      // If quick connects are shown, block the selection
                      if (!areQuickConnectsShown) {
                        dispatch(ccpActions.setActiveChatTabIndex(index));
                      }
                    }}
                    onClose={() => {
                      activeChatContact.curr?.clear({
                        success: () => {
                          //
                        },
                        failure: () => {
                          //
                        },
                      });
                    }}
                  />
                ))}
                {shouldShowIncomingChatTab && (
                  <ChatTab
                    type={incomingChatContactStatus}
                    chatName={incomingChatContactName!}
                    key={incomingChatContact!.contactId}
                    selected={
                      activeChatContacts.length === 0
                        ? true
                        : activeChatTabIndex === activeChatContacts.length
                    }
                    onClick={() => {
                      // If quick connects are shown, block the selection
                      if (!areQuickConnectsShown) {
                        if (activeChatContacts.length === 0) {
                          dispatch(ccpActions.setActiveChatTabIndex(0));
                        } else {
                          dispatch(
                            ccpActions.setActiveChatTabIndex(
                              activeChatContacts.length
                            )
                          );
                        }
                      }
                    }}
                    onClose={() => {
                      incomingChatContact!.clear({
                        success: () => {
                          //
                        },
                        failure: () => {
                          //
                        },
                      });
                    }}
                    timeString={getMinutesAndSecondsStringFromMilliseconds(
                      incomingChatContactStateDurationMs ?? 0
                    )}
                  />
                )}
              </StyledTabs>
            </Box>
          )}
          {activeChatContacts.map((activeChatContact, index) => (
            <TabPanel
              key={activeChatContact.contactId}
              isShown={
                !isTaskCreatorShown &&
                !areQuickConnectsShown &&
                index === activeChatTabIndex
              }
              chat={activeChatContact}
              onCreateTaskIntention={() => {
                setIsTaskCreatorShown(true);
              }}
              onEndChatIntention={() => {
                activeChatContact.curr?.getAgentConnection().destroy({
                  success: () => {
                    //
                  },
                  failure: () => {
                    //
                  },
                });
              }}
              onQuickConnectIntention={() => {
                setAreQuickConnectsShown(true);
              }}
              onCloseContactIntention={() => {
                activeChatContact.curr?.clear({
                  success: () => {
                    //
                  },
                  failure: () => {
                    //
                  },
                });
              }}
              onSendMessage={(message) =>
                sendMessageToContact(activeChatContact.curr, message)
              }
              onDownloadAttachment={(attachment) => {
                downloadAttachment(activeChatContact.curr, attachment);
              }}
              onSendAttachment={(file) => {
                sendAttachment(activeChatContact.curr, file);
              }}
            />
          ))}
          {incomingChatContact && (
            <TabPanel
              key={incomingChatContact.contactId}
              isShown={
                !isTaskCreatorShown &&
                !areQuickConnectsShown &&
                (activeChatContacts.length === 0
                  ? true
                  : activeChatTabIndex === activeChatContacts.length)
              }
              chat={incomingContact}
              onCreateTaskIntention={() => {
                setIsTaskCreatorShown(true);
              }}
              onEndChatIntention={() => {
                incomingChatContact.getAgentConnection().destroy({
                  success: () => {
                    //
                  },
                  failure: () => {
                    //
                  },
                });
              }}
              onQuickConnectIntention={() => {
                setAreQuickConnectsShown(true);
              }}
              onCloseContactIntention={() => {
                incomingChatContact.clear({
                  success: () => {
                    //
                  },
                  failure: () => {
                    //
                  },
                });
              }}
              onSendMessage={() => {
                // This will not get called anyways for the incomingChatContact tab panel
                return Promise.resolve();
              }}
              onDownloadAttachment={() => {
                //
              }}
              onSendAttachment={() => {
                //
              }}
            />
          )}
        </Box>
      )}
      {isTaskCreatorShown && (
        <TaskCreator
          onCancel={() => {
            setIsTaskCreatorShown(false);
          }}
          onCreatedSuccessfully={() => {
            setIsTaskCreatorShown(false);
          }}
        />
      )}
      {areQuickConnectsShown && (
        <ChatQuickConnects
          onCancel={() => {
            setAreQuickConnectsShown(false);
          }}
          onTransferSuccessful={() => {
            setAreQuickConnectsShown(false);
            dispatch(
              ccpActions.updateOrCreateChatChannelActiveContact({
                ...activeChatContacts[activeChatTabIndex],
                wasContactTransferred: true,
              })
            );
          }}
          chatContactToTransfer={activeChatContacts[activeChatTabIndex].curr}
        />
      )}
    </Box>
  );
};

export default ChatUi;
