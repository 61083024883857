import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TLanguageReducer } from "../../app/types/language-types";

export const languageReducerInitialState: TLanguageReducer = {
  language: "us",
};

const languageReducer = createSlice({
  name: 'languageReducer',
  initialState: languageReducerInitialState,
  reducers: {
    setLanguage: (state, { payload }: PayloadAction<string>) => {
      state.language = payload;
    }
  }
});

export const languageActions = languageReducer.actions;

export default languageReducer.reducer;
