import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  listSortLabel: {
    '&.MuiTableSortLabel-active': {
      color: theme.palette.primary.main,
    },

    '&.MuiTableSortLabel-root': {
      alignContent: 'center',
      fontWeight: 600,
      padding: '1rem 0 1rem 0',
      borderBottom: '0',

      '&:hover': {
        color: theme.palette.secondary.main,

        '& > svg': {
          fill: theme.palette.secondary.main,
        },
      },

      '& > svg': {
        fill: theme.palette.text.primary,
      },
    },
  },
  listHeaderCell: {
    '&.MuiTableCell-root': {
      borderBottom: 0,
    },
  },
}));
