import touchtone from 'touchtone';
import baudio from 'webaudio';

/** Simple wrapper around touchtone and webaudio generator */

class TouchTone {
  touchTone: any;
  baudio: any;

  constructor() {
    this.touchTone = touchtone({
      duration: 0.25,

      volume: 0.25,
    });

    this.baudio = baudio(this.touchTone.play());
  }

  press(value: any) {
    this.touchTone.press(value);

    this.baudio.play();
  }

  dial(values: any) {
    this.touchTone.dial(values);

    this.baudio.play();
  }
}

export default new TouchTone();
