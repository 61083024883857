import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Typography,
  styled,
  Tooltip,
  IconButton,
} from '@mui/material';
import IncomingTaskNotificationIcon from '@mui/icons-material/NewReleases';
import TimeIcon from '@mui/icons-material/AccessTime';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import QuickConnectsIcon from '@mui/icons-material/PeopleAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import AssignmentIcon from '@mui/icons-material/Assignment';

import TaskCreator from '../task-creator/task-creator';
import { ccpSelectors } from '../../../../../store/selectors';
import { TTaskContact, TTaskContactStatus } from '../../../../types/ccp';
import { getMinutesAndSecondsStringFromMilliseconds } from '../../../../../utils/dateUtils';
import { ccpActions } from '../../../../../store/reducers/ccpReducer';
import IncomingTaskModal from '../incoming-task-modal';
import { IncomingTaskTabPanel } from '../incoming-task-tab';
import TaskQuickConnects from '../task-quick-connects';

import { themeConstants } from '../../../../../assets/styles/defaultTheme/constants';

interface ITabPanelProps {
  isShown: boolean;
  task: TTaskContact;
  onCreateTaskIntention: () => void;
  onQuickConnectIntention: () => void;
  onEndTaskIntention: () => void;
  onCloseContactIntention: () => void;
}

const TAB_PANEL_CONTENT_FIELD_LABEL_STYLES = {
  fontWeight: 500,
  fontSize: 14,
};
const TAB_PANEL_CONTENT_FIELD_CONTENT_STYLES = {
  fontSize: 14,
};

function TabPanel({
  isShown,
  task,
  onCreateTaskIntention,
  onQuickConnectIntention,
  onEndTaskIntention,
  onCloseContactIntention,
}: ITabPanelProps) {
  const { t } = useTranslation();

  const [isAllTaskInformationShown, setIsAllTaskInformationShown] =
    useState(false);

  const agent = new connect.Agent();

  const smallCreateTaskButton = (
    <IconButton
      title={t('ccp.task.createTask')}
      onClick={onCreateTaskIntention}
    >
      <AssignmentIcon sx={{ color: themeConstants.COLOR_SECONDARY }} />
    </IconButton>
  );

  const fullSizeCreateTaskButton = (
    <Button
      title={t('ccp.task.createTask')}
      onClick={onCreateTaskIntention}
      sx={{
        display: 'flex',
        gap: 1,
        textTransform: 'none',
      }}
    >
      <AssignmentIcon sx={{ color: themeConstants.COLOR_SECONDARY }} />
      {t('ccp.task.createTask')}
    </Button>
  );

  const quickConnectsButton = (
    <IconButton
      title={t('ccp.quickConnects')}
      onClick={onQuickConnectIntention}
    >
      <QuickConnectsIcon sx={{ color: themeConstants.COLOR_SECONDARY }} />
    </IconButton>
  );

  const endTaskButton = (
    <Button
      sx={{
        display: 'flex',
        gap: 1,
        textTransform: 'none',
      }}
      onClick={onEndTaskIntention}
    >
      <CancelIcon sx={{ color: themeConstants.COLOR_RED }} />
      {t('ccp.task.endTask')}
    </Button>
  );

  const closeContactButton = (
    <Button
      sx={{
        display: 'flex',
        gap: 1,
        textTransform: 'none',
      }}
      onClick={onCloseContactIntention}
      variant="contained"
    >
      {t('ccp.task.closeContact')}
    </Button>
  );

  return (
    <Box>
      {isShown && (
        <Box
          sx={{
            paddingTop: 2,
            paddingLeft: 1,
            paddingRight: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          {task.status !== 'Incoming' &&
            task.status !== 'Missed' &&
            task.status !== 'Rejected' && (
              <>
                {task.description && (
                  <Box>
                    <Typography sx={TAB_PANEL_CONTENT_FIELD_LABEL_STYLES}>
                      {t('ccp.task.descriptionSimple')}
                    </Typography>
                    <Typography
                      sx={{
                        ...TAB_PANEL_CONTENT_FIELD_CONTENT_STYLES,
                        fontWeight: 500,
                        marginTop: 2,
                        textAlign: 'center',
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      {task.description}
                    </Typography>
                  </Box>
                )}
                {task.references && Object.keys(task.references).length > 0 && (
                  <Box>
                    <Typography sx={TAB_PANEL_CONTENT_FIELD_LABEL_STYLES}>
                      {t('ccp.task.references')}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        paddingLeft: 2,
                        paddingTop: 1,
                      }}
                    >
                      {Object.entries(task.references).map(
                        ([key, value]: [string, any]) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1 / 2,
                            }}
                          >
                            <Typography
                              sx={TAB_PANEL_CONTENT_FIELD_CONTENT_STYLES}
                            >
                              {key}
                            </Typography>
                            <Typography
                              sx={{
                                ...TAB_PANEL_CONTENT_FIELD_CONTENT_STYLES,
                                color: themeConstants.COLOR_SECONDARY,
                                overflowWrap: 'break-word',
                                whiteSpace: 'break-spaces',
                              }}
                              component="a"
                              href={value.value}
                              target="_blank"
                            >
                              {value.value}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                )}
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsAllTaskInformationShown(!isAllTaskInformationShown);
                    }}
                  >
                    {isAllTaskInformationShown ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: '500',
                      }}
                    >
                      {isAllTaskInformationShown
                        ? t('ccp.task.hideAllTaskInformation')
                        : t('ccp.task.showAllTaskInformation')}
                    </Typography>
                  </Box>
                  {isAllTaskInformationShown && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1 / 2,
                        mt: 1,
                      }}
                    >
                      <Typography
                        sx={{ ...TAB_PANEL_CONTENT_FIELD_LABEL_STYLES, mt: 1 }}
                      >
                        {t('ccp.task.agentUsername')}
                      </Typography>
                      <Typography sx={TAB_PANEL_CONTENT_FIELD_CONTENT_STYLES}>
                        {agent.getConfiguration().username}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            )}
          {task.wasContactTransferred && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                marginTop: 2,
                textAlign: 'center',
                wordBreak: 'break-all',
                whiteSpace: 'normal',
              }}
            >
              {t('ccp.contactWasTransferred')}
            </Typography>
          )}
          {(task.status === 'Rejected' || task.status === 'Missed') && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                marginTop: 2,
                textAlign: 'center',
                wordBreak: 'break-all',
                whiteSpace: 'normal',
              }}
            >
              {t('ccp.task.cannotAcceptMoreUntilClosed')}
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 3,
              marginTop: 4,
            }}
          >
            {task.status === 'Connected' && (
              <>
                {smallCreateTaskButton}
                {quickConnectsButton}
                {endTaskButton}
              </>
            )}
            {(task.status === 'AfterContactWork' ||
              task.status === 'Rejected') && (
              <>
                {fullSizeCreateTaskButton}
                {closeContactButton}
              </>
            )}
            {task.status === 'Missed' && closeContactButton}
            {task.status === 'Incoming' && <IncomingTaskTabPanel />}
          </Box>
        </Box>
      )}
    </Box>
  );
}

interface IStyledTabsProps {
  children: React.ReactNode;
}

const StyledTabs = styled((props: IStyledTabsProps) => <Box {...props} />)({
  display: 'flex',
  gap: '4px',
  overflowX: 'auto',
});

type TTaskTabType = TTaskContactStatus;

type StyledTabProps = {
  type: TTaskTabType;
  selected: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const StyledTab = styled(({ type, ...rest }: StyledTabProps) => (
  <Box {...rest} />
))(({ theme, type, selected }) => ({
  minWidth: '130px',

  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  cursor: 'pointer',
  backgroundColor: 'white',
  color: 'black',

  ...(type === 'Incoming' && {
    ...(selected && {
      backgroundColor: 'green',
      color: 'white',
    }),
  }),

  ...(type === 'Connected' && {
    ...(selected && {
      backgroundColor: themeConstants.COLOR_SECONDARY,
      color: 'white',
    }),
  }),

  ...(type === 'AfterContactWork' && {
    ...(selected && {
      backgroundColor: 'grey',
      color: 'white',
    }),
  }),

  ...(type === 'Missed' && {
    backgroundColor: themeConstants.COLOR_RED,
    color: 'white',
  }),

  ...(type === 'Rejected' && {
    backgroundColor: themeConstants.COLOR_RED,
    color: 'white',
  }),

  '& .text-to-show-in-full-width-tab': {
    display: 'none',
  },

  '&:first-child:last-child': {
    width: '100%',

    '& .text-to-show-in-full-width-tab': {
      display: 'block',
    },
  },
}));

type TaskTab =
  | {
      type: 'Incoming';
      taskName: string;
      selected: boolean;
    }
  | {
      type: 'Connected' | 'AfterContactWork';
      taskName: string;
      selected: boolean;
      timeString: string;
    }
  | {
      type: 'Missed';
      taskName: string;
      selected: boolean;
      timeString: string;
      onClose: () => void;
    }
  | {
      type: 'Accepted';
      taskName: string;
      selected: boolean;
      timeString: string;
    }
  | {
      type: 'Initial';
      taskName: string;
      selected: boolean;
      timeString: string;
    }
  | {
      type: 'Rejected';
      taskName: string;
      selected: boolean;
      timeString: string;
    };

const TaskTab = ({
  type,
  taskName,
  ...restProps
}: TaskTab & StyledTabProps) => {
  const { t } = useTranslation();
  const shouldShowElapsedTime =
    type === 'Connected' ||
    type === 'Rejected' ||
    type === 'AfterContactWork' ||
    type === 'Missed';

  return (
    <StyledTab type={type} {...restProps}>
      <Tooltip
        placement="bottom"
        arrow
        title={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: 130,
            }}
          >
            <Typography
              sx={{
                gridArea: 'task-name',
                fontSize: 'inherit',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontWeight: '500',
              }}
            >
              {taskName}
            </Typography>

            {shouldShowElapsedTime && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1 / 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 'inherit',
                    fontWeight: '500',
                  }}
                >
                  {t('ccp.task.elapsedTime')}:&nbsp;
                </Typography>
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {(restProps as any).timeString}
                </Typography>
              </Box>
            )}

            {type === 'Incoming' && (
              <Typography
                sx={{
                  fontSize: 'inherit',
                }}
              >
                {t('ccp.incomingTask')}
              </Typography>
            )}
            {type === 'AfterContactWork' && (
              <Typography
                sx={{
                  fontSize: 'inherit',
                }}
              >
                {t('ccp.task.afterContactWork')}
              </Typography>
            )}
            {type === 'Connected' && (
              <Typography
                sx={{
                  fontSize: 'inherit',
                }}
              >
                {t('ccp.task.connectedTask')}
              </Typography>
            )}
            {type === 'Rejected' && (
              <Typography
                sx={{
                  fontSize: 'inherit',
                }}
              >
                {t('ccp.task.rejectedTask')}
              </Typography>
            )}
            {type === 'Missed' && (
              <Typography
                sx={{
                  fontSize: 'inherit',
                }}
              >
                {t('ccp.task.missedTask')}
              </Typography>
            )}
          </Box>
        }
      >
        <Box
          sx={{
            padding: 1,
            paddingBottom: 1 / 2,
            display: 'grid',
            gridTemplateColumns: 'minmax(0,1fr) auto',
            gridTemplateRows: 'auto auto',
            gridTemplateAreas: `
            'task-name top-right-side'
            'extra-info extra-info'
            `,
            alignItems: 'center',
            columnGap: 1,
            color: 'inherit',
            fontSize: 12,
          }}
        >
          <Typography
            sx={{
              gridArea: 'task-name',
              fontSize: 'inherit',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontWeight: '500',
            }}
          >
            {taskName}
          </Typography>
          <Box
            sx={{
              gridArea: 'top-right-side',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              height: 18,
            }}
          >
            {type === 'Incoming' && (
              <IncomingTaskNotificationIcon
                sx={{
                  height: 18,
                }}
              />
            )}
            {type === 'AfterContactWork' && (
              <HourglassEmptyIcon
                sx={{
                  height: 18,
                }}
              />
            )}
            {(type === 'Missed' || type === 'Rejected') && (
              <CloseIcon
                sx={{
                  height: 18,
                }}
                onClick={(restProps as any).onClose}
              />
            )}
          </Box>
          <Box
            sx={{
              gridArea: 'extra-info',
              height: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {shouldShowElapsedTime && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1 / 2,
                  }}
                >
                  <TimeIcon
                    sx={{
                      width: 20,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 'inherit',
                    }}
                  >
                    {(restProps as any).timeString}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box className="text-to-show-in-full-width-tab">
              {type === 'Incoming' && (
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {t('ccp.incomingTask')}
                </Typography>
              )}
              {type === 'AfterContactWork' && (
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {t('ccp.task.afterContactWork')}
                </Typography>
              )}
              {type === 'Connected' && (
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {t('ccp.task.connectedTask')}
                </Typography>
              )}
              {type === 'Rejected' && (
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {t('ccp.task.rejectedTask')}
                </Typography>
              )}
              {type === 'Missed' && (
                <Typography
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {t('ccp.task.missedTask')}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Tooltip>
    </StyledTab>
  );
};

const TaskUi = () => {
  const [isTaskCreatorShown, setIsTaskCreatorShown] = useState(false);
  const [areQuickConnectsShown, setAreQuickConnectsShown] = useState(false);

  const { t } = useTranslation();

  const incomingContact = useSelector(
    ccpSelectors.getTaskChannelIncomingContact
  );

  const {
    curr: incomingTaskContact,
    name: incomingTaskContactName,
    status: incomingTaskContactStatus,
    stateDurationMs: incomingTaskContactStateDurationMs,
    contactId: incomingTaskContactContactId,
  } = incomingContact;

  const incomingTaskContactRef = useRef<connect.Contact | null | undefined>();
  useEffect(() => {
    incomingTaskContactRef.current = incomingTaskContact;
  }, [incomingTaskContact]);

  const activeTaskContacts = useSelector(
    ccpSelectors.getTaskChannelActiveContacts
  );
  const activeTaskContactsRef = useRef<TTaskContact[]>([]);
  useEffect(() => {
    activeTaskContactsRef.current = activeTaskContacts;
  }, [activeTaskContacts]);
  const [activeTaskTabIndex, setActiveTaskTabIndex] = useState(0);

  /**
   * If there already active (open) tasks, first a popover (modal like)
   * notification is shown that a task is incoming.
   *
   * If this notification is closed, the incoming task is put into
   * a tab in Incoming state.
   * That's why this state is needed: whether the notification for an
   * incoming task has been closed once.
   *
   * We reinitialize it on every change of the incoming task.
   */
  const [
    wasIncomingTaskNotificationDismissed,
    setWasIncomingTaskNotificationDismissed,
  ] = useState(false);
  useEffect(() => {
    if (incomingTaskContactStatus === 'Incoming') {
      setWasIncomingTaskNotificationDismissed(false);
    }
  }, [incomingTaskContactContactId]);

  const shouldShowIncomingTaskNotification =
    activeTaskContacts.length > 0 &&
    incomingTaskContact &&
    !wasIncomingTaskNotificationDismissed;

  const shouldShowIncomingTaskTab =
    incomingTaskContact &&
    (wasIncomingTaskNotificationDismissed || activeTaskContacts.length === 0);

  const dispatch = useDispatch();

  // Effect to calculate the state duration
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        ccpActions.setTaskChannelActiveContacts(
          activeTaskContactsRef.current.map((activeTaskContact) => ({
            ...activeTaskContact,
            stateDurationMs: activeTaskContact.curr?.getStateDuration(),
          }))
        )
      );
      if (incomingTaskContactRef.current) {
        dispatch(
          ccpActions.setPartialTaskChannelIncomingContact({
            stateDurationMs: incomingTaskContactRef.current?.getStateDuration(),
          })
        );
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {shouldShowIncomingTaskNotification && (
        <IncomingTaskModal
          onAction={() => {
            setWasIncomingTaskNotificationDismissed(true);
          }}
        />
      )}
      {activeTaskContacts.length === 0 &&
      !incomingTaskContact &&
      !isTaskCreatorShown ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography>{t('ccp.task.have0openTasks')}</Typography>
        </Box>
      ) : (
        <Box sx={{ width: '100%' }}>
          {(activeTaskContacts.length > 0 || shouldShowIncomingTaskTab) && (
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <StyledTabs>
                {activeTaskContacts.map((activeTaskContact, index) => (
                  <TaskTab
                    type={activeTaskContact.status}
                    taskName={activeTaskContact.name!}
                    timeString={getMinutesAndSecondsStringFromMilliseconds(
                      activeTaskContact.stateDurationMs ?? 0
                    )}
                    key={activeTaskContact.contactId}
                    selected={activeTaskTabIndex === index}
                    onClick={() => {
                      // If quick connects are shown, block the selection
                      if (!areQuickConnectsShown) {
                        setActiveTaskTabIndex(index);
                      }
                    }}
                    onClose={() => {
                      activeTaskContact.curr?.clear({
                        success: () => {
                          //
                        },
                        failure: () => {
                          //
                        },
                      });
                    }}
                  />
                ))}
                {shouldShowIncomingTaskTab && (
                  <TaskTab
                    type={incomingTaskContactStatus}
                    taskName={incomingTaskContactName!}
                    key={incomingTaskContact!.contactId}
                    selected={
                      activeTaskContacts.length === 0
                        ? true
                        : activeTaskTabIndex === activeTaskContacts.length
                    }
                    onClick={() => {
                      // If quick connects are shown, block the selection
                      if (!areQuickConnectsShown) {
                        if (activeTaskContacts.length === 0) {
                          setActiveTaskTabIndex(0);
                        } else {
                          setActiveTaskTabIndex(activeTaskContacts.length);
                        }
                      }
                    }}
                    onClose={() => {
                      incomingTaskContact!.clear({
                        success: () => {
                          //
                        },
                        failure: () => {
                          //
                        },
                      });
                    }}
                    timeString={getMinutesAndSecondsStringFromMilliseconds(
                      incomingTaskContactStateDurationMs ?? 0
                    )}
                  />
                )}
              </StyledTabs>
            </Box>
          )}
          {activeTaskContacts.map((activeTaskContact, index) => (
            <TabPanel
              key={activeTaskContact.contactId}
              isShown={
                !isTaskCreatorShown &&
                !areQuickConnectsShown &&
                index === activeTaskTabIndex
              }
              task={activeTaskContact}
              onCreateTaskIntention={() => {
                setIsTaskCreatorShown(true);
              }}
              onEndTaskIntention={() => {
                activeTaskContact.curr?.getAgentConnection().destroy({
                  success: () => {
                    //
                  },
                  failure: () => {
                    //
                  },
                });
              }}
              onQuickConnectIntention={() => {
                setAreQuickConnectsShown(true);
              }}
              onCloseContactIntention={() => {
                activeTaskContact.curr?.clear({
                  success: () => {
                    //
                  },
                  failure: () => {
                    //
                  },
                });
              }}
            />
          ))}
          {incomingTaskContact && (
            <TabPanel
              key={incomingTaskContact.contactId}
              isShown={
                !isTaskCreatorShown &&
                !areQuickConnectsShown &&
                (activeTaskContacts.length === 0
                  ? true
                  : activeTaskTabIndex === activeTaskContacts.length)
              }
              task={incomingContact}
              onCreateTaskIntention={() => {
                setIsTaskCreatorShown(true);
              }}
              onEndTaskIntention={() => {
                incomingTaskContact.getAgentConnection().destroy({
                  success: () => {
                    //
                  },
                  failure: () => {
                    //
                  },
                });
              }}
              onQuickConnectIntention={() => {
                setAreQuickConnectsShown(true);
              }}
              onCloseContactIntention={() => {
                incomingTaskContact.clear({
                  success: () => {
                    //
                  },
                  failure: () => {
                    //
                  },
                });
              }}
            />
          )}
        </Box>
      )}
      {isTaskCreatorShown && (
        <TaskCreator
          onCancel={() => {
            setIsTaskCreatorShown(false);
          }}
          onCreatedSuccessfully={() => {
            setIsTaskCreatorShown(false);
          }}
        />
      )}
      {areQuickConnectsShown && (
        <TaskQuickConnects
          onCancel={() => {
            setAreQuickConnectsShown(false);
          }}
          onTransferSuccessful={() => {
            setAreQuickConnectsShown(false);
            dispatch(
              ccpActions.updateOrCreateTaskChannelActiveContact({
                ...activeTaskContacts[activeTaskTabIndex],
                wasContactTransferred: true,
              })
            );
          }}
          taskContactToTransfer={activeTaskContacts[activeTaskTabIndex].curr}
        />
      )}
      {!incomingTaskContact &&
        activeTaskContacts.length === 0 &&
        !isTaskCreatorShown && (
          <Button
            variant="contained"
            onClick={() => {
              setIsTaskCreatorShown(true);
            }}
            sx={{
              marginTop: 2,
            }}
          >
            {t('ccp.task.createTask')}
          </Button>
        )}
    </Box>
  );
};

export default TaskUi;
