import React, { FC, RefObject } from 'react';
import { useDispatch } from 'react-redux';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';

import { ccpActions } from '../../../../store/reducers/ccpReducer';
import { useConfig } from '../../../hooks/useConfig';

import { TStatus } from '../../../types/ccp';

import { useHeaderStyles } from './Header-style';

interface IProps {
  open: boolean;
  anchorRef: RefObject<HTMLButtonElement>;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  handleListKeyDown: (event: React.KeyboardEvent) => void;
  handleListSelect: (status: TStatus) => void;
  id?: string;
}

const StatusMenu: FC<IProps> = ({
  open,
  anchorRef,
  handleClose,
  handleListKeyDown,
  handleListSelect,
  id,
}) => {
  const { over, listWrapper } = useHeaderStyles();

  const agent = new connect.Agent();
  const statuses = agent.getAgentStates();
  const dispatch = useDispatch();
  const { env } = useConfig();

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      transition
      disablePortal
      className={over}
      id={id}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'left top' : 'left bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
                className={listWrapper}
              >
                {statuses.map((status: any, index: number) => (
                  <MenuItem
                    onClick={() => {
                      handleListSelect(status.name as TStatus);
                      const statusIsCampaignCalls = statuses.filter(
                        (item) => item.agentStateARN === env?.arnCampaignCall
                      );
                      if (status.name === statusIsCampaignCalls[0]?.name) {
                        dispatch(ccpActions.setDisabledDialpad(true));
                        dispatch(
                          ccpActions.setOutboundCallType('outboundCampaign')
                        );
                        dispatch(
                          ccpActions.setVoiceChannelOutboundCall({ number: '' })
                        );
                      } else {
                        dispatch(ccpActions.setDisabledDialpad(false));
                        dispatch(ccpActions.setOutboundCallType(''));
                      }
                    }}
                    key={index}
                  >
                    <ListItemIcon>
                      <FiberManualRecordIcon
                        color={
                          status.name === 'Available'
                            ? 'secondary'
                            : status.name === 'Offline'
                            ? 'disabled'
                            : 'warning'
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={status.name} />
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default StatusMenu;
