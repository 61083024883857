import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useQuickConnectsStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    '&.MuiButton-root': {
      width: 'calc(100% - 2rem)',
      margin: '0 1rem',
      textTransform: 'none',

      '& > svg': {
        marginRight: '.5rem',
        width: '1.2rem',
        height: '1.2rem',
      },

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}15`,
      },
    },
  },
  quickConnectList: {
    margin: '0 1rem 1rem',
    maxHeight: '34vh',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '0.3rem',
      height: '1rem',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 1rem ${theme.palette.action.disabled}`,
      borderRadius: '0.5rem',
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '0.5rem',

      '&:hover': {
        background: theme.palette.primary.dark,
      },
    },
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.primary.main}40`,
    paddingRight: '.6rem',
    alignItems: 'center',

    '& > .MuiTypography-root': {
      fontSize: '.99rem',
    },

    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}26`,
    },

    '& > .MuiSvgIcon-root': {
      borderRadius: '.2rem',
      padding: '.2rem',
      margin: '.2rem',
      width: '1.4rem',
      height: '1.4rem',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
  },
  inputWrapper: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    margin: '0 1rem',
    display: 'flex',
    borderRadius: '.2rem .2rem 0 0',

    '& > .MuiSvgIcon-root': {
      flex: '1 0 5%',
    },

    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}26`,
    },
  },
  input: {
    flex: '1 0 calc(95% - 2rem)',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
  },
}));
