import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCallData } from '../../app/types/ccp';
import type { TAutomatedCallReducerState } from '../../app/types/automated-call-types';

export const automatedCallInitialState: TAutomatedCallReducerState = {
  agentKey: '',
  callReason: '',
  contactId: '',
  customerEndpoint: '',
  inputValue: '',
  additionalAttributes: {},
};

const automatedCallReducer = createSlice({
  name: 'automatedCallReducer',
  initialState: automatedCallInitialState,
  reducers: {
    setAutomatedCallData: (state, { payload }: PayloadAction<TCallData>) => {
      state.agentKey = payload.agentKey;
      state.callReason = payload.callReason;
      state.contactId = payload.contactId;
      state.customerEndpoint = payload.customerEndpoint;
      state.inputValue = payload.inputValue;
      state.additionalAttributes = payload.additionalAttributes;
    },
    resetAutomatedCallData: () => automatedCallInitialState,
  },
});

export const automatedCallActions = automatedCallReducer.actions;

export default automatedCallReducer.reducer;
