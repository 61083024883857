import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TSideNavReducerState } from '../../app/types/sideNavTypes';

export const sideNavInitialState: TSideNavReducerState = {
  isOpen: false,
};

const sideNavReducer = createSlice({
  name: 'sideNavReducer',
  initialState: sideNavInitialState,
  reducers: {
    toggleOpen: (state, { payload }: PayloadAction<TSideNavReducerState>) => {
      state.isOpen = payload.isOpen;
    },
  },
});

export const sideNavActions = sideNavReducer.actions;

export default sideNavReducer.reducer;
