import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCallAttributeReducerState } from '../../app/types/callAttributeTypes';
import { TKeyObject } from '../../app/types/generalTypes';

export const callAttributesReducerInitialState: TCallAttributeReducerState = {};

const callAttributesReducer = createSlice({
  name: 'callAttributesReducer',
  initialState: callAttributesReducerInitialState,
  reducers: {
    setContact: (_, { payload }: PayloadAction<TCallAttributeReducerState>) =>
      payload,
    resetContact: () => callAttributesReducerInitialState,
    setShowForm: (state, { payload }: PayloadAction<boolean>) => {
      state.showForm = payload;
    },
    setAttributes: (state, { payload }: PayloadAction<TKeyObject<string>>) => {
      state.callAttributes = payload;
    },
  },
});

export const callAttributesActions = callAttributesReducer.actions;

export default callAttributesReducer.reducer;
