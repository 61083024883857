import { FC } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { TListElement } from '../../../../../../../types/tableTypes';

interface IProps {
  cell: TListElement;
  onClick?: (
    event?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id?: string
  ) => void;
  id?: string;
}

const ListRow: FC<IProps> = ({
  cell,
  onClick = () => {
    return;
  },
  id,
}) => (
  <TableRow id={id} onClick={(event) => onClick(event, id)}>
    {Object.keys(cell).map((key) => (
      <TableCell key={`${id}${key}`}>{cell[key]}</TableCell>
    ))}
  </TableRow>
);

export default ListRow;
