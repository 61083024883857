import { AxiosPromise } from 'axios';
import {
  TContactRecord,
  TContactRecordRequest,
  TNotification,
} from '../types/contactRecordTypes';
import { TFormCompleted, TFormHistory } from '../types/formTypes';
import { TPagedSearch, UUID } from '../types/generalTypes';
import apiPaths from './apiPaths';

import { axiosInstance } from './axiosClient';

const {
  contactRecord: {
    search,
    contactRecords,
    form,
    notifications,
    voicemail,
    history,
  },
  callService: {
    call,
    notifyStart,
  },
} = apiPaths;

export const contactRecordApi: {
  searchContactRecords: (
    request: TContactRecordRequest
  ) => AxiosPromise<TPagedSearch<TContactRecord>>;
  searchCallRecords: (
    request: TContactRecordRequest
  ) => AxiosPromise<TPagedSearch<TContactRecord>>;
  submitForm: (data: TFormCompleted) => AxiosPromise;
  getCompletedForm: (contactId: UUID) => AxiosPromise<TFormHistory>;
  getNotificationCount: () => AxiosPromise<TNotification>;
  getVoicemail: (contactId: UUID) => AxiosPromise<string>;
  startContact: (contactId: UUID) => AxiosPromise;
} = {
  searchContactRecords: (request) =>
    axiosInstance.post(contactRecords + search, request),
  searchCallRecords: (request) =>
    axiosInstance.post(contactRecords + history, request),
  submitForm: (data) => axiosInstance.post(contactRecords + form, data),
  getCompletedForm: (contactId) =>
    axiosInstance.get(contactRecords + `/${contactId}` + form),
  getNotificationCount: () => axiosInstance.get(contactRecords + notifications),
  getVoicemail: (contactId) =>
    axiosInstance.get(contactRecords + voicemail + `/${contactId}`),
  startContact: (contactId) => axiosInstance.post(call + notifyStart + `/${contactId}`, contactId)
};
