import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AcceptIncomingTaskIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button } from '@mui/material';

import { ccpSelectors } from '../../../../../store/selectors';

import { useIncomingTaskTabPanelStyles } from './incoming-task-tab-styles';

const IncomingTask = () => {
  const { curr: incomingTaskContact } = useSelector(
    ccpSelectors.getTaskChannelIncomingContact
  );

  const { t } = useTranslation();
  const {
    wrapper,
    incomingButton,
    incomingButtonAccept,
    incomingButtonReject,
  } = useIncomingTaskTabPanelStyles();

  if (!incomingTaskContact) return null;

  return (
    <Box className={wrapper}>
      <Button
        className={`${incomingButton} ${incomingButtonReject}`}
        onClick={() => incomingTaskContact.reject()}
      >
        <CancelIcon />
        {t('ccp.reject')}
      </Button>

      <Button
        className={`${incomingButton} ${incomingButtonAccept}`}
        onClick={() => incomingTaskContact.accept()}
      >
        <AcceptIncomingTaskIcon />
        {t('ccp.accept')}
      </Button>
    </Box>
  );
};

export default IncomingTask;
