import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  TextField,
  Button,
  IconButton,
  Autocomplete,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

export interface ITaskQuickConnects {
  taskContactToTransfer?: connect.Contact;
  onCancel: () => void;
  onTransferSuccessful: () => void;
}

const TaskQuickConnects = ({
  taskContactToTransfer,
  onCancel,
  onTransferSuccessful: onTransferSuccessfully,
}: ITaskQuickConnects) => {
  const { t } = useTranslation();

  const [quickConnects, setQuickConnects] = useState<connect.Endpoint[]>([]);
  const [selectedQuickConnect, setSelectedQuickConnect] =
    useState<connect.Endpoint | null>();

  useEffect(() => {
    // Load all the quick connects

    const agent = new connect.Agent();

    const queuesARNs = agent.getAllQueueARNs();

    agent.getEndpoints(queuesARNs, {
      success: (data) => {
        setQuickConnects(
          data.endpoints.filter(
            (endpoint) => endpoint.type !== connect.EndpointType.PHONE_NUMBER
          )
        );
      },
    });
  }, []);

  const transferContact = () => {
    if (selectedQuickConnect) {
      taskContactToTransfer?.addConnection(selectedQuickConnect, {
        success: onTransferSuccessfully,
      });
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <Typography sx={{ fontWeight: 500 }}>
          {t('ccp.quickConnects')}
        </Typography>
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </Box>

      <Autocomplete
        options={quickConnects}
        getOptionLabel={(quickConnect) => {
          return quickConnect.name;
        }}
        onChange={(_, newValue) => {
          setSelectedQuickConnect(newValue);
        }}
        value={selectedQuickConnect}
        renderInput={(params) => <TextField {...params} />}
      />

      <Box
        sx={{
          paddingTop: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          disabled={!selectedQuickConnect}
          data-test-id="task_quick_connects_transfer_button"
          onClick={transferContact}
        >
          {t('ccp.task.transfer')}
        </Button>
        <Button
          onClick={onCancel}
          data-test-id="task_quick_connects_cancel_button"
        >
          {t('button.cancel')}
        </Button>
      </Box>
    </Box>
  );
};
export default TaskQuickConnects;
