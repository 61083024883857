import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';

import useSearchChatAttachments from '../../../../../../hooks/chat-attachments/use-search-chat-attachments';

import ListHeader from './list/ListHeader';
import ListBodyWrapper from './list/ListBodyWrapper';
import ListRow from './list/ListRow';

import { TListElement } from '../../../../../../types/tableTypes';
import { chatAttachmentFiltersSelectors } from '../../../../../../../store/selectors';
import { sortOnClickV2 } from '../../../../../../../utils/sortUtils';
import { chatAttachmentFilterActions } from './../../../../../../../store/reducers/chat-attachment-filter-reducer';
import { CHAT_ATTACHMENT_LIST_HEADER } from './constants';

import { useStyles } from './chat-attachment-listing-styles';

interface IChatAttachmentListing {
  handleClick: (argument: { fileName: string; id: string }) => void;
}

const ChatAttachmentListing = ({ handleClick }: IChatAttachmentListing) => {
  const [list, setList] = useState<TListElement[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const { chatAttachments, totalNumberOfPages, isLoading, refetch } =
    useSearchChatAttachments();
  const { page, sortingOptions } = useSelector(
    chatAttachmentFiltersSelectors.getState
  );

  useEffect(() => {
    const copy: TListElement[] = chatAttachments.map((el) => ({
      id: el.id,
      name: el.name,
      title: el.title,
      description: el.description,
      size: el.size / 1000 + ' kB',
    }));
    setList(copy);
  }, [chatAttachments]);

  const handleSort = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    property: string
  ) => {
    dispatch(
      chatAttachmentFilterActions.setSorting(
        sortOnClickV2(sortingOptions, property)
      )
    );
  };

  return (
    <Box className={classes.listWrapper}>
      <TableContainer>
        <Table
          classes={{
            root: classes.table,
          }}
        >
          <ListHeader
            headers={CHAT_ATTACHMENT_LIST_HEADER}
            order={sortingOptions.direction === 'ASC' ? 'asc' : 'desc'}
            orderBy={sortingOptions.property}
            handleSort={handleSort}
          />
          <TableBody>
            <ListBodyWrapper
              noResult={list?.length === 0}
              length={CHAT_ATTACHMENT_LIST_HEADER.length}
              isLoading={isLoading}
            >
              {list.map(({ id, ...el }) => (
                <ListRow
                  id={id}
                  key={id}
                  cell={el}
                  onClick={() => handleClick({ fileName: el.name, id: el.id })}
                />
              ))}
            </ListBodyWrapper>
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classes.pagination}
        count={totalNumberOfPages}
        page={page + 1}
        onChange={(_, page) =>
          dispatch(chatAttachmentFilterActions.setPage(page - 1))
        }
        shape="rounded"
        boundaryCount={2}
        siblingCount={1}
        color="primary"
      />
    </Box>
  );
};

export default ChatAttachmentListing;
