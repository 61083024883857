import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TShowCallAttributesModal,
  TModal,
  TModalReducer,
  TShowChatTranscriptModal,
} from '../../app/types/modalType';

export const modalReducerInitialState: TModalReducer = {
  callHistory: {
    showCallAttributes: {
      open: false,
      data: { forceRefresh: false },
    },
    showCallForm: {
      open: false,
    },
  },
  chatTranscript: {
    showChatTranscript: {
      open: false,
      data: {
        transcript: [],
      },
    },
  },
};

const modalReducer = createSlice({
  name: 'modalReducer',
  initialState: modalReducerInitialState,
  reducers: {
    setShowCallAttributes: (
      state,
      { payload }: PayloadAction<TModal<TShowCallAttributesModal>>
    ) => {
      state.callHistory.showCallAttributes = payload;
    },
    setShowCallForm: (state, { payload }: PayloadAction<TModal>) => {
      state.callHistory.showCallForm = payload;
    },
    setShowChatTranscriptForm: (
      state,
      { payload }: PayloadAction<TModal<TShowChatTranscriptModal>>
    ) => {
      state.chatTranscript.showChatTranscript = payload;
    },
    resetModal: () => modalReducerInitialState,
  },
});

export const modalReducerActions = modalReducer.actions;

export default modalReducer.reducer;
