import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MenuItem, Tooltip } from '@mui/material';
import { Box } from '@mui/system';

import { authSelectors, ccpSelectors } from '../../../store/selectors';

import { useAuth } from '../../hooks/useAuth';

const HeaderDropdownMenu: FC = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const isOutboundCampaign = useSelector(ccpSelectors.getOutboundCallType);

  const isLoggedInConnect = useSelector(authSelectors.getIsLoggedOnConnect);

  return (
    <>
      <MenuItem>Profile</MenuItem>
      <MenuItem>Settings</MenuItem>
      {isLoggedInConnect === false ? (
        <Tooltip
          disableFocusListener
          disableTouchListener
          placement="bottom"
          title={`${t('logout.buttonDisabled')}`}
        >
          <Box
            sx={{
              display: 'inline-block',
              paddingLeft: '17px',
            }}
          >
            <MenuItem
              sx={{
                pointerEvents: 'none',
              }}
              disabled={isLoggedInConnect === false}
              onClick={() => {
                if (isOutboundCampaign === 'outboundCampaign') {
                  window.dispatchEvent(new Event('logout'));
                } else {
                  logout();
                }
              }}
            >
              {t('logout.button')}
            </MenuItem>
          </Box>
        </Tooltip>
      ) : (
        <MenuItem
          onClick={() => {
            if (isOutboundCampaign === 'outboundCampaign') {
              window.dispatchEvent(new Event('logout'));
            } else {
              logout();
            }
          }}
        >
          {t('logout.button')}
        </MenuItem>
      )}
    </>
  );
};

export default HeaderDropdownMenu;
