import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TNotificationReducerState } from '../../app/types/notificationTypes';

export const notificationReducerInitialState: TNotificationReducerState = {
  isOpen: false,
  message: '',
  type: 'success',
};

const notificationReducer = createSlice({
  name: 'notificationReducer',
  initialState: notificationReducerInitialState,
  reducers: {
    openNotification: (
      state,
      { payload }: PayloadAction<TNotificationReducerState>
    ) => payload,
    closeNotification: () => notificationReducerInitialState,
  },
});

export const notificationActions = notificationReducer.actions;

export default notificationReducer.reducer;
