import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import { languageActions } from '../../../store/reducers/language-reducer';
import { languageSelectors } from '../../../store/selectors';
import { resources } from '../../../i18n';

import { usersApi } from '../../api/usersApi';

const LanguageSelect = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedLanguageFromStore = useSelector(languageSelectors.getLanguage);

  const handleChange = (event: SelectChangeEvent) => {
    i18next.changeLanguage(event.target.value);
    dispatch(languageActions.setLanguage(event.target.value));
    usersApi.setLanguage(event.target.value);
  };

  return (
    <FormControl variant="standard" sx={{ m: 1 }}>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={selectedLanguageFromStore}
        onChange={handleChange}
        label="Language"
        disableUnderline
        sx={{ paddingTop: '8px' }}
      >
        {Object.keys(resources)
          .sort()
          .map((elem) => (
            <MenuItem value={elem} key={elem}>
              <Box
                sx={{ paddingRight: '10px', width: '25px', height: '25px' }}
                component="img"
                alt={elem}
                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${elem.toUpperCase()}.svg`}
              />
              <Typography
                sx={{
                  display: 'inline-block',
                  lineHeight: '25px',
                  verticalAlign: 'top',
                  fontWeight: 500,
                }}
                color="primary"
              >
                {t(`language.${elem}`)}
              </Typography>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelect;
