import { AxiosPromise } from 'axios';
import { axiosInstance } from './axiosClient';

import apiPaths from './apiPaths';
import { TChatEntry } from '../types/ccp';

export type IGetTranslationBody = {
  ticketId?: string,
  contactId: string,
  targetLanguage: string,
  text: string,
  type: "ORIGINAL" | "REPLY"
}

export type IGetTranslationResponse = {
  id: string,
  ticketId: string,
  sourceLanguage: string,
  targetLanguage: string,
  text: string,
  translatedText: string,
  date: string,
  type: string
  format: string,
  status: string,
  rejectionCategory: string,
  rejectionReason: string,
  copied: boolean,
}

export interface IPolyglotAPI {
  getTranslation: (
    data: IGetTranslationBody
  ) => AxiosPromise<string>;
}

export const polyglotApi: {
  getTranslation: (data: IGetTranslationBody) => AxiosPromise<IGetTranslationResponse>;
  sendChatToBackend: (contactId: string, chatEntries: TChatEntry[]) => AxiosPromise;
} = { 
  getTranslation: (data) => axiosInstance.post(apiPaths.polyglot.translation, data),
  sendChatToBackend: (contactId, chatEntries) => axiosInstance.post(apiPaths.polyglot.chat, chatEntries, {params: {contactId: contactId}})
}
