import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TCcpReducerState,
  TChannel,
  TVoiceContact,
  TOutboundCall,
  TRecording,
  TStatus,
  TTaskContact,
  TChatContact,
  TChatEntry,
  TChatHistoryItemByEmailWithTranscript,
} from '../../app/types/ccp';

const IncomingContactEmptyState = {
  curr: undefined,
  status: 'Initial' as const,
  contactId: null,
};

const IncomingChatContactEmptyState = {
  curr: undefined,
  status: 'Initial' as const,
  contactId: null,
  chatEntries: [],
};

export const ccpReducerInitialState: TCcpReducerState = {
  activeUiChannel: 'VOICE',
  voice: {
    contact: {
      curr: undefined,
      status: 'Initial',
    },
    phoneNumber: '',
    isOnHold: false,
    isMuted: false,
    isOutboundCall: false,
    wasMissed: false,
    endedByCustomer: false,
    callHoldTime: 0,
    contactId: '',
    outboundCallType: '',
    isDialpadDisabled: false,
    isNextContactDisabled: false,
    isOutboundCampaignAvailable: true,
    outboundCall: {
      number: '',
      country: 'us',
    },
    recording: {
      isRecording: false,
      recordingHasStarted: false,
    },
  },
  task: {
    incomingContact: {
      curr: undefined,
      status: 'Initial',
      contactId: '',
    },
    activeContacts: [],
  },
  chat: {
    incomingContact: {
      curr: undefined,
      status: 'Initial',
      contactId: '',
      chatEntries: [],
    },
    activeContacts: [],
    attachmentNamesToWaitForWithTemporaryMessage: [],
    activeChatTabIndex: 0,
    isRejectedChat: false,
    customerLanguage: '',
    lastOriginalMessage: '',
    isTranslationError: false,
  },
  visibility: {
    isDialpadOpen: false,
    isHeaderOpen: false,
    isQuickConnectsOpen: false,
  },
  channelsActivationMap: {
    VOICE: false,
    CHAT: false,
    TASK: false,
  },
};

const ccpReducer = createSlice({
  name: 'ccpReducer',
  initialState: ccpReducerInitialState,
  reducers: {
    setStatus: (state, { payload }: PayloadAction<TStatus>) => {
      state.status = payload;
    },
    setVoiceChannelPhoneNumber: (state, { payload }: PayloadAction<string>) => {
      state.voice.phoneNumber = payload;
    },
    setVoiceChannelContact: (
      state,
      { payload }: PayloadAction<Partial<TVoiceContact>>
    ) => {
      state.voice.contact = { ...state.voice.contact, ...payload };
    },
    setVoiceChannelEndedByCustomer: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.voice.endedByCustomer = payload;
    },
    setVoiceChannelWasMissed: (state, { payload }: PayloadAction<boolean>) => {
      state.voice.wasMissed = payload;
    },
    setVoiceChannelIsOnHold: (state, { payload }: PayloadAction<boolean>) => {
      state.voice.isOnHold = payload;
    },
    setVoiceChannelIsMuted: (state, { payload }: PayloadAction<boolean>) => {
      state.voice.isMuted = payload;
    },
    setVoiceChannelIsOutboundCall: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.voice.isOutboundCall = payload;
    },
    setVoiceChannelRecording: (
      state,
      { payload }: PayloadAction<TRecording>
    ) => {
      state.voice.recording = payload;
    },
    setVoiceChannelDialpadVisibility: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.visibility.isDialpadOpen = payload;
    },
    setHeaderVisibility: (state, { payload }: PayloadAction<boolean>) => {
      state.visibility.isHeaderOpen = payload;
    },
    setQuickConnectsVisibility: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.visibility.isQuickConnectsOpen = payload;
    },
    setVoiceChannelOutboundCall: (
      state,
      { payload }: PayloadAction<Partial<TOutboundCall>>
    ) => {
      state.voice.outboundCall = {
        ...state.voice.outboundCall,
        ...payload,
        isRedial: !state.voice.outboundCall.isRedial,
      };
    },
    setVoiceChannelOutboundCallCountry: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.voice.outboundCall.country = payload;
    },
    setVoiceChannelOutboundCallDialledNumber: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.voice.outboundCall.number = payload;
    },
    setCallHoldTime : (state, {payload}: PayloadAction<number>) => {
      state.voice.callHoldTime = payload;
    },
    setContactId: (state, {payload}: PayloadAction<string>) => {
      state.voice.contactId = payload;
    },
    setOutboundCallType: (state, {payload}: PayloadAction<string>) => {
      state.voice.outboundCallType = payload;
    },
    setDisabledDialpad: (state, {payload}: PayloadAction<boolean>) => {
      state.voice.isDialpadDisabled = payload;
    },
    setDisabledNextContactOutboundCampaign: (state, {payload}: PayloadAction<boolean>) => {
      state.voice.isNextContactDisabled = payload;
    },
    setIsOutboundCampaignAvailable: (state, {payload}: PayloadAction<boolean>) => {
      state.voice.isOutboundCampaignAvailable = payload;
    },
    setQuickConnects: (
      state,
      { payload }: PayloadAction<connect.Endpoint[] | undefined>
    ) => {
      state.quickConnects = payload;
    },
    setActiveUiChannel: (state, { payload }: PayloadAction<TChannel>) => {
      state.activeUiChannel = payload;
    },
    setChannelsActivationMap: (
      state,
      { payload }: PayloadAction<Record<TChannel, boolean>>
    ) => {
      state.channelsActivationMap = payload;
    },
    setPartialTaskChannelIncomingContact: (
      state,
      { payload }: PayloadAction<Partial<TTaskContact>>
    ) => {
      state.task.incomingContact = {
        ...state.task.incomingContact,
        ...payload,
      };
    },
    resetTaskChannelIncomingContact: (state) => {
      state.task.incomingContact = {
        curr: undefined,
        status: 'Initial',
        contactId: null,
      };
    },
    resetTaskChannelIncomingContactIfSameAsPayloadContact: (
      state,
      { payload }: PayloadAction<connect.Contact>
    ) => {
      if (state.task.incomingContact.curr?.contactId === payload.contactId) {
        state.task.incomingContact = {
          ...IncomingContactEmptyState,
        };
      }
    },
    setTaskChannelActiveContacts: (
      state,
      { payload }: PayloadAction<TTaskContact[]>
    ) => {
      state.task.activeContacts = payload;
    },
    addTaskChannelActiveContact: (
      state,
      { payload }: PayloadAction<TTaskContact>
    ) => {
      state.task.activeContacts = [...state.task.activeContacts, payload];
    },
    updateOrCreateTaskChannelActiveContact: (
      state,
      { payload }: PayloadAction<TTaskContact>
    ) => {
      const activeContactArrayIndex = state.task.activeContacts.findIndex(
        (contact) => contact.curr?.contactId === payload.curr?.contactId
      );
      // if not found, create
      if (activeContactArrayIndex === -1) {
        state.task.activeContacts = [...state.task.activeContacts, payload];
      } else {
        state.task.activeContacts = [
          ...state.task.activeContacts.slice(0, activeContactArrayIndex),
          { ...state.task.activeContacts[activeContactArrayIndex], ...payload },
          ...state.task.activeContacts.slice(activeContactArrayIndex + 1),
        ];
      }
    },
    removeTaskChannelActiveContactIfPresent: (
      state,
      { payload }: PayloadAction<connect.Contact>
    ) => {
      const activeContactArrayIndex = state.task.activeContacts.findIndex(
        (contact) => contact.curr?.contactId === payload.contactId
      );
      // if not found, return..
      if (activeContactArrayIndex === -1) {
        return;
      }

      state.task.activeContacts = [
        ...state.task.activeContacts.slice(0, activeContactArrayIndex),
        ...state.task.activeContacts.slice(activeContactArrayIndex + 1),
      ];
    },
    setPartialChatChannelIncomingContact: (
      state,
      { payload }: PayloadAction<Partial<TChatContact>>
    ) => {
      state.chat.incomingContact = {
        ...state.chat.incomingContact,
        ...payload,
      };
    },
    resetChatChannelIncomingContact: (state) => {
      state.chat.incomingContact = {
        ...IncomingChatContactEmptyState,
      };
    },
    resetChatChannelIncomingContactIfSameAsPayloadContact: (
      state,
      { payload }: PayloadAction<connect.Contact>
    ) => {
      if (state.chat.incomingContact.curr?.contactId === payload.contactId) {
        state.chat.incomingContact = {
          ...IncomingChatContactEmptyState,
        };
      }
    },
    setChatChannelActiveContacts: (
      state,
      { payload }: PayloadAction<TChatContact[]>
    ) => {
      state.chat.activeContacts = payload;
    },
    addChatChannelActiveContact: (
      state,
      { payload }: PayloadAction<TChatContact>
    ) => {
      state.chat.activeContacts = [...state.chat.activeContacts, payload];
    },
    addAttachmentNameToWaitForWithTemporaryMessage: (
      state,
      {
        payload,
      }: PayloadAction<{
        attachmentName: string;
        temporaryMessageInternalId: string;
      }>
    ) => {
      state.chat.attachmentNamesToWaitForWithTemporaryMessage = [
        ...state.chat.attachmentNamesToWaitForWithTemporaryMessage,
        payload,
      ];
    },
    removeAttachmentNameToWaitForWithTemporaryMessage: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.chat.attachmentNamesToWaitForWithTemporaryMessage =
        state.chat.attachmentNamesToWaitForWithTemporaryMessage.filter(
          (value) => value.attachmentName !== payload
        );
    },
    removeAttachmentNameToWaitForWithTemporaryMessageAndTemporaryMessage: (
      state,
      {
        payload,
      }: PayloadAction<{
        attachmentName: string;
        contactId: string;
      }>
    ) => {
      const indexOfAttachmentNameToWaitFor =
        state.chat.attachmentNamesToWaitForWithTemporaryMessage.findIndex(
          (message) => message.attachmentName === payload.attachmentName
        );

      if (indexOfAttachmentNameToWaitFor > -1) {
        const temporaryMessageInternalId =
          state.chat.attachmentNamesToWaitForWithTemporaryMessage[
            indexOfAttachmentNameToWaitFor
          ].temporaryMessageInternalId;

        state.chat.attachmentNamesToWaitForWithTemporaryMessage =
          state.chat.attachmentNamesToWaitForWithTemporaryMessage.filter(
            (value) => value.attachmentName !== payload.attachmentName
          );

        const activeContactArrayIndex = state.chat.activeContacts.findIndex(
          (contact) => contact.curr?.contactId === payload.contactId
        );
        // if not found, return
        if (activeContactArrayIndex === -1) {
          return;
        } else {
          state.chat.activeContacts = [
            ...state.chat.activeContacts.slice(0, activeContactArrayIndex),
            {
              ...state.chat.activeContacts[activeContactArrayIndex],
              chatEntries: state.chat.activeContacts[
                activeContactArrayIndex
              ].chatEntries.filter(
                (chatEntry) =>
                  chatEntry.internalId !== temporaryMessageInternalId
              ),
            },
            ...state.chat.activeContacts.slice(activeContactArrayIndex + 1),
          ];
        }
      }
    },
    updateOrCreateChatChannelActiveContact: (
      state,
      { payload }: PayloadAction<TChatContact>
    ) => {
      const activeContactArrayIndex = state.chat.activeContacts.findIndex(
        (contact) => contact.curr?.contactId === payload.curr?.contactId
      );
      // if not found, create
      if (activeContactArrayIndex === -1) {
        state.chat.activeContacts = [...state.chat.activeContacts, payload];
      } else {
        state.chat.activeContacts = [
          ...state.chat.activeContacts.slice(0, activeContactArrayIndex),
          { ...state.chat.activeContacts[activeContactArrayIndex], ...payload },
          ...state.chat.activeContacts.slice(activeContactArrayIndex + 1),
        ];
      }
    },
    updatePartialChatChannelActiveContact: (
      state,
      { payload }: PayloadAction<Partial<TChatContact>>
    ) => {
      const activeContactArrayIndex = state.chat.activeContacts.findIndex(
        (contact) => contact.curr?.contactId === payload.curr?.contactId
      );
      // if not found, return
      if (activeContactArrayIndex === -1) {
        return;
      } else {
        state.chat.activeContacts = [
          ...state.chat.activeContacts.slice(0, activeContactArrayIndex),
          { ...state.chat.activeContacts[activeContactArrayIndex], ...payload },
          ...state.chat.activeContacts.slice(activeContactArrayIndex + 1),
        ];
      }
    },
    updatePartialChatChannelActiveContactHistoryItemsByEmailWithTranscript: (
      state,
      {
        payload,
      }: PayloadAction<{
        contactId: string;
        historyItemsByEmailWithTranscript: TChatHistoryItemByEmailWithTranscript[];
      }>
    ) => {
      const activeContactArrayIndex = state.chat.activeContacts.findIndex(
        (contact) => contact.curr?.contactId === payload.contactId
      );
      // if not found, return
      if (activeContactArrayIndex === -1) {
        return;
      } else {
        state.chat.activeContacts = [
          ...state.chat.activeContacts.slice(0, activeContactArrayIndex),
          {
            ...state.chat.activeContacts[activeContactArrayIndex],
            historyItemsByEmailWithTranscript:
              payload.historyItemsByEmailWithTranscript,
          },
          ...state.chat.activeContacts.slice(activeContactArrayIndex + 1),
        ];
      }
    },
    addChatEntryToChatChannelActiveContact: (
      state,
      {
        payload,
      }: PayloadAction<{
        chatEntry: TChatEntry;
        contactId: string;
      }>
    ) => {
      const activeContactArrayIndex = state.chat.activeContacts.findIndex(
        (contact) => contact.curr?.contactId === payload.contactId
      );
      // if not found, return
      if (activeContactArrayIndex === -1) {
        return;
      } else {
        state.chat.activeContacts = [
          ...state.chat.activeContacts.slice(0, activeContactArrayIndex),
          {
            ...state.chat.activeContacts[activeContactArrayIndex],
            chatEntries: [
              ...state.chat.activeContacts[activeContactArrayIndex].chatEntries,
              payload.chatEntry,
            ],
          },
          ...state.chat.activeContacts.slice(activeContactArrayIndex + 1),
        ];
      }
    },
    removeChatEntryToChatChannelActiveContactByInternalId: (
      state,
      {
        payload,
      }: PayloadAction<{
        chatEntryInternalId: string;
        contactId: string;
      }>
    ) => {
      const activeContactArrayIndex = state.chat.activeContacts.findIndex(
        (contact) => contact.curr?.contactId === payload.contactId
      );
      // if not found, return
      if (activeContactArrayIndex === -1) {
        return;
      } else {
        state.chat.activeContacts = [
          ...state.chat.activeContacts.slice(0, activeContactArrayIndex),
          {
            ...state.chat.activeContacts[activeContactArrayIndex],
            chatEntries: state.chat.activeContacts[
              activeContactArrayIndex
            ].chatEntries.filter(
              (chatEntry) =>
                chatEntry.internalId !== payload.chatEntryInternalId
            ),
          },
          ...state.chat.activeContacts.slice(activeContactArrayIndex + 1),
        ];
      }
    },
    setChatEntriesToChatChannelActiveContact: (
      state,
      {
        payload,
      }: PayloadAction<{
        chatEntries: TChatEntry[];
        contactId: string;
      }>
    ) => {
      const activeContactArrayIndex = state.chat.activeContacts.findIndex(
        (contact) => contact.curr?.contactId === payload.contactId
      );
      // if not found, return
      if (activeContactArrayIndex === -1) {
        return;
      } else {
        state.chat.activeContacts = [
          ...state.chat.activeContacts.slice(0, activeContactArrayIndex),
          {
            ...state.chat.activeContacts[activeContactArrayIndex],
            chatEntries: payload.chatEntries,
          },
          ...state.chat.activeContacts.slice(activeContactArrayIndex + 1),
        ];
      }
    },
    removeChatChannelActiveContactIfPresent: (
      state,
      { payload }: PayloadAction<connect.Contact>
    ) => {
      const activeContactArrayIndex = state.chat.activeContacts.findIndex(
        (contact) => contact.curr?.contactId === payload.contactId
      );
      // if not found, return..
      if (activeContactArrayIndex === -1) {
        return;
      }

      /**
       * This logic is for setting the active chat tab index.
       * If the contact removed is the current active one in the UI (state.chat.activeChatTabIndex === activeContactArrayIndex)
       * AND if that is the last contact (state.chat.activeChatTabIndex === state.chat.activeContacts.length - 1)
       * AND if there is more than one active contact existing (state.chat.activeContacts.length > 1)
       * THEN decrement the active chat tab index (i.e. move to the previous one in the UI
       * to not be on an index that doesn't exist anymore)
       */
      if (
        state.chat.activeChatTabIndex === activeContactArrayIndex &&
        state.chat.activeChatTabIndex ===
          state.chat.activeContacts.length - 1 &&
        state.chat.activeContacts.length > 1
      ) {
        state.chat.activeChatTabIndex = state.chat.activeChatTabIndex - 1;
      }

      state.chat.activeContacts = [
        ...state.chat.activeContacts.slice(0, activeContactArrayIndex),
        ...state.chat.activeContacts.slice(activeContactArrayIndex + 1),
      ];
    },
    setActiveChatTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.chat.activeChatTabIndex = payload;
    },
    setIsRejectedChat: (state, { payload }: PayloadAction<boolean>) => {
      state.chat.isRejectedChat = payload;
    },
    setCustomerLanguage: (state, { payload }: PayloadAction<string>) => {
      state.chat.customerLanguage = payload;
    },
    setLastOriginalMessage: (state, { payload }: PayloadAction<string>) => {
      state.chat.lastOriginalMessage = payload;
    },
    setIsTranslationError: (state, { payload }: PayloadAction<boolean>) => {
      state.chat.isTranslationError = payload;
    },
  },
});

export const ccpActions = ccpReducer.actions;

export default ccpReducer.reducer;
