import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';

import useScreenPopUp from '../../hooks/useScreenPopUp';
import { useConfig } from '../../hooks/useConfig';

import Header from './header/Header';
import MiniCcp from './minicontroller/MiniCcp';
import Call from './voice/actions/ongoing/Call';
import DialpadV1 from './voice/dialpad/DialpadV1';
import Loader from '../loader/Loader';
import QuickConnects from './quickConnects/QuickConnects';
import AutomatedCallInitiatorReceiver from './voice/automated-call-initiator-receiver';
import DesktopNotificationsRequester from './desktop-notifications-requester';
import TaskListener from './task/task-listener';
import TaskUi from './task/task-ui';

import { authSelectors, ccpSelectors } from '../../../store/selectors';
import { authActions } from '../../../store/reducers/authReducer';
import { COUNTRY_CODES } from './voice/dialpad/constants';
import { ccpActions } from '../../../store/reducers/ccpReducer';
import { sideNavActions } from '../../../store/reducers/sideNavReducer';
import { TCallData } from '../../types/ccp';
import { automatedCallActions } from '../../../store/reducers/automated-call-reducer';
import { notificationActions } from '../../../store/reducers/notificationReducer';
import { usersApi } from '../../api/usersApi';
import ChatListener from './chat/chat-listener';
import ChatUi from './chat/chat-ui';
import { parsePhoneNumber } from 'libphonenumber-js';

export interface ContactControlPanelProps {
  open: boolean;
}

export const ContactControlPanel = ({
  open = true,
}: ContactControlPanelProps) => {
  const { env } = useConfig();
  useScreenPopUp();
  const dispatch = useDispatch();
  const container = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const { status, curr } = useSelector(ccpSelectors.getVoiceChannelContact);
  const channel = useSelector(ccpSelectors.getActiveUiChannel);
  const channelsActivationMap = useSelector(
    ccpSelectors.getChannelsActivationMap
  );
  const isThereOngoingCallContact = useRef<boolean>(false);
  useEffect(() => {
    isThereOngoingCallContact.current = Boolean(curr);
  }, [curr]);

  const { t } = useTranslation();

  const isLoggedInConnect = useSelector(authSelectors.getIsLoggedOnConnect);

  const initCcp = (): void => {
    if (!container.current) {
      return;
    }
    setLoading(true);

    const options = {
      ccpUrl: env?.connectInstanceUrl,
      region: env?.awsRegion,
      softphone: { allowFramedSoftphone: true },
      loginPopup: true,
      loginPopupAutoClose: true,
      loginUrl: env?.ccpLoginUrl,
      loginOptions: {
        autoClose: true,
        height: 600,
        width: 400,
        top: 0,
        left: 0,
      },
    } as connect.InitCCPOptions;

    connect.core.initCCP(container.current, options);

    (connect.core as any).onInitialized(() => {
      dispatch(authActions.setCcpInitialized());
    });

    connect.core.onAuthFail(() => {
      dispatch(authActions.logout());
    });

    connect.agent((agent) => {
      setLoading(false);

      const agentChannelConcurrency = agent.getChannelConcurrency();
      dispatch(
        ccpActions.setChannelsActivationMap({
          VOICE: agentChannelConcurrency.VOICE >= 1,
          CHAT: agentChannelConcurrency.CHAT >= 1,
          TASK: agentChannelConcurrency.TASK >= 1,
        })
      );
    });
  };

  useEffect(() => {
    initCcp();
  }, [env?.connectInstanceUrl, env?.awsRegion]);

  // Callback for when an external trigger comes for a call
  // and thus an automated call must be made
  const onCallRequestReceived = useCallback((response: TCallData) => {
    if (!isThereOngoingCallContact.current) {
      const type = response.callReason;
      const value = response.inputValue;

      const countryCode = Object.keys(COUNTRY_CODES)
        .filter((key) =>
          response
            .customerEndpoint!.replace(/\s/g, '')
            .includes(COUNTRY_CODES[key])
        )
        .reduce((a, b) => (a.length > b.length ? a : b), '');
      if (countryCode) {
        dispatch(
          ccpActions.setVoiceChannelOutboundCall({
            form: {
              type,
              value,
            },
            country: countryCode,
            number: parsePhoneNumber(
              response.customerEndpoint
            ).formatInternational(),
          })
        );
        dispatch(ccpActions.setVoiceChannelDialpadVisibility(true));
        dispatch(sideNavActions.toggleOpen({ isOpen: true }));
        dispatch(automatedCallActions.setAutomatedCallData(response));
      }
    }
  }, []);

  /**
   * Loads the default country code to use in the CCP
   */
  useEffect(() => {
    usersApi
      .getCountryCode()
      .then(({ data }) => {
        dispatch(
          ccpActions.setVoiceChannelOutboundCallCountry(data.toLowerCase())
        );
      })
      .catch((error) => {
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'error',
            message: (error as any).response?.data.error,
          })
        );
      });
  }, []);

  const onDownloadLogsClick = () => {
    connect.getLog().download();
  };

  return (
    <>
      <AutomatedCallInitiatorReceiver
        onCallRequestReceived={onCallRequestReceived}
      />
      <DesktopNotificationsRequester />
      {open && !isLoggedInConnect && <Loader />}
      <Box ref={container} style={{ display: 'none' }} />
      <Box sx={{ display: open ? 'block' : 'none' }}>
        {!loading && isLoggedInConnect && (
          <>
            <Header />
            {channelsActivationMap.VOICE && (
              <Box
                sx={{
                  display: channel === 'VOICE' ? 'block' : 'none',
                }}
              >
                <Call />
              </Box>
            )}
            {channelsActivationMap.TASK && env?.enabledFeatures?.CCP_TASK && (
              <TaskListener />
            )}
            {channelsActivationMap.CHAT && env?.enabledFeatures?.CCP_CHAT && (
              <ChatListener />
            )}

            {env?.enabledFeatures?.DIALPAD && channelsActivationMap.VOICE ? (
              <Box
                sx={{
                  display: channel === 'VOICE' ? 'block' : 'none',
                }}
              >
                <DialpadV1 />
              </Box>
            ) : null}

            {status === 'Connected' && channelsActivationMap.VOICE && (
              <Box
                sx={{
                  display: channel === 'VOICE' ? 'block' : 'none',
                }}
              >
                <QuickConnects />
              </Box>
            )}

            {channelsActivationMap.CHAT && (
              <Box
                sx={{
                  display: channel === 'CHAT' ? 'block' : 'none',
                }}
              >
                <ChatUi />
              </Box>
            )}

            {channelsActivationMap.TASK && env?.enabledFeatures?.CCP_TASK ? (
              <Box
                sx={{
                  display: channel === 'TASK' ? 'block' : 'none',
                }}
              >
                <TaskUi />
              </Box>
            ) : null}
          </>
        )}
      </Box>
      <Box sx={{ display: open ? 'none' : 'block' }}>
        {!loading && isLoggedInConnect && <MiniCcp />}
      </Box>
      {open && isLoggedInConnect && !loading && (
        <Box
          sx={{
            flex: 1,
            padding: 1,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              paddingBottom: 1 / 2,
            }}
          >
            <Button
              onClick={onDownloadLogsClick}
              sx={{
                textTransform: 'none',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {t('ccp.downloadLogs')}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
