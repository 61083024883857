import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useLoaderStyles = makeStyles((theme: Theme) => ({
  loaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '.5rem 0',

    '& > span': {
      fontSize: '1.2rem',
      fontWeight: 500,
      maxHeight: '5rem',
      margin: '.5rem',
    },
  },
}));
