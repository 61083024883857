import { useRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PhoneIcon from '@mui/icons-material/Phone';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MessageIcon from '@mui/icons-material/Message';
import { Box, Button } from '@mui/material';
import IncomingTaskNotificationIcon from '@mui/icons-material/NewReleases';

import StatusMenu from './StatusMenu';

import { TStatus } from '../../../types/ccp';
import { getStatusIcon } from '../shared/helper';
import { ccpActions } from '../../../../store/reducers/ccpReducer';
import { ccpSelectors } from '../../../../store/selectors';
import { useConfig } from '../../../hooks/useConfig';

import { useHeaderStyles } from './Header-style';
import { useIncomingTaskStyles } from '../../../components/ContactControlPanel/minicontroller/mini-ccp-style';

const Header = () => {
  const { taskIcon } = useIncomingTaskStyles();

  const contactState = useSelector(ccpSelectors.getState);
  const dispatch = useDispatch();
  const status = useSelector(ccpSelectors.getStatus);
  const channel = useSelector(ccpSelectors.getActiveUiChannel);
  const open = useSelector(ccpSelectors.getHeaderVisibility);
  const channelsActivationMap = useSelector(
    ccpSelectors.getChannelsActivationMap
  );
  /**
   * Enqueued next state is getting saved in the local state AND
   * also in a ref as we want to be able to access it in
   * an effect below without having it in the deps array.
   *
   * Thus we need the effect below the ref declaration
   * to always update the ref when state changes (to keep these
   * two in sync).
   */
  const [enqueuedNextState, setEnqueuedNextState] = useState<TStatus | null>(
    null
  );
  const enqueuedNextStateRef = useRef<TStatus | null>(null);
  useEffect(() => {
    enqueuedNextStateRef.current = enqueuedNextState;
  }, [enqueuedNextState]);

  const { linkButton, headerButton, headerWrapper, channelWrapper } =
    useHeaderStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);

  const agent = new connect.Agent();

  /**
   * Effect that sets the current state of the agent (reads on mount to set the default
   * state and also fetches it once every 500 ms)
   */
  useEffect(() => {
        const contact = agent.getContacts()[0];
        const customerConnection = contact?.getConnections()
        .find((cnn) => cnn.getType() === connect.ConnectionType.INBOUND);
        contact?.onACW((contact) => {
          dispatch(
            ccpActions.updateOrCreateChatChannelActiveContact({
              curr: contact,
              status: 'AfterContactWork',
              contactId: contact.contactId,
              name:
                (customerConnection as connect.ChatConnection).getMediaInfo()
                  .originalInfo.customerName ?? '',
              chatEntries: [],
            })
          );
        });

    const getAgentStatusAndSetLocalStates = () => {
      const {
        name: currentStateName,
        type: currentStateType,
        nextState,
        availabilityState,
      } = agent.getState() as any;

      if (nextState && nextState.name) {
        setEnqueuedNextState(nextState.name);
      } else {
        setEnqueuedNextState(null);
      }

      /**
       * Sometimes the type is 'system' but there is a key called availabilityState set
       * to 'Available' - which in fact means our agent is available.
       *
       * If the type is 'system' but does not have availabilityState 'Available',
       * ignore setting the state to the type 'system' state.
       */
      if (currentStateType === 'system' || currentStateType === 'error') {
        if (currentStateType === 'error') {
          dispatch(ccpActions.setVoiceChannelWasMissed(true));
          dispatch(ccpActions.setVoiceChannelContact({ status: 'Missed' }));
        }
        if (availabilityState === 'Available') {
          dispatch(ccpActions.setStatus(availabilityState));
        } else {
          return;
        }
      } else {
        dispatch(ccpActions.setStatus(currentStateName));
      }
    };

    const interval = setInterval(() => {
      getAgentStatusAndSetLocalStates();
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleToggle = () => {
    dispatch(ccpActions.setHeaderVisibility(!open));
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    dispatch(ccpActions.setHeaderVisibility(false));
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      dispatch(ccpActions.setHeaderVisibility(false));
    }
  };

  const handleListSelect = (status: TStatus) => {
    const statusState = agent.getAgentStates().find((s: any) => {
      if (s.name === status) return s;
      return null;
    });

    agent.setState(
      statusState!,
      {
        success: () => {
          //
        },
        failure: () => {
          //
        },
      },
      { enqueueNextState: true }
    );
    dispatch(ccpActions.setHeaderVisibility(false));
  };

  const id = useMemo(() => (open ? 'menu-list-grow' : undefined), [open]);

  const { env } = useConfig();

  const { t } = useTranslation();

  return (
    <Box className={headerWrapper}>
      <Button
        color="primary"
        className={headerButton}
        ref={anchorRef}
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleToggle}
        disabled={status === 'FailedConnectAgent'}
        id="header-button"
        sx={{
          fontSize: 14,
        }}
      >
        {getStatusIcon(status)}
        <Box
          sx={{
            flex: 1,
            textAlign: 'start',
            whiteSpace: 'pre-line',
          }}
          component="span"
        >
          {`${
            enqueuedNextState
            ? `${t('ccp.nextStatePre')}${enqueuedNextState}`
            : status
            }`}
        </Box>
        <ArrowDropDownIcon color="primary" />
      </Button>
      <StatusMenu
        open={open}
        anchorRef={anchorRef}
        handleClose={handleClose}
        handleListKeyDown={handleListKeyDown}
        handleListSelect={handleListSelect}
        id={id}
      />
      <Box className={channelWrapper}>
        {channelsActivationMap.VOICE && (
          <Button
            className={linkButton}
            disabled={channel === 'VOICE'}
            onClick={() => {
              dispatch(ccpActions.setActiveUiChannel('VOICE'));
            }}
          >
            <PhoneIcon />
            {contactState.voice.contact.status !== 'Initial' &&
              (channel === 'TASK' || channel === 'CHAT') ? (
              <IncomingTaskNotificationIcon className={taskIcon} />
            ) : null}
          </Button>
        )}
        {channelsActivationMap.CHAT && env?.enabledFeatures?.CCP_CHAT && (
          <Button
            className={linkButton}
            disabled={channel === 'CHAT'}
            onClick={() => {
              dispatch(ccpActions.setActiveUiChannel('CHAT'));
            }}
          >
            <MessageIcon />
            {(contactState.chat.activeContacts.length > 0 ||
              contactState.chat.incomingContact.status === 'Incoming' ||
              contactState.chat.incomingContact.status === 'Rejected') &&
              (channel === 'TASK' || channel === 'VOICE') ? (
              <IncomingTaskNotificationIcon className={taskIcon} />
            ) : null}
          </Button>
        )}
        {channelsActivationMap.TASK && env?.enabledFeatures?.CCP_TASK && (
          <Button
            className={linkButton}
            disabled={channel === 'TASK'}
            onClick={() => {
              dispatch(ccpActions.setActiveUiChannel('TASK'));
            }}
          >
            <AssignmentIcon />
            {(contactState.task.activeContacts.length > 0 ||
              contactState.task.incomingContact.status === 'Incoming' ||
              contactState.task.incomingContact.status === 'Rejected') &&
              (channel === 'CHAT' || channel === 'VOICE') ? (
              <IncomingTaskNotificationIcon className={taskIcon} />
            ) : null}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Header;
