import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { STYLE_CONSTANTS } from '../../../../assets/styles/defaultTheme/constants';

const getColor = (theme: Theme, status: string): string => {
  switch (status) {
    case 'Available':
      return theme.palette.secondary.main;
    case 'Offline':
      return theme.palette.grey[600];
    default:
      return theme.palette.warning.main;
  }
};

const mainWrapper = (theme: Theme) => ({
  ...STYLE_CONSTANTS.FLEX.COLUMN,
  width: theme.spacing(12),
});

const callText = (theme: Theme) => ({
  display: 'block',
  fontWeight: 600,
  fontSize: '.7rem',
  textTransform: 'uppercase',
  textAlign: 'center',
  whiteSpace: 'break-spaces',
  width: theme.spacing(10),
  margin: '.8rem 0',
  cursor: 'inherit',
});

export const useMiniCcpStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    ...mainWrapper(theme),
  },
  actionButton: {
    '&.MuiButton-root': {
      padding: '.6rem',
    },
  },
  loaderWrapper: {
    ...STYLE_CONSTANTS.FLEX.COLUMN,
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: `${theme.palette.primary.main}14`,
  },
  welcomeWrapper: {
    textAlign: 'center',
    padding: '1rem 0 .5rem',
    backgroundColor: (props: { status: string }) =>
      `${getColor(theme, props.status)}26`,

    '& > svg': {
      fill: (props: { status: string }) => getColor(theme, props.status),
    },
  },
  loaderText: {
    '&.MuiTypography-root': {
      fontSize: '.7rem',
      fontWeight: 600,
      margin: '.8rem 0',
    },
  },
  customerEnd: {
    ...mainWrapper(theme),
    alignItems: 'center',
    backgroundColor: `${theme.palette.warning.main}26`,
    padding: '.5rem 0',

    '& > .MuiSvgIcon-root': {
      fontSize: '2.5rem',
    },

    '& > .MuiTypography-root': {
      fontSize: '.7rem',
      fontWeight: 600,
      width: theme.spacing(11),
      whiteSpace: 'break-spaces',
      textAlign: 'center',
    },
  },
}));

export const useHeaderStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    ...STYLE_CONSTANTS.FLEX.SPACE_BETWEEN,
    padding: '0 .3rem',
    marginTop: '1.5rem',
    cursor: 'pointer',
    backgroundColor: (props: { status: string }) =>
      `${getColor(theme, props.status)}26`,

    '&:hover': {
      backgroundColor: (props: { status: string }) =>
        `${getColor(theme, props.status)}40`,
    },
  },
}));

export const useIncomingCallStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    ...mainWrapper(theme),
  },
  answerCall: {
    '&.MuiButton-root': {
      ...mainWrapper(theme),
      alignItems: 'center',
      backgroundColor: `${theme.palette.third?.main}26`,

      '&:hover': {
        backgroundColor: `${theme.palette.third?.main}35`,
      },
    },
  },
  callIcon: {
    '&.MuiSvgIcon-root': {
      color: theme.palette.third?.main,
      fontSize: '2.5rem',
    },
  },
  callText: {
    '&.MuiTypography-root': {
      ...callText(theme),
    },
  },
  endCall: {
    '&.MuiButton-root': {
      backgroundColor: `${theme.palette.third?.main}26`,
      borderTop: `.1rem solid ${theme.palette.common.white}`,
      padding: '.6rem',

      '&:hover': {
        backgroundColor: `${theme.palette.error.main}26`,
      },
    },
  },
}));

export const useIncomingTaskStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    ...mainWrapper(theme),
  },
  openCCP: {
    '&.MuiButton-root': {
      ...mainWrapper(theme),
      alignItems: 'center',
      backgroundColor: `${theme.palette.third?.main}26`,

      '&:hover': {
        backgroundColor: `${theme.palette.third?.main}35`,
      },
    },
  },
  taskIcon: {
    '&.MuiSvgIcon-root': {
      color: theme.palette.third?.main,
      fontSize: '2.5rem',
    },
  },
  taskText: {
    '&.MuiTypography-root': {
      ...callText(theme),
    },
  },
}));

export const useIncomingChatStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    ...mainWrapper(theme),
  },
  openCCP: {
    '&.MuiButton-root': {
      ...mainWrapper(theme),
      alignItems: 'center',
      backgroundColor: `${theme.palette.third?.main}26`,

      '&:hover': {
        backgroundColor: `${theme.palette.third?.main}35`,
      },
    },
  },
  chatIcon: {
    '&.MuiSvgIcon-root': {
      color: theme.palette.third?.main,
      fontSize: '2.5rem',
    },
  },
  chatText: {
    '&.MuiTypography-root': {
      ...callText(theme),
    },
  },
}));

export const useOngoingCallStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    ...mainWrapper(theme),
  },
  onHoldCall: {
    ...mainWrapper(theme),
    alignItems: 'center',
    backgroundColor: `${theme.palette.warning.dark}14`,
  },
  ongoingCall: {
    ...mainWrapper(theme),
    alignItems: 'center',
    backgroundColor: `${theme.palette.third?.main}14`,
  },
  onHoldIcon: {
    '&.MuiSvgIcon-root': {
      color: theme.palette.warning.dark,
      fontSize: '2.5rem',
    },
  },
  callIcon: {
    '&.MuiSvgIcon-root': {
      color: `${theme.palette.third?.main}80`,
      fontSize: '2.5rem',
    },
  },
  callText: {
    '&.MuiTypography-root': {
      ...callText(theme),
    },
  },
  endCall: {
    '&.MuiButton-root': {
      backgroundColor: `${theme.palette.third?.main}14`,
      borderTop: `.1rem solid ${theme.palette.common.white}`,
      padding: '.6rem',

      '&:hover': {
        backgroundColor: `${theme.palette.error.main}26`,
      },
    },
  },
  endCallHold: {
    '&.MuiButton-root': {
      backgroundColor: `${theme.palette.warning.dark}14`,
      borderTop: `.1rem solid ${theme.palette.common.white}`,
      padding: '.6rem',

      '&:hover': {
        backgroundColor: `${theme.palette.error.main}26`,
      },
    },
  },
  callButtons: {
    '&.MuiButton-root': {
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.third?.main}14`,
      borderTop: `.1rem solid ${theme.palette.common.white}`,
      padding: '.6rem',
    },
  },
  holdButtons: {
    '&.MuiButton-root': {
      color: theme.palette.warning.dark,
      backgroundColor: `${theme.palette.warning.dark}14`,
      borderTop: `.1rem solid ${theme.palette.common.white}`,
      padding: '.6rem',

      '&:hover': {
        backgroundColor: `${theme.palette.warning.dark}35`,
      },
    },
  },
}));

export const useOnMissedCallStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '&.MuiButton-root': {
      ...mainWrapper(theme),
      alignItems: 'center',
      backgroundColor: `${theme.palette.error.main}26`,

      '&:hover': {
        backgroundColor: `${theme.palette.error.main}35`,
      },
    },
  },
  missedText: {
    '&.MuiTypography-root': {
      ...callText(theme),
    },
  },
  missedIcon: {
    '&.MuiSvgIcon-root': {
      fontSize: '2.5rem',
    },
  },
}));

export const useACWStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '&.MuiButton-root': {
      ...mainWrapper(theme),
      alignItems: 'center',
      backgroundColor: `${theme.palette.warning.main}26`,

      '&:hover': {
        backgroundColor: `${theme.palette.warning.main}35`,
      },
    },
  },
  acwText: {
    '&.MuiTypography-root': {
      ...callText(theme),
    },
  },
  acwIcon: {
    '&.MuiSvgIcon-root': {
      fontSize: '2.5rem',
    },
  },
}));

export const useRecordingStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    ...STYLE_CONSTANTS.FLEX.COLUMN,
    width: theme.spacing(10),
    alignSelf: 'center',
    borderTop: `.1px solid ${theme.palette.primary.main}26`,
    paddingTop: '.5rem',
    marginTop: '.5rem',
  },
  recordingText: {
    '&.MuiTypography-root': {
      fontSize: '.66rem',
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
  },
  actionButton: {
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      backgroundColor: `${theme.palette.primary.main}14`,
      fontSize: '.7rem',
      alignSelf: 'center',
      marginTop: '.5rem',
      width: '100%',

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}26`,
      },

      '& > .MuiSvgIcon-root': {
        fontSize: '1.2rem',
      },
    },
  },
}));

export const useTransferStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    ...mainWrapper(theme),
  },
  callIcon: {
    '&.MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      fontSize: '2.5rem',
    },
  },
  callText: {
    '&.MuiTypography-root': {
      ...callText(theme),
    },
  },
  transferredCall: {
    ...mainWrapper(theme),
    alignItems: 'center',
    backgroundColor: `${theme.palette.secondary.main}40`,
  },
  onHoldCall: {
    ...mainWrapper(theme),
    alignItems: 'center',
    backgroundColor: `${theme.palette.warning.dark}14`,
  },
  endCall: {
    '&.MuiButton-root': {
      backgroundColor: `${theme.palette.secondary.main}40`,
      borderTop: `.1rem solid ${theme.palette.common.white}`,
      padding: '.6rem',

      '&:hover': {
        backgroundColor: `${theme.palette.error.main}26`,
      },
    },
  },
  callButtons: {
    '&.MuiButton-root': {
      backgroundColor: `${theme.palette.secondary.main}40`,
      borderTop: `.1rem solid ${theme.palette.common.white}`,
      padding: '.6rem',
    },
  },
  onHoldIcon: {
    '&.MuiSvgIcon-root': {
      color: theme.palette.warning.dark,
      fontSize: '2.5rem',
    },
  },
}));
