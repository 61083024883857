import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { screenPopUpsActions } from '../../store/reducers/screenPopUpsReducer';
import { screenPopUpsSelectors } from '../../store/selectors';
import { screenPopUpsApi } from '../api/screenPopUpsApi';

const useScreenPopUp = () => {
  const screenPopUpsUrl = useSelector(screenPopUpsSelectors.getPopUps);

  const dispatch = useDispatch();

  useEffect(() => {
    if (screenPopUpsUrl === null)
      screenPopUpsApi.getPopUp().then(({ data }) => {
        data && dispatch(screenPopUpsActions.setPopUps(data));
      });
  }, []);
};

export default useScreenPopUp;
