import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useHeaderStyles = makeStyles((theme: Theme) => ({
  over: {
    zIndex: 100,
  },
  listWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
    borderRadius: '.5rem',
    boxShadow: `0 0.3rem 0.6rem ${theme.palette.common.black}33`,

    '& > li': {
      padding: '.5rem 2rem',
      textAlign: 'center',

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}20`,
      },
    },
  },
  linkButton: {
    '&.MuiButton-root': {
      height: '40px',

      backgroundColor: `${theme.palette.primary.main}26`,
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}26`,
        color: theme.palette.primary.main,
      },

      '&:disabled': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },

      borderRadius: '0 0 0 0',

      '&:first-of-type': {
        borderRadius: '.3rem 0 0 .3rem',
      },

      '&:last-of-type': {
        borderRadius: '0 .3rem .3rem 0',
      },

      '&:last-of-type:first-of-type': {
        borderRadius: '.3rem .3rem .3rem .3rem',
      },

      '& > svg': {
        fontSize: '1.2rem',
      },
    },
  },
  headerButton: {
    '&.MuiButton-root': {
      flex: 1,

      display: 'flex',
      justifyContent: 'start',
      gap: '4px',
      marginRight: '.2rem',
      textTransform: 'none',
    },
  },
  channelWrapper: {
    marginLeft: '.2rem',
    display: 'flex',
    justifyContent: 'end',
  },
  headerWrapper: {
    display: 'flex',
    margin: '1rem 1rem 0',
    alignItems: 'center',
  },
}));
