import { AxiosPromise } from 'axios';
import {
  TChatHistoryItemByEmailWithTranscriptResponse,
  TGetChatHistoryByEmailWithTranscriptRequest,
} from '../types/chat-types';
import apiPaths from './apiPaths';
import { axiosInstance } from './axiosClient';

export const chatApi: {
  getChatHistoryByEmailWithTranscript: (
    request: TGetChatHistoryByEmailWithTranscriptRequest
  ) => AxiosPromise<TChatHistoryItemByEmailWithTranscriptResponse[]>;
} = {
  getChatHistoryByEmailWithTranscript: (request) =>
    axiosInstance.get(
      apiPaths.chat.historyByEmailWithTranscript + `/${request.contactId}`
    ),
};
