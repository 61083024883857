import { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DialpadIcon from '@mui/icons-material/Dialpad';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { WelcomeIcon } from '../../../../assets/images';
import { ccpSelectors } from '../../../../store/selectors';
import { ccpActions } from '../../../../store/reducers/ccpReducer';
import { sideNavActions } from '../../../../store/reducers/sideNavReducer';

import { useMiniCcpStyles } from './mini-ccp-style';

const Header = lazy(() => import(/* webpackChunkName: 'header' */ './Header'));
const IncomingCall = lazy(
  () => import(/* webpackChunkName: 'incomingCall' */ './actions/IncomingCall')
);
const OngoingCall = lazy(
  () => import(/* webpackChunkName: 'ongoingCall' */ './actions/OngoingCall')
);
const OnMissedCall = lazy(
  () => import(/* webpackChunkName: 'onMissedCall' */ './actions/OnMissedCall')
);
const AfterCallWork = lazy(
  () =>
    import(/* webpackChunkName: 'afterCallWork' */ './actions/AfterCallWork')
);
const Recording = lazy(
  () => import(/* webpackChunkName: 'recording' */ './actions/Recording')
);
const OutboundCall = lazy(
  () => import(/* webpackChunkName: 'outboundCall' */ './actions/OutboundCall')
);
const Transfer = lazy(
  () => import(/* webpackChunkName: 'transfer' */ './actions/Transfer')
);
const IncomingTask = lazy(
  () =>
    import(
      /* webpackChunkName: 'incomingTaskMiniCCP' */ './actions/task/incoming-task'
    )
);
const IncomingChat = lazy(
  () =>
    import(
      /* webpackChunkName: 'incomingChatMiniCCP' */ './actions/chat/incoming-chat'
    )
);

const MiniCcp = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    task: { incomingContact: incomingTaskContact },
    chat: { incomingContact: incomingChatContact },
    voice: {
      wasMissed,
      endedByCustomer,
      phoneNumber,
      contact: { status },
    },
    status: agentStatus,
  } = useSelector(ccpSelectors.getState);

  const {
    mainWrapper,
    actionButton,
    loaderWrapper,
    loaderText,
    customerEnd,
    welcomeWrapper,
  } = useMiniCcpStyles({ status: agentStatus || '' });

  const renderContent = (): JSX.Element | null => {
    if (incomingTaskContact.curr) {
      return <IncomingTask />;
    }
    if (incomingChatContact.curr) {
      return <IncomingChat />;
    }

    switch (status) {
      case 'Initial':
        return (
          <Box className={welcomeWrapper}>
            <WelcomeIcon />
          </Box>
        );
      case 'Incoming':
        return <IncomingCall />;
      case 'Outbound':
        return <OutboundCall />;
      case 'Accepted':
        return (
          <Box className={loaderWrapper}>
            <CircularProgress sx={{ marginTop: '.8rem' }} />
            <Typography className={loaderText}>{phoneNumber}</Typography>
          </Box>
        );
      case 'Connected':
        return <OngoingCall />;
      case 'AfterCallWork':
        return <AfterCallWork />;
      case 'Transfer':
        return <Transfer />;
      default:
        return null;
    }
  };

  return (
    <Suspense fallback={null}>
      <Box className={mainWrapper}>
        <Header />
        {renderContent()}
        {endedByCustomer && (
          <Box className={customerEnd}>
            <WarningIcon color="warning" />
            <Typography>{t('ccp.customerEndedCall')}</Typography>
          </Box>
        )}
        {wasMissed && <OnMissedCall />}
        <Button
          className={actionButton}
          onClick={() => {
            dispatch(sideNavActions.toggleOpen({ isOpen: true }));
            dispatch(ccpActions.setVoiceChannelDialpadVisibility(true));
            dispatch(ccpActions.setQuickConnectsVisibility(false));
          }}
        >
          <Tooltip
            disableFocusListener
            disableTouchListener
            placement="bottom"
            title={`${t('miniCcp.dialpad')}`}
          >
            <DialpadIcon />
          </Tooltip>
        </Button>
        {status === 'Connected' && (
          <Button
            className={actionButton}
            onClick={() => {
              dispatch(sideNavActions.toggleOpen({ isOpen: true }));
              dispatch(ccpActions.setQuickConnectsVisibility(true));
              dispatch(ccpActions.setVoiceChannelDialpadVisibility(false));
            }}
          >
            <Tooltip
              disableFocusListener
              disableTouchListener
              placement="bottom"
              title={`${t('miniCcp.quickConnects')}`}
            >
              <PeopleAltIcon />
            </Tooltip>
          </Button>
        )}
        {status === 'Connected' && <Recording />}
      </Box>
    </Suspense>
  );
};

export default MiniCcp;
