import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { authSelectors } from '../../../../../store/selectors';
import { notificationActions } from '../../../../../store/reducers/notificationReducer';
import { TCallData } from '../../../../types/ccp';
import { callServiceApi } from '../../../../api/call-service-api';

export interface IAutomatedCallInitiatorReceiver {
  onCallRequestReceived?: (callData: TCallData) => void;
}

const AutomatedCallInitiatorReceiver = ({
  onCallRequestReceived,
}: IAutomatedCallInitiatorReceiver) => {
  const userInfo = useSelector(authSelectors.getUserInfo);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    let intervalID: any = null;
    let requestAbortController: AbortController | null = null;

    if (userInfo?.sub) {
      const queryCallData = async () => {
        try {
          requestAbortController?.abort();

          requestAbortController = new AbortController();
          const { data } = await callServiceApi.getCallToInitiateData(
            {
              username: userInfo.sub,
            },
            requestAbortController
          );
          if (data) {
            onCallRequestReceived?.(data);
          }
        } catch (err) {
          if (!axios.isCancel(err)) {
            dispatch(
              notificationActions.openNotification({
                isOpen: true,
                type: 'error',
                message: t('callInitiatorReceiver.errorFetchingData'),
              })
            );
          } else {
            console.log('Axios automated call initiator request cancelled.');
          }
        }
      };

      queryCallData();

      // Periodic polling of call data (whether there's someone to call)
      intervalID = setInterval(() => {
        queryCallData();
      }, 3000);
    }

    return () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
      requestAbortController?.abort();
    };
  }, [onCallRequestReceived, userInfo?.sub, dispatch, t]);

  return null;
};

export default AutomatedCallInitiatorReceiver;
