import { useEffect } from 'react';

const DesktopNotificationsRequester = () => {
  // Effect that asks for desktop notifications' permissions
  useEffect(() => {
    // If notifications hasn't been granted yet
    if (
      Notification.permission === 'denied' ||
      Notification.permission === 'default'
    ) {
      Notification.requestPermission().then(function () {
        console.log('Desktop notifications enabled.');
      });
    }
  }, []);

  return null;
};

export default DesktopNotificationsRequester;
