import { TKeyObject } from '../../../../types/generalTypes';

export const COUNTRY_CODES: TKeyObject = {
  us: '+1',
  fr: '+33',
  ie: '+353',
  it: '+39',
  gb: '+44',
  ro: '+40',
  es: '+34',
  gr: '+30',
  cz: '+420',
  bd: '+880',
  be: '+32',
  bf: '+226',
  bg: '+359',
  ba: '+387',
  bb: '+1-246',
  wf: '+681',
  bl: '+590',
  bm: '+1-441',
  bn: '+673',
  bo: '+591',
  bh: '+973',
  bi: '+257',
  bj: '+229',
  bt: '+975',
  jm: '+1-876',
  bv: '+55',
  bw: '+267',
  ws: '+685',
  bq: '+599',
  br: '+55',
  bs: '+1-242',
  je: '+44-1534',
  by: '+375',
  bz: '+501',
  ru: '+7',
  rw: '+250',
  rs: '+381',
  tl: '+670',
  re: '+262',
  tm: '+993',
  tj: '+992',
  tk: '+690',
  gw: '+245',
  gu: '+1-671',
  gt: '+502',
  gs: '+500',
  gq: '+240',
  gp: '+590',
  jp: '+81',
  gy: '+592',
  gg: '+44-1481',
  gf: '+594',
  ge: '+995',
  gd: '+1-473',
  ga: '+241',
  sv: '+503',
  gn: '+224',
  gm: '+220',
  gl: '+299',
  gi: '+350',
  gh: '+233',
  om: '+968',
  tn: '+216',
  jo: '+962',
  hr: '+385',
  ht: '+509',
  hu: '+36',
  hk: '+852',
  hn: '+504',
  hm: '+672',
  ve: '+58',
  ps: '+970',
  pw: '+680',
  pt: '+351',
  sj: '+47',
  py: '+595',
  iq: '+964',
  pa: '+507',
  pf: '+689',
  pg: '+675',
  pe: '+51',
  pk: '+92',
  ph: '+63',
  pn: '+870',
  pl: '+48',
  pm: '+508',
  zm: '+260',
  eh: '+212',
  ee: '+372',
  eg: '+20',
  za: '+27',
  ec: '+593',
  vn: '+84',
  sb: '+677',
  et: '+251',
  so: '+252',
  zw: '+263',
  sa: '+966',
  er: '+291',
  me: '+382',
  md: '+373',
  mg: '+261',
  mf: '+590',
  ma: '+212',
  mc: '+377',
  uz: '+998',
  mm: '+95',
  ml: '+223',
  mo: '+853',
  mn: '+976',
  mh: '+692',
  mk: '+389',
  mu: '+230',
  mt: '+356',
  mw: '+265',
  mv: '+960',
  mq: '+596',
  mp: '+1-670',
  ms: '+1-664',
  mr: '+222',
  im: '+44-1624',
  ug: '+256',
  tz: '+255',
  my: '+60',
  mx: '+52',
  il: '+972',
  io: '+246',
  sh: '+290',
  fi: '+358',
  fj: '+679',
  fk: '+500',
  fm: '+691',
  fo: '+298',
  ni: '+505',
  nl: '+31',
  no: '+47',
  na: '+264',
  vu: '+678',
  nc: '+687',
  ne: '+227',
  nf: '+672',
  ng: '+234',
  nz: '+64',
  np: '+977',
  nr: '+674',
  nu: '+683',
  ck: '+682',
  xk: '+383',
  ci: '+225',
  ch: '+41',
  co: '+57',
  cn: '+86',
  cm: '+237',
  cl: '+56',
  cc: '+61',
  ca: '+1',
  cg: '+242',
  cf: '+236',
  cd: '+243',
  cy: '+357',
  cx: '+61',
  cr: '+506',
  cw: '+599',
  cv: '+238',
  cu: '+53',
  sz: '+268',
  sy: '+963',
  sx: '+599',
  kg: '+996',
  ke: '+254',
  ss: '+211',
  sr: '+597',
  ki: '+686',
  kh: '+855',
  kn: '+1-869',
  km: '+269',
  st: '+239',
  sk: '+421',
  kr: '+82',
  si: '+386',
  kp: '+850',
  kw: '+965',
  sn: '+221',
  sm: '+378',
  sl: '+232',
  sc: '+248',
  kz: '+7',
  ky: '+1-345',
  sg: '+65',
  se: '+46',
  sd: '+249',
  dm: '+1-767',
  dj: '+253',
  dk: '+45',
  vg: '+1-284',
  de: '+49',
  ye: '+967',
  dz: '+213',
  uy: '+598',
  yt: '+262',
  um: '+1',
  lb: '+961',
  lc: '+1-758',
  la: '+856',
  tv: '+688',
  tw: '+886',
  tt: '+1-868',
  tr: '+90',
  lk: '+94',
  li: '+423',
  lv: '+371',
  to: '+676',
  lt: '+370',
  lu: '+352',
  lr: '+231',
  ls: '+266',
  th: '+66',
  tf: '+262',
  tg: '+228',
  td: '+235',
  tc: '+1-649',
  ly: '+218',
  va: '+379',
  vc: '+1-784',
  ae: '+971',
  ad: '+376',
  ag: '+1-268',
  af: '+93',
  ai: '+1-264',
  vi: '+1-340',
  is: '+354',
  ir: '+98',
  am: '+374',
  al: '+355',
  ao: '+244',
  aq: '+672',
  as: '+1-684',
  ar: '+54',
  au: '+61',
  at: '+43',
  aw: '+297',
  in: '+91',
  ax: '+358-18',
  az: '+994',
  id: '+62',
  ua: '+380',
  qa: '+974',
  mz: '+258',
};

export const OUTBOUND_CALL_TYPES: TKeyObject = {
  NEW_CUSTOMER: 'callReason.NEW_CUSTOMER',
  END_CUSTOMER: 'callReason.END_CUSTOMER',
  PARTNER: 'callReason.PARTNER',
  INTERNAL: 'callReason.INTERNAL',
};
