import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useConfig } from '../hooks/useConfig';

import Loader from '../components/loader/Loader';

import { ROUTE_PATHS } from './routePaths';

const Login = lazy(
  () => import(/* webpackChunkName: 'login' */ '../pages/Login')
);
const LoginDev = lazy(
  () => import(/* webpackChunkName: 'login' */ '../pages/LoginDev')
);

const LoginRoutes = () => {
  const { env } = useConfig();

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path={ROUTE_PATHS.auth.login} exact component={Login} />
        {!env?.production && (
          <Route path={ROUTE_PATHS.auth.loginDev} exact component={LoginDev} />
        )}
        <Redirect to={ROUTE_PATHS.auth.login} />
      </Switch>
    </Suspense>
  );
};

export default LoginRoutes;
