import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { configActions } from '../../store/reducers/configReducer';
import { configSelectors } from '../../store/selectors';
import { TConfig, TConfigReducer } from '../types/configType';

export const useConfig = (): TConfigReducer => {
  const dispatch = useDispatch();
  const { env } = useSelector(configSelectors.getConfig);

  const getEnv = async () => {
    const env: { environment: TConfig } = await import(
      `../../environments/${process.env.NX_INSTANCE_NAME}`
    );

    dispatch(configActions.setConfig({ env: env.environment }));
  };

  useEffect(() => {
    if (!env) {
      getEnv();
    }
  }, []);

  return { env };
};
