import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TNotification } from '../../app/types/contactRecordTypes';

export const callNotificationsReducerInitialState: TNotification = {
  missedCalls: 0,
  newVoicemails: 0,
};

const callNotificationsReducer = createSlice({
  name: 'callNotificationsReducer',
  initialState: callNotificationsReducerInitialState,
  reducers: {
    setCallNotifications: (_, { payload }: PayloadAction<TNotification>) =>
      payload,
  },
});

export const callNotificationsActions = callNotificationsReducer.actions;

export default callNotificationsReducer.reducer;
