import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useNotifications } from './contactHistory/useNotifications';
import { callNotificationsActions } from '../../store/reducers/call-notifications-reducer';

const useUpdateReduxWithCallNotifications = () => {
  const callNotificationsData = useNotifications();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      callNotificationsActions.setCallNotifications(callNotificationsData)
    );
  }, [callNotificationsData, dispatch]);
};

export default useUpdateReduxWithCallNotifications;
