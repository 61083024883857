import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import { Box, Button, Typography } from '@mui/material';

import {
  authSelectors,
  screenPopUpsSelectors,
} from '../../../../store/selectors';

import { useConfig } from '../../../hooks/useConfig';

import NetworkStatus from '../../NetworkStatus';
import HeaderDropdown from './header-dropdown';
import { TPopUpValues } from '../../../types/screenPopUpsTypes';
import LanguageSelect from '../../language-select/language-select';
import { usersApi } from '../../../api/usersApi';
import { languageActions } from '../../../../store/reducers/language-reducer';

import { useStyles } from '../side-nav-style';

export const Header = () => {
  const classes = useStyles();
  const windowsMapRef = useRef<any>({});
  const dispatch = useDispatch();

  const userInfo = useSelector(authSelectors.getUserInfo);
  const popUps = useSelector(screenPopUpsSelectors.getPopUps);

  const [openWithButtonsPopUps, setOpenWithButtonsPopUps] = useState<
    | (TPopUpValues & {
        isDisabled: boolean;
      })[]
    | null
  >(null);

  useEffect(() => {
    usersApi.getLanguage().then(({ data }) => {
      if (!data) {
        data = 'us';
      }
      dispatch(languageActions.setLanguage(data));
      i18next.changeLanguage(data);
    });
  }, []);

  useEffect(() => {
    setOpenWithButtonsPopUps(
      popUps
        ?.filter((elem) => elem.openWithButton === true)
        .map((elem) => ({ ...elem, isDisabled: false })) ?? null
    );
  }, [popUps]);

  const { env } = useConfig();

  const isInCall = useSelector(screenPopUpsSelectors.getIsInCall);

  const openRequestedSinglePopup = (
    url: string,
    openIn: string | undefined,
    index: number
  ) => {
    setOpenWithButtonsPopUps((array) => {
      if (!array) return null;

      return [
        ...array.slice(0, index),
        {
          ...array[index],
          isDisabled: true,
        },
        ...array.slice(index + 1, array.length),
      ];
    });

    setTimeout(() => {
      setOpenWithButtonsPopUps((array) => {
        if (!array) return null;

        return [
          ...array.slice(0, index),
          {
            ...array[index],
            isDisabled: false,
          },
          ...array.slice(index + 1, array.length),
        ];
      });
    }, env?.popupDisableTime);

    if (windowsMapRef.current[url] && !windowsMapRef.current[url].closed) {
      windowsMapRef.current[url].focus();
    } else {
      windowsMapRef.current[url] = window.open(
        url,
        url,
        openIn === 'NEW_TAB' ? '' : 'popup'
      );
    }
  };

  return (
    <Box className={classes.wrapper}>
      <Box sx={{ display: 'flex' }}>
        {isInCall &&
          openWithButtonsPopUps?.map((elem, index) => (
            <Button
              key={elem.newUrl}
              classes={{ root: classes.primaryButton }}
              disabled={!elem.newUrl || elem.isDisabled}
              onClick={() => {
                openRequestedSinglePopup(elem.newUrl!, elem.openIn, index);
              }}
            >
              {elem.openButtonLabel}
            </Button>
          ))}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {env?.enabledFeatures?.NETWORK_METRICS && (
          <Box
            sx={{
              marginRight: 3,
            }}
          >
            <NetworkStatus showCTAToNetworkMetricsPage />
          </Box>
        )}
        {env?.enabledFeatures?.LANGUAGE_SELECTOR && (
          <Box>
            <LanguageSelect />
          </Box>
        )}
        <Typography sx={{ margin: 'auto 0', fontWeight: 500 }} color="primary">
          {userInfo ? userInfo.firstName + ' ' + userInfo.lastName : ''}
        </Typography>
        <HeaderDropdown />
      </Box>
    </Box>
  );
};
