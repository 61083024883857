import { IStore } from '..';
import {
  TChatAttachmentFilterRequest,
  TChatTAttachmentFilterReducerState,
} from '../../app/types/chat-attachment-types';

export const getFilters = (
  state: IStore
): Partial<TChatAttachmentFilterRequest> =>
  state.chatAttachmentFilterReducer.filters;
export const getState = (state: IStore): TChatTAttachmentFilterReducerState =>
  state.chatAttachmentFilterReducer;
