import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TChatAttachmentFilterRequest,
  TChatTAttachmentFilterReducerState,
} from '../../app/types/chat-attachment-types';
import { TSortList } from '../../app/types/generalTypes';

export const chatAttachmentFilterInitialState: TChatTAttachmentFilterReducerState =
  {
    filters: {},
    forceRefresh: true,
    page: 0,
    sortingOptions: { direction: 'DESC', property: 'UPLOAD_DATE' },
  };

const chatAttachmentFilterReducer = createSlice({
  name: 'chatAttachmentFilterReducer',
  initialState: chatAttachmentFilterInitialState,
  reducers: {
    setFilters: (
      state,
      { payload }: PayloadAction<Partial<TChatAttachmentFilterRequest>>
    ) => {
      state.filters = payload;
      state.forceRefresh = !state.forceRefresh;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSorting: (state, { payload }: PayloadAction<TSortList>) => {
      state.sortingOptions = payload;
      state.forceRefresh = !state.forceRefresh;
      state.page = 0;
    },
  },
});

export const chatAttachmentFilterActions = chatAttachmentFilterReducer.actions;

export default chatAttachmentFilterReducer.reducer;
